export const site = /* GraphQL */ `
  query site($limit: Int) {
    site(limit: $limit) {
      weight_scale
      updated_at
      timezone
      rfid_reader
      public_ip
      perimeter_long
      perimeter_lat
      operation_type
      name
      map_zoom_level
      isp_upload_mbps
      isp_download_mbps
      isp_datacap_gb
      isp_billing_cycle_day
      internet_provider
      id
      customer_id
      created_at
      coordinates_long
      coordinates_lat
      community_pasture_used
      assigned_id
      area
      customer {
        first_name
        last_name
        id
      }
    }
  }
`;

export const camera = /* GraphQL */ `
  query camera(
    $where: camera_bool_exp
    $limit: Int
    $order_by: [camera_order_by!]
    $offset: Int
  ) {
    camera(limit: $limit, order_by: $order_by, where: $where, offset: $offset) {
      updated_at
      status
      site_id
      shipped
      predator_notifications_enabled
      name
      model
      map_icon_color
      manufacturer
      latest_upload_type
      latest_upload_source
      latest_upload_resolution
      latest_upload_fps
      latest_upload_corrupted
      latest_upload_bitrate_kbps
      latest_upload
      last_video_upload
      last_status_update
      inventory_number
      id
      footage_thumbnail_url
      estrus_notifications_enabled
      customer_id
      created_at
      coordinates_long
      coordinates_lat
      calving_notifications_enabled
      broken_reason
      broken
      blurriness_value
      blurriness_label
      customer {
        first_name
        last_name
        id
      }
      site {
        name
        id
      }
      # media(
      #   where: { media_type: { _eq: "VIDEO" } }
      #   limit: 1
      #   order_by: { media_timestamp: desc }
      # ) {
      #   width
      #   uploaded_at
      #   ratio
      #   num_frames_inferred
      #   num_frames
      #   media_type
      #   media_timestamp_to_inference
      #   media_timestamp
      #   keypoint_type
      #   json_type
      #   inference_to_upload
      #   inference_start
      #   inference_end
      #   height
      #   fps
      #   customer_id
      #   camera_id
      # }
    }
  }
`;

export const wifi = /* GraphQL */ `
  query wifi($limit: Int) {
    wifi(limit: $limit) {
      updated_at
      status
      site_id
      shipped
      model
      map_icon_color
      manufacturer
      inventory_number
      id
      device_type
      customer_id
      created_at
      coordinates_long
      coordinates_lat
      customer {
        first_name
        last_name
        id
      }
      site {
        name
        id
      }
    }
  }
`;

export const admin_note = /* GraphQL */ `
  query admin_note($where: admin_note_bool_exp) {
    admin_note(where: $where, order_by: { datetime: asc }) {
      updated_at
      wifi_id
      datetime
      id
      customer_id
      created_at
      comment
      camera_id
      author
    }
  }
`;

export const customer = /* GraphQL */ `
  query customer($limit: Int) {
    customer(limit: $limit) {
      updated_at
      unit_system
      type
      stripe_id
      status
      social_youtube
      social_x
      social_other
      social_linkedin
      social_instagram
      social_facebook
      referred_by
      preferred_name
      phone_work
      phone_home
      phone_fax
      phone_cell
      last_name
      initial_login_web
      initial_login_mobile
      id
      first_name
      created_at
      company_website
      company_occupation
      company_name
      company_logo
      company_brand_image
      addresses(order_by: { type: desc }) {
        city
        country
        line_1
        line_2
        postal_code
        province
        type
        customer_id
      }
      shares {
        approved
        created_at
        notifications_enabled
        pending
        permission
        updated_at
        collaborator_email
        owner_email
      }
      shares_with {
        approved
        created_at
        notifications_enabled
        pending
        permission
        updated_at
        collaborator_email
        owner_email
      }
      notification_preferences {
        alert_call_frequency
        alert_email_frequency
        alert_sms_frequency
        info_call_frequency
        info_email_frequency
        info_sms_frequency
        warning_call_frequency
        warning_email_frequency
        warning_sms_frequency
      }
      wifis_aggregate {
        aggregate {
          count
        }
      }
      cameras_aggregate {
        aggregate {
          count
        }
      }
      sites {
        name
        id
      }
    }
  }
`;
export const customer_by_pk = /* GraphQL */ `
  query customer_by_pk($id: String!) {
    customer_by_pk(id: $id) {
      updated_at
      unit_system
      type
      stripe_id
      status
      social_youtube
      social_x
      social_other
      social_linkedin
      social_instagram
      social_facebook
      referred_by
      preferred_name
      phone_work
      phone_home
      phone_fax
      phone_cell
      last_name
      initial_login_web
      initial_login_mobile
      id
      first_name
      created_at
      company_website
      company_occupation
      company_name
      company_logo
      company_brand_image
      addresses(order_by: { type: desc }) {
        city
        country
        line_1
        line_2
        postal_code
        province
        type
        customer_id
      }
      shares {
        approved
        created_at
        notifications_enabled
        pending
        permission
        updated_at
        collaborator_email
        owner_email
      }
      shares_with {
        approved
        created_at
        notifications_enabled
        pending
        permission
        updated_at
        collaborator_email
        owner_email
      }
      notification_preferences {
        alert_call_frequency
        alert_email_frequency
        alert_sms_frequency
        info_call_frequency
        info_email_frequency
        info_sms_frequency
        warning_call_frequency
        warning_email_frequency
        warning_sms_frequency
      }
      wifis {
        updated_at
        status
        site_id
        shipped
        model
        map_icon_color
        manufacturer
        inventory_number
        id
        device_type
        customer_id
        created_at
        coordinates_long
        coordinates_lat
      }
      cameras {
        updated_at
        status
        site_id
        shipped
        predator_notifications_enabled
        name
        model
        map_icon_color
        manufacturer
        latest_upload_type
        latest_upload_source
        latest_upload_resolution
        latest_upload_fps
        latest_upload_corrupted
        latest_upload_bitrate_kbps
        latest_upload
        last_video_upload
        last_status_update
        inventory_number
        id
        footage_thumbnail_url
        estrus_notifications_enabled
        customer_id
        created_at
        coordinates_long
        coordinates_lat
        calving_notifications_enabled
        broken_reason
        broken
      }
      sites {
        name
        id
      }
    }
  }
`;
export const getHeatVideos = /* GraphQL */ `
  query getHeatVideos($pageSize: Int, $page: Int) {
    inference_media(
      limit: $pageSize
      offset: $page
      where: {
        media_type: { _eq: "VIDEO" }
        pgies: { classifications: { heat_detection_label: { _eq: "in_heat" } } }
      }
    ) {
      camera_id
      customer_id
      media_timestamp
    }
  }
`;

export const getHeatVideosWithCameraId = /* GraphQL */ `
  query getHeatVideosWithCameraId(
    $pageSize: Int
    $page: Int
    $camera_id: String
  ) {
    inference_media(
      limit: $pageSize
      offset: $page
      where: {
        media_type: { _eq: "VIDEO" }
        pgies: { classifications: { heat_detection_label: { _eq: "in_heat" } } }
        camera_id: $camera_id
      }
    ) {
      camera_id
      customer_id
      media_timestamp
    }
  }
`;

export const getHeatVideosWithCustomerId = /* GraphQL */ `
  query getHeatVideosWithCustomerId(
    $pageSize: Int
    $page: Int
    $customer_id: String
  ) {
    inference_media(
      limit: $pageSize
      offset: $page
      where: {
        media_type: { _eq: "VIDEO" }
        pgies: { classifications: { heat_detection_label: { _eq: "in_heat" } } }
        customer_id: $customer_id
      }
    ) {
      camera_id
      customer_id
      media_timestamp
    }
  }
`;

export const getHeatVideosWithBoth = /* GraphQL */ `
  query getHeatVideosWithBoth(
    $pageSize: Int
    $page: Int
    $camera_id: String
    $customer_id: String
  ) {
    inference_media(
      limit: $pageSize
      offset: $page
      where: {
        media_type: { _eq: "VIDEO" }
        pgies: { classifications: { heat_detection_label: { _eq: "in_heat" } } }
        customer_id: $customer_id
        camera_id: $camera_id
      }
    ) {
      camera_id
      customer_id
      media_timestamp
    }
  }
`;

export const getHeatCameras = /* GraphQL */ `
  query getHeatCameras {
    inference_media(
      where: {
        media_type: { _eq: "VIDEO" }
        pgies: { classifications: { heat_detection_label: { _eq: "in_heat" } } }
      }
    ) {
      camera_id
    }
  }
`;

export const getHeatCustomers = /* GraphQL */ `
  query getHeatCustomers {
    inference_media(
      where: {
        media_type: { _eq: "VIDEO" }
        pgies: { classifications: { heat_detection_label: { _eq: "in_heat" } } }
      }
    ) {
      customer_id
    }
  }
`;

export const getAllImages = /* GraphQL */ `
  query ($limit: Int!, $offset: Int!) {
    inference_media(
      limit: $limit
      offset: $offset
      where: { media_type: { _eq: "IMAGE" } }
    ) {
      camera_id
      customer_id
      media_timestamp
      pgies {
        bbox
        classifications {
          species_label
        }
      }
    }
  }
`;

export const getAllCamerasImages = /* GraphQL */ `
  query getAllCamerasImages {
    inference_media(distinct_on: camera_id) {
      camera_id
    }
  }
`;

export const getAllCamerasNamesImages = /* GraphQL */ `
  query getAllCamerasImages {
    camera {
      model
      name
      id
      customer_id
    }
  }
`;
export const getAllCamerasUsingCustomerIDImages = /* GraphQL */ `
  query getAllCamerasUsingCustomerID($customer_id: String!) {
    camera(where: { customer_id: { _eq: $customer_id } }) {
      model
      name
      id
      customer_id
    }
  }
`;

export const getAllCamerasUsingCustomerID = /* GraphQL */ `
  query getAllCamerasUsingCustomerID($customer_id: String!) {
    inference_media(
      where: { customer_id: { _eq: $customer_id } }
      distinct_on: camera_id
    ) {
      camera_id
    }
  }
`;

export const notification = /* GraphQL */ `
  query notification(
    $where: notification_bool_exp
    $order_by: [notification_order_by!]
    $limit: Int
    $offset: Int
  ) {
    notification(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      value
      updated_at
      type
      twilio_sid
      title
      sent_datetime_twilio
      sent_datetime_sendgrid
      sendgrid_id
      read
      level
      media_url
      id
      description
      datetime
      created_at
      customer_id
      camera_id
      animal_id
      bbox
      contraction_conf_2
      contraction_conf
      contraction_label
      contraction_label_2
      tag_1_id_conf
      tag_1_id_label
      tag_2_id_conf
      tag_2_id_label
      tail_bbox
      tracking_id
      feedback_received
      feedback_positive
      feedback_datetime
      feedback_comments
      camera {
        name
      }
      customer {
        first_name
        last_name
      }

      internal_feedback_verdict
      internal_feedback_user_id
      internal_feedback_reason
      internal_feedback_datetime
      internal_feedback_provided
      internal_feedback_comments
    }
  }
`;
export const notification_aggregate = /* GraphQL */ `
  query notification_aggregate($where: notification_bool_exp = {}) {
    notification_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

// ANIMAL IDENTIFICATIONS
export const animal_image = /* GraphQL */ `
  query animal_image(
    $where: animal_image_bool_exp
    $order_by: [animal_image_order_by!]
    $limit: Int
    $offset: Int
  ) {
    animal_image(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      url
      uploaded_at
      updated_at
      tracking_id
      source
      reviewed_by
      review_status
      review_datetime
      media_timestamp
      id
      frame_num
      detected_tag_label
      detected_tag_color
      deleted_at
      customer_id
      created_at
      comment
      camera_id
      bbox_y
      bbox_x
      bbox_w
      bbox_h
      animal_id
      customer {
        first_name
        last_name
      }
    }
  }
`;

export const animal_image_aggregate = /* GraphQL */ `
  query animal_image_aggregate($where: animal_image_bool_exp) {
    animal_image_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

/* SUMMARY */

export const summary_media_1day_admin = /* GraphQL */ `
  query summary_media_1day_admin($limit: Int) {
    summary_media_1day_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;
export const summary_media_1hour_admin = /* GraphQL */ `
  query summary_media_1hour_admin($limit: Int) {
    summary_media_1hour_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;
export const summary_media_5min_admin = /* GraphQL */ `
  query summary_media_5min_admin($limit: Int) {
    summary_media_5min_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;

export const summary_pgie_1day_admin = /* GraphQL */ `
  query summary_pgie_1day_admin($limit: Int) {
    summary_pgie_1day_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
    }
  }
`;
export const summary_pgie_1hour_admin = /* GraphQL */ `
  query summary_pgie_1hour_admin($limit: Int) {
    summary_pgie_1hour_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
    }
  }
`;
export const summary_pgie_5min_admin = /* GraphQL */ `
  query summary_pgie_5min_admin($limit: Int) {
    summary_pgie_5min_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
    }
  }
`;

export const summary_media_1day_user = /* GraphQL */ `
  query summary_media_1day_user(
    $limit: Int
    $where: summary_media_1day_user_bool_exp
  ) {
    summary_media_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;
export const summary_media_1hour_user = /* GraphQL */ `
  query summary_media_1hour_user(
    $limit: Int
    $where: summary_media_1hour_user_bool_exp
  ) {
    summary_media_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;
export const summary_media_5min_user = /* GraphQL */ `
  query summary_media_5min_user(
    $limit: Int
    $where: summary_media_5min_user_bool_exp
  ) {
    summary_media_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;

export const summary_pgie_1day_user = /* GraphQL */ `
  query summary_pgie_1day_user(
    $limit: Int
    $where: summary_pgie_1day_user_bool_exp
  ) {
    summary_pgie_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
    }
  }
`;
export const summary_pgie_1hour_user = /* GraphQL */ `
  query summary_pgie_1hour_user(
    $limit: Int
    $where: summary_pgie_1hour_user_bool_exp
  ) {
    summary_pgie_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
    }
  }
`;
export const summary_pgie_5min_user = /* GraphQL */ `
  query summary_pgie_5min_user(
    $limit: Int
    $where: summary_pgie_5min_user_bool_exp
  ) {
    summary_pgie_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
    }
  }
`;

export const summary_activity_1day_admin = /* GraphQL */ `
  query summary_activity_1day_admin(
    $limit: Int
    $where: summary_activity_1day_admin_bool_exp
  ) {
    summary_activity_1day_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      walking_count
      walking_conf_stddev
      walking_conf_mean
      swimming_count
      walking_conf_2nd_stddev
      swimming_conf_stddev
      swimming_conf_mean
      swimming_conf_2nd_stddev
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      standing_walking_count
      standing_walking_conf_stddev
      standing_walking_conf_mean
      standing_walking_conf_2nd_stddev
      sleeping_count
      sleeping_conf_stddev
      sleeping_conf_mean
      sleeping_conf_2nd_stddev
      running_count
      running_conf_stddev
      running_conf_mean
      running_conf_2nd_stddev
      playing_count
      playing_conf_stddev
      playing_conf_mean
      playing_conf_2nd_stddev
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      media_timestamp
      lying_down_count
      lying_down_conf_stddev
      lying_down_conf_mean
      lying_down_conf_2nd_stddev
      head_up_count
      head_up_conf_stddev
      head_up_conf_mean
      head_up_2_count
      head_up_2_conf_stddev
      head_up_2_conf_mean
      head_down_count
      head_down_conf_stddev
      head_down_conf_mean
      head_down_2_count
      head_down_2_conf_stddev
      head_down_2_conf_mean
      digging_count
      digging_conf_stddev
      digging_conf_mean
      digging_conf_2nd_stddev
      cow_count
      cow_conf_stddev
      cow_conf_mean
      cow_2_count
      cow_2_conf_stddev
      cow_2_conf_mean
      climbing_stairs_count
      climbing_stairs_conf_stddev
      climbing_stairs_conf_mean
      climbing_stairs_conf_2nd_stddev
      calf_count
      calf_conf_stddev
      calf_conf_mean
      calf_conf_2nd_stddev
      bull_count
      bull_conf_stddev
      bull_conf_mean
      bull_2_count
      bull_2_conf_stddev
      bull_2_conf_mean
      adult_count
      adult_conf_stddev
      adult_conf_mean
      adult_conf_2nd_stddev
    }
  }
`;
export const summary_activity_1hour_admin = /* GraphQL */ `
  query summary_activity_1hour_admin(
    $limit: Int
    $where: summary_activity_1hour_admin_bool_exp
  ) {
    summary_activity_1hour_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      walking_count
      walking_conf_stddev
      walking_conf_mean
      swimming_count
      walking_conf_2nd_stddev
      swimming_conf_stddev
      swimming_conf_mean
      swimming_conf_2nd_stddev
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      standing_walking_count
      standing_walking_conf_stddev
      standing_walking_conf_mean
      standing_walking_conf_2nd_stddev
      sleeping_count
      sleeping_conf_stddev
      sleeping_conf_mean
      sleeping_conf_2nd_stddev
      running_count
      running_conf_stddev
      running_conf_mean
      running_conf_2nd_stddev
      playing_count
      playing_conf_stddev
      playing_conf_mean
      playing_conf_2nd_stddev
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      media_timestamp
      lying_down_count
      lying_down_conf_stddev
      lying_down_conf_mean
      lying_down_conf_2nd_stddev
      head_up_count
      head_up_conf_stddev
      head_up_conf_mean
      head_up_2_count
      head_up_2_conf_stddev
      head_up_2_conf_mean
      head_down_count
      head_down_conf_stddev
      head_down_conf_mean
      head_down_2_count
      head_down_2_conf_stddev
      head_down_2_conf_mean
      digging_count
      digging_conf_stddev
      digging_conf_mean
      digging_conf_2nd_stddev
      cow_count
      cow_conf_stddev
      cow_conf_mean
      cow_2_count
      cow_2_conf_stddev
      cow_2_conf_mean
      climbing_stairs_count
      climbing_stairs_conf_stddev
      climbing_stairs_conf_mean
      climbing_stairs_conf_2nd_stddev
      calf_count
      calf_conf_stddev
      calf_conf_mean
      calf_conf_2nd_stddev
      bull_count
      bull_conf_stddev
      bull_conf_mean
      bull_2_count
      bull_2_conf_stddev
      bull_2_conf_mean
      adult_count
      adult_conf_stddev
      adult_conf_mean
      adult_conf_2nd_stddev
    }
  }
`;
export const summary_activity_5min_admin = /* GraphQL */ `
  query summary_activity_5min_admin(
    $limit: Int
    $where: summary_activity_5min_admin_bool_exp
  ) {
    summary_activity_5min_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      walking_count
      walking_conf_stddev
      walking_conf_mean
      swimming_count
      walking_conf_2nd_stddev
      swimming_conf_stddev
      swimming_conf_mean
      swimming_conf_2nd_stddev
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      standing_walking_count
      standing_walking_conf_stddev
      standing_walking_conf_mean
      standing_walking_conf_2nd_stddev
      sleeping_count
      sleeping_conf_stddev
      sleeping_conf_mean
      sleeping_conf_2nd_stddev
      running_count
      running_conf_stddev
      running_conf_mean
      running_conf_2nd_stddev
      playing_count
      playing_conf_stddev
      playing_conf_mean
      playing_conf_2nd_stddev
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      media_timestamp
      lying_down_count
      lying_down_conf_stddev
      lying_down_conf_mean
      lying_down_conf_2nd_stddev
      head_up_count
      head_up_conf_stddev
      head_up_conf_mean
      head_up_2_count
      head_up_2_conf_stddev
      head_up_2_conf_mean
      head_down_count
      head_down_conf_stddev
      head_down_conf_mean
      head_down_2_count
      head_down_2_conf_stddev
      head_down_2_conf_mean
      digging_count
      digging_conf_stddev
      digging_conf_mean
      digging_conf_2nd_stddev
      cow_count
      cow_conf_stddev
      cow_conf_mean
      cow_2_count
      cow_2_conf_stddev
      cow_2_conf_mean
      climbing_stairs_count
      climbing_stairs_conf_stddev
      climbing_stairs_conf_mean
      climbing_stairs_conf_2nd_stddev
      calf_count
      calf_conf_stddev
      calf_conf_mean
      calf_conf_2nd_stddev
      bull_count
      bull_conf_stddev
      bull_conf_mean
      bull_2_count
      bull_2_conf_stddev
      bull_2_conf_mean
      adult_count
      adult_conf_stddev
      adult_conf_mean
      adult_conf_2nd_stddev
    }
  }
`;

export const summary_activity_1day_user = /* GraphQL */ `
  query summary_activity_1day_user(
    $limit: Int
    $where: summary_activity_1day_user_bool_exp
  ) {
    summary_activity_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      walking_count
      walking_conf_stddev
      walking_conf_mean
      swimming_count
      walking_conf_2nd_stddev
      swimming_conf_stddev
      swimming_conf_mean
      swimming_conf_2nd_stddev
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      standing_walking_count
      standing_walking_conf_stddev
      standing_walking_conf_mean
      standing_walking_conf_2nd_stddev
      sleeping_count
      sleeping_conf_stddev
      sleeping_conf_mean
      sleeping_conf_2nd_stddev
      running_count
      running_conf_stddev
      running_conf_mean
      running_conf_2nd_stddev
      playing_count
      playing_conf_stddev
      playing_conf_mean
      playing_conf_2nd_stddev
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      media_timestamp
      lying_down_count
      lying_down_conf_stddev
      lying_down_conf_mean
      lying_down_conf_2nd_stddev
      head_up_count
      head_up_conf_stddev
      head_up_conf_mean
      head_up_2_count
      head_up_2_conf_stddev
      head_up_2_conf_mean
      head_down_count
      head_down_conf_stddev
      head_down_conf_mean
      head_down_2_count
      head_down_2_conf_stddev
      head_down_2_conf_mean
      digging_count
      digging_conf_stddev
      digging_conf_mean
      digging_conf_2nd_stddev
      cow_count
      cow_conf_stddev
      cow_conf_mean
      cow_2_count
      cow_2_conf_stddev
      cow_2_conf_mean
      climbing_stairs_count
      climbing_stairs_conf_stddev
      climbing_stairs_conf_mean
      climbing_stairs_conf_2nd_stddev
      calf_count
      calf_conf_stddev
      calf_conf_mean
      calf_conf_2nd_stddev
      bull_count
      bull_conf_stddev
      bull_conf_mean
      bull_2_count
      bull_2_conf_stddev
      bull_2_conf_mean
      adult_count
      adult_conf_stddev
      adult_conf_mean
      adult_conf_2nd_stddev
    }
  }
`;
export const summary_activity_1hour_user = /* GraphQL */ `
  query summary_activity_1hour_user(
    $limit: Int
    $where: summary_activity_1hour_user_bool_exp
  ) {
    summary_activity_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      walking_count
      walking_conf_stddev
      walking_conf_mean
      swimming_count
      walking_conf_2nd_stddev
      swimming_conf_stddev
      swimming_conf_mean
      swimming_conf_2nd_stddev
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      standing_walking_count
      standing_walking_conf_stddev
      standing_walking_conf_mean
      standing_walking_conf_2nd_stddev
      sleeping_count
      sleeping_conf_stddev
      sleeping_conf_mean
      sleeping_conf_2nd_stddev
      running_count
      running_conf_stddev
      running_conf_mean
      running_conf_2nd_stddev
      playing_count
      playing_conf_stddev
      playing_conf_mean
      playing_conf_2nd_stddev
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      media_timestamp
      lying_down_count
      lying_down_conf_stddev
      lying_down_conf_mean
      lying_down_conf_2nd_stddev
      head_up_count
      head_up_conf_stddev
      head_up_conf_mean
      head_up_2_count
      head_up_2_conf_stddev
      head_up_2_conf_mean
      head_down_count
      head_down_conf_stddev
      head_down_conf_mean
      head_down_2_count
      head_down_2_conf_stddev
      head_down_2_conf_mean
      digging_count
      digging_conf_stddev
      digging_conf_mean
      digging_conf_2nd_stddev
      cow_count
      cow_conf_stddev
      cow_conf_mean
      cow_2_count
      cow_2_conf_stddev
      cow_2_conf_mean
      climbing_stairs_count
      climbing_stairs_conf_stddev
      climbing_stairs_conf_mean
      climbing_stairs_conf_2nd_stddev
      calf_count
      calf_conf_stddev
      calf_conf_mean
      calf_conf_2nd_stddev
      bull_count
      bull_conf_stddev
      bull_conf_mean
      bull_2_count
      bull_2_conf_stddev
      bull_2_conf_mean
      adult_count
      adult_conf_stddev
      adult_conf_mean
      adult_conf_2nd_stddev
    }
  }
`;
export const summary_activity_5min_user = /* GraphQL */ `
  query summary_activity_5min_user(
    $limit: Int
    $where: summary_activity_5min_user_bool_exp
  ) {
    summary_activity_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      walking_count
      walking_conf_stddev
      walking_conf_mean
      swimming_count
      walking_conf_2nd_stddev
      swimming_conf_stddev
      swimming_conf_mean
      swimming_conf_2nd_stddev
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      standing_walking_count
      standing_walking_conf_stddev
      standing_walking_conf_mean
      standing_walking_conf_2nd_stddev
      sleeping_count
      sleeping_conf_stddev
      sleeping_conf_mean
      sleeping_conf_2nd_stddev
      running_count
      running_conf_stddev
      running_conf_mean
      running_conf_2nd_stddev
      playing_count
      playing_conf_stddev
      playing_conf_mean
      playing_conf_2nd_stddev
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      media_timestamp
      lying_down_count
      lying_down_conf_stddev
      lying_down_conf_mean
      lying_down_conf_2nd_stddev
      head_up_count
      head_up_conf_stddev
      head_up_conf_mean
      head_up_2_count
      head_up_2_conf_stddev
      head_up_2_conf_mean
      head_down_count
      head_down_conf_stddev
      head_down_conf_mean
      head_down_2_count
      head_down_2_conf_stddev
      head_down_2_conf_mean
      digging_count
      digging_conf_stddev
      digging_conf_mean
      digging_conf_2nd_stddev
      cow_count
      cow_conf_stddev
      cow_conf_mean
      cow_2_count
      cow_2_conf_stddev
      cow_2_conf_mean
      climbing_stairs_count
      climbing_stairs_conf_stddev
      climbing_stairs_conf_mean
      climbing_stairs_conf_2nd_stddev
      calf_count
      calf_conf_stddev
      calf_conf_mean
      calf_conf_2nd_stddev
      bull_count
      bull_conf_stddev
      bull_conf_mean
      bull_2_count
      bull_2_conf_stddev
      bull_2_conf_mean
      adult_count
      adult_conf_stddev
      adult_conf_mean
      adult_conf_2nd_stddev
    }
  }
`;

export const summary_vehicle_details_1day_admin = /* GraphQL */ `
  query summary_vehicle_details_1day_admin(
    $limit: Int
    $where: summary_vehicle_details_1day_admin_bool_exp
  ) {
    summary_vehicle_details_1day_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
    media_timestamp
    license_plate_count
    license_plate_class_conf_stddev
    license_plate_class_conf_mean
    }
  }
`;
export const summary_vehicle_details_1hour_admin = /* GraphQL */ `
  query summary_vehicle_details_1hour_admin(
    $limit: Int
    $where: summary_vehicle_details_1hour_admin_bool_exp
  ) {
    summary_vehicle_details_1hour_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
    media_timestamp
    license_plate_count
    license_plate_class_conf_stddev
    license_plate_class_conf_mean
    }
  }
`;
export const summary_vehicle_details_5min_admin = /* GraphQL */ `
  query summary_vehicle_details_5min_admin(
    $limit: Int
    $where: summary_vehicle_details_5min_admin_bool_exp
  ) {
    summary_vehicle_details_5min_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
    media_timestamp
    license_plate_count
    license_plate_class_conf_stddev
    license_plate_class_conf_mean
    }
  }
`;
export const summary_vehicle_details_1day_user = /* GraphQL */ `
  query summary_vehicle_details_1day_user(
    $limit: Int
    $where: summary_vehicle_details_1day_user_bool_exp
  ) {
    summary_vehicle_details_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
    media_timestamp
    license_plate_count
    license_plate_class_conf_stddev
    license_plate_class_conf_mean
    customer_id
    }
  }
`;
export const summary_vehicle_details_1hour_user = /* GraphQL */ `
  query summary_vehicle_details_1hour_user(
    $limit: Int
    $where: summary_vehicle_details_1hour_user_bool_exp
  ) {
    summary_vehicle_details_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
    media_timestamp
    license_plate_count
    license_plate_class_conf_stddev
    license_plate_class_conf_mean
    customer_id
    }
  }
`;
export const summary_vehicle_details_5min_user = /* GraphQL */ `
  query summary_vehicle_details_5min_user(
    $limit: Int
    $where: summary_vehicle_details_5min_user_bool_exp
  ) {
    summary_vehicle_details_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
    media_timestamp
    license_plate_count
    license_plate_class_conf_stddev
    license_plate_class_conf_mean
    customer_id
    }
  }
`;

export const summary_calving_1day_admin = /* GraphQL */ `
  query summary_calving_1day_admin(
    $limit: Int
    $where: summary_calving_1day_admin_bool_exp
  ) {
    summary_calving_1day_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up2_count
      tail_up2_conf_stddev
      tail_up2_conf_mean
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down2_count
      tail_down2_conf_stddev
      tail_down2_conf_mean
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      not_delivering_count
      not_delivering_conf_stddev
      not_delivering_conf_mean
      not_calving_count
      not_calving_conf_stddev
      not_calving_conf_mean
      media_timestamp
      delivering_count
      delivering_conf_stddev
      delivering_conf_mean
      calving_count
      calving_conf_stddev
      calving_conf_mean
    }
  }
`;
export const summary_calving_1hour_admin = /* GraphQL */ `
  query summary_calving_1hour_admin(
    $limit: Int
    $where: summary_calving_1hour_admin_bool_exp
  ) {
    summary_calving_1hour_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up2_count
      tail_up2_conf_stddev
      tail_up2_conf_mean
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down2_count
      tail_down2_conf_stddev
      tail_down2_conf_mean
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      not_delivering_count
      not_delivering_conf_stddev
      not_delivering_conf_mean
      not_calving_count
      not_calving_conf_stddev
      not_calving_conf_mean
      media_timestamp
      delivering_count
      delivering_conf_stddev
      delivering_conf_mean
      calving_count
      calving_conf_stddev
      calving_conf_mean
    }
  }
`;
export const summary_calving_5min_admin = /* GraphQL */ `
  query summary_calving_5min_admin(
    $limit: Int
    $where: summary_calving_5min_admin_bool_exp
  ) {
    summary_calving_5min_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up2_count
      tail_up2_conf_stddev
      tail_up2_conf_mean
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down2_count
      tail_down2_conf_stddev
      tail_down2_conf_mean
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      not_delivering_count
      not_delivering_conf_stddev
      not_delivering_conf_mean
      not_calving_count
      not_calving_conf_stddev
      not_calving_conf_mean
      media_timestamp
      delivering_count
      delivering_conf_stddev
      delivering_conf_mean
      calving_count
      calving_conf_stddev
      calving_conf_mean
    }
  }
`;
export const summary_calving_1day_user = /* GraphQL */ `
  query summary_calving_1day_user(
    $limit: Int
    $where: summary_calving_1day_user_bool_exp
  ) {
    summary_calving_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up2_count
      tail_up2_conf_stddev
      tail_up2_conf_mean
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down2_count
      tail_down2_conf_stddev
      tail_down2_conf_mean
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      not_delivering_count
      not_delivering_conf_stddev
      not_delivering_conf_mean
      not_calving_count
      not_calving_conf_stddev
      not_calving_conf_mean
      media_timestamp
      delivering_count
      delivering_conf_stddev
      delivering_conf_mean
      calving_count
      calving_conf_stddev
      calving_conf_mean
    }
  }
`;
export const summary_calving_1hour_user = /* GraphQL */ `
  query summary_calving_1hour_user(
    $limit: Int
    $where: summary_calving_1hour_user_bool_exp
  ) {
    summary_calving_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up2_count
      tail_up2_conf_stddev
      tail_up2_conf_mean
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down2_count
      tail_down2_conf_stddev
      tail_down2_conf_mean
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      not_delivering_count
      not_delivering_conf_stddev
      not_delivering_conf_mean
      not_calving_count
      not_calving_conf_stddev
      not_calving_conf_mean
      media_timestamp
      delivering_count
      delivering_conf_stddev
      delivering_conf_mean
      calving_count
      calving_conf_stddev
      calving_conf_mean
    }
  }
`;
export const summary_calving_5min_user = /* GraphQL */ `
  query summary_calving_5min_user(
    $limit: Int
    $where: summary_calving_5min_user_bool_exp
  ) {
    summary_calving_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up2_count
      tail_up2_conf_stddev
      tail_up2_conf_mean
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down2_count
      tail_down2_conf_stddev
      tail_down2_conf_mean
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      not_delivering_count
      not_delivering_conf_stddev
      not_delivering_conf_mean
      not_calving_count
      not_calving_conf_stddev
      not_calving_conf_mean
      media_timestamp
      delivering_count
      delivering_conf_stddev
      delivering_conf_mean
      calving_count
      calving_conf_stddev
      calving_conf_mean
    }
  }
`;

export const summary_estrus_1day_admin = /* GraphQL */ `
  query summary_estrus_1day_admin(
    $limit: Int
    $where: summary_estrus_1day_admin_bool_exp
  ) {
    summary_estrus_1day_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      resting_chin_count
      resting_chin_conf_stddev
      resting_chin_conf_mean
      not_resting_chin_count
      not_resting_chin_conf_stddev
      not_resting_chin_conf_mean
      not_mounting_count
      not_mounting_conf_stddev
      not_mounting_conf_mean
      not_in_heat_count
      not_in_heat_conf_stddev
      not_in_heat_conf_mean
      mounting_count
      mounting_conf_stddev
      mounting_conf_mean
      media_timestamp
      in_heat_count
      in_heat_conf_stddev
      in_heat_conf_mean
    }
  }
`;
export const summary_estrus_1hour_admin = /* GraphQL */ `
  query summary_estrus_1hour_admin(
    $limit: Int
    $where: summary_estrus_1hour_admin_bool_exp
  ) {
    summary_estrus_1hour_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      resting_chin_count
      resting_chin_conf_stddev
      resting_chin_conf_mean
      not_resting_chin_count
      not_resting_chin_conf_stddev
      not_resting_chin_conf_mean
      not_mounting_count
      not_mounting_conf_stddev
      not_mounting_conf_mean
      not_in_heat_count
      not_in_heat_conf_stddev
      not_in_heat_conf_mean
      mounting_count
      mounting_conf_stddev
      mounting_conf_mean
      media_timestamp
      in_heat_count
      in_heat_conf_stddev
      in_heat_conf_mean
    }
  }
`;
export const summary_estrus_5min_admin = /* GraphQL */ `
  query summary_estrus_5min_admin(
    $limit: Int
    $where: summary_estrus_5min_admin_bool_exp
  ) {
    summary_estrus_5min_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      resting_chin_count
      resting_chin_conf_stddev
      resting_chin_conf_mean
      not_resting_chin_count
      not_resting_chin_conf_stddev
      not_resting_chin_conf_mean
      not_mounting_count
      not_mounting_conf_stddev
      not_mounting_conf_mean
      not_in_heat_count
      not_in_heat_conf_stddev
      not_in_heat_conf_mean
      mounting_count
      mounting_conf_stddev
      mounting_conf_mean
      media_timestamp
      in_heat_count
      in_heat_conf_stddev
      in_heat_conf_mean
    }
  }
`;

export const summary_estrus_1day_user = /* GraphQL */ `
  query summary_estrus_1day_user(
    $limit: Int
    $where: summary_estrus_1day_user_bool_exp
  ) {
    summary_estrus_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      resting_chin_count
      resting_chin_conf_stddev
      resting_chin_conf_mean
      not_resting_chin_count
      not_resting_chin_conf_stddev
      not_resting_chin_conf_mean
      not_mounting_count
      not_mounting_conf_stddev
      not_mounting_conf_mean
      not_in_heat_count
      not_in_heat_conf_stddev
      not_in_heat_conf_mean
      mounting_count
      mounting_conf_stddev
      mounting_conf_mean
      media_timestamp
      in_heat_count
      in_heat_conf_stddev
      in_heat_conf_mean
    }
  }
`;
export const summary_estrus_1hour_user = /* GraphQL */ `
  query summary_estrus_1hour_user(
    $limit: Int
    $where: summary_estrus_1hour_user_bool_exp
  ) {
    summary_estrus_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      resting_chin_count
      resting_chin_conf_stddev
      resting_chin_conf_mean
      not_resting_chin_count
      not_resting_chin_conf_stddev
      not_resting_chin_conf_mean
      not_mounting_count
      not_mounting_conf_stddev
      not_mounting_conf_mean
      not_in_heat_count
      not_in_heat_conf_stddev
      not_in_heat_conf_mean
      mounting_count
      mounting_conf_stddev
      mounting_conf_mean
      media_timestamp
      in_heat_count
      in_heat_conf_stddev
      in_heat_conf_mean
    }
  }
`;
export const summary_estrus_5min_user = /* GraphQL */ `
  query summary_estrus_5min_user(
    $limit: Int
    $where: summary_estrus_5min_user_bool_exp
  ) {
    summary_estrus_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      resting_chin_count
      resting_chin_conf_stddev
      resting_chin_conf_mean
      not_resting_chin_count
      not_resting_chin_conf_stddev
      not_resting_chin_conf_mean
      not_mounting_count
      not_mounting_conf_stddev
      not_mounting_conf_mean
      not_in_heat_count
      not_in_heat_conf_stddev
      not_in_heat_conf_mean
      mounting_count
      mounting_conf_stddev
      mounting_conf_mean
      media_timestamp
      in_heat_count
      in_heat_conf_stddev
      in_heat_conf_mean
    }
  }
`;

export const summary_species_1day_admin = /* GraphQL */ `
  query summary_species_1day_admin(
    $limit: Int
    $where: summary_species_1day_admin_bool_exp
  ) {
    summary_species_1day_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;
export const summary_species_1hour_admin = /* GraphQL */ `
  query summary_species_1hour_admin(
    $limit: Int
    $where: summary_species_1hour_admin_bool_exp
  ) {
    summary_species_1hour_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;
export const summary_species_5min_admin = /* GraphQL */ `
  query summary_species_5min_admin(
    $limit: Int
    $where: summary_species_5min_admin_bool_exp
  ) {
    summary_species_5min_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;
export const summary_species_1day_user = /* GraphQL */ `
  query summary_species_1day_user(
    $limit: Int
    $where: summary_species_1day_user_bool_exp
  ) {
    summary_species_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;
export const summary_species_1hour_user = /* GraphQL */ `
  query summary_species_1hour_user(
    $limit: Int
    $where: summary_species_1hour_user_bool_exp
  ) {
    summary_species_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;
export const summary_species_5min_user = /* GraphQL */ `
  query summary_species_5min_user(
    $limit: Int
    $where: summary_species_5min_user_bool_exp
  ) {
    summary_species_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;



export const summary_tag_1day_admin = /* GraphQL */ `
  query summary_tag_1day_admin(
    $limit: Int
    $where: summary_tag_1day_admin_bool_exp
  ) {
    summary_tag_1day_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      unique_tag_count
    tag_count_over_5_chars
    tag_count_5_chars
    tag_count_4_chars
    tag_count_3_chars
    tag_count_2_chars
    tag_count_1_char
    media_timestamp
    }
  }
`;
export const summary_tag_1hour_admin = /* GraphQL */ `
  query summary_tag_1hour_admin(
    $limit: Int
    $where: summary_tag_1hour_admin_bool_exp
  ) {
    summary_tag_1hour_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      unique_tag_count
    tag_count_over_5_chars
    tag_count_5_chars
    tag_count_4_chars
    tag_count_3_chars
    tag_count_2_chars
    tag_count_1_char
    media_timestamp
    }
  }
`;
export const summary_tag_5min_admin = /* GraphQL */ `
  query summary_tag_5min_admin(
    $limit: Int
    $where: summary_tag_5min_admin_bool_exp
  ) {
    summary_tag_5min_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      unique_tag_count
    tag_count_over_5_chars
    tag_count_5_chars
    tag_count_4_chars
    tag_count_3_chars
    tag_count_2_chars
    tag_count_1_char
    media_timestamp
    }
  }
`;
export const summary_tag_1day_user = /* GraphQL */ `
  query summary_tag_1day_user(
    $limit: Int
    $where: summary_tag_1day_user_bool_exp
  ) {
    summary_tag_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      unique_tag_count
    tag_count_over_5_chars
    tag_count_5_chars
    tag_count_4_chars
    tag_count_3_chars
    tag_count_2_chars
    tag_count_1_char
    media_timestamp
    customer_id
    }
  }
`;
export const summary_tag_1hour_user = /* GraphQL */ `
  query summary_tag_1hour_user(
    $limit: Int
    $where: summary_tag_1hour_user_bool_exp
  ) {
    summary_tag_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      unique_tag_count
    tag_count_over_5_chars
    tag_count_5_chars
    tag_count_4_chars
    tag_count_3_chars
    tag_count_2_chars
    tag_count_1_char
    media_timestamp
    customer_id
    }
  }
`;
export const summary_tag_5min_user = /* GraphQL */ `
  query summary_tag_5min_user(
    $limit: Int
    $where: summary_tag_5min_user_bool_exp
  ) {
    summary_tag_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      unique_tag_count
    tag_count_over_5_chars
    tag_count_5_chars
    tag_count_4_chars
    tag_count_3_chars
    tag_count_2_chars
    tag_count_1_char
    media_timestamp
    customer_id
    }
  }
`;

export const summary_vpc_1day_admin = /* GraphQL */ `
  query summary_vpc_1day_admin(
    $limit: Int
    $where: summary_vpc_1day_admin_bool_exp
  ) {
    summary_vpc_1day_admin(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      vprc_server_name
      vprc_processing_time_stddev
      vprc_processing_time_mean
      vprc_processing_time_2nd_stddev
      media_type
      media_timestamp
      media_count
    }
  }
`;
/* ----------------- */

export const customer_aggregate = /* GraphQL */ `
  query customer_aggregate($where: customer_bool_exp) {
    customer_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;
export const site_aggregate = /* GraphQL */ `
  query site_aggregate($where: site_bool_exp) {
    site_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;
export const animal_aggregate = /* GraphQL */ `
  query animal_aggregate($where: animal_bool_exp) {
    animal_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;
export const animal_tag_aggregate = /* GraphQL */ `
  query animal_tag_aggregate($where: animal_tag_bool_exp) {
    animal_tag_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;
export const camera_aggregate = /* GraphQL */ `
  query camera_aggregate($where: camera_bool_exp) {
    camera_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;
export const wifi_aggregate = /* GraphQL */ `
  query wifi_aggregate($where: wifi_bool_exp) {
    wifi_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

/* ANALYTICS */

export const analytics_calving = /* GraphQL */ `
  query analytics_calving(
    $limit: Int
    $offset: Int
    $where: analytics_calving_bool_exp
    $order_by: [analytics_calving_order_by!]
  ) {
    analytics_calving(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      valid_calving_event
      uploaded_at
      tracking_id
      total_processing_time
      tail_ratio
      tail_conf
      tail_bbox
      tail_area
      tag_2_label
      tag_2_id_label
      tag_2_id_conf
      tag_2_conf
      tag_2_colour_conf
      tag_2_colour_label
      tag_2_bbox
      tag_1_label
      tag_1_id_label
      tag_1_id_conf
      tag_1_conf
      tag_1_colour_label
      tag_1_colour_conf
      tag_1_bbox
      species_label
      species_conf
      s3_uri
      notification_timestamp
      media_timestamp
      history_check
      group_filter
      gpt_check
      frame_num
      customer_id
      contraction_label_2
      contraction_label
      contraction_conf_2
      contraction_conf
      camera_id
      calving_label
      calving_description_tsvector
      calving_description
      calving_conf
      bbox_ratio
      bbox_area
      bbox
      customer {
        first_name
        last_name
      }
    }
  }
`;
export const analytics_calving_aggregate = /* GraphQL */ `
  query analytics_calving_aggregate($where: analytics_calving_bool_exp = {}) {
    analytics_calving_aggregate(where: $where) {
      aggregate {
        count(columns: media_timestamp)
      }
    }
  }
`;

export const analytics_estrus = /* GraphQL */ `
  query analytics_estrus(
    $limit: Int
    $offset: Int
    $where: analytics_estrus_bool_exp
    $order_by: [analytics_estrus_order_by!]
  ) {
    analytics_estrus(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      uploaded_at
      camera_id
      bbox_x
      bbox_y
      bbox_w
      bbox_h
      class_conf
      class_label
      conf_check
      class_label_2
      class_conf_2
      gpt_check
      gpt_description
      oo_bbox_h
      oo_bbox_w
      oo_bbox_x
      oo_bbox_y
      oo_tracking_id
      recent_sent_check
      s3_uri
      media_timestamp
      tracking_id
      species_label
      species_conf
      customer {
        id
        first_name
        last_name
      }
    }
  }
`;
export const analytics_estrus_aggregate = /* GraphQL */ `
  query analytics_estrus_aggregate($where: analytics_estrus_bool_exp = {}) {
    analytics_estrus_aggregate(where: $where) {
      aggregate {
        count(columns: media_timestamp)
      }
    }
  }
`;

export const analytics_predator = /* GraphQL */ `
  query analytics_predator(
    $limit: Int
    $offset: Int
    $where: analytics_predator_bool_exp
    $order_by: [analytics_predator_order_by!]
  ) {
    analytics_predator(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      uploaded_at
      tracking_id
      species_label
      species_conf
      s3_uri
      recent_sent_check
      media_type
      media_timestamp
      gpt_description
      gpt_check
      frame_num
      customer_id
      customer {
        first_name
        last_name
      }
      conf_check
      camera_id
      breed_label
      breed_conf
      bbox_y
      bbox_x
      bbox_w
      bbox
      bbox_h
    }
  }
`;
export const analytics_predator_aggregate = /* GraphQL */ `
  query analytics_predator_aggregate($where: analytics_predator_bool_exp = {}) {
    analytics_predator_aggregate(where: $where) {
      aggregate {
        count(columns: media_timestamp)
      }
    }
  }
`;

export const inference_media_video = /* GraphQL */ `
  query inference_media(
    $where: inference_media_bool_exp
    $order_by: [inference_media_order_by!]
    $limit: Int
    $offset: Int
  ) {
    inference_media(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      width
      uploaded_at
      ratio
      num_frames_inferred
      num_frames
      media_type
      media_timestamp_to_inference
      media_timestamp
      keypoint_type
      json_type
      inference_to_upload
      inference_start
      height
      inference_end
      fps
      customer_id
      camera_id
    }
  }
`;
export const inference_media_aggregate = /* GraphQL */ `
  query inference_media_aggregate(
    $where: inference_media_bool_exp
    $order_by: [inference_media_order_by!]
    $limit: Int
    $offset: Int
  ) {
    inference_media_aggregate(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const summary_media_summary = /* GraphQL */ `
  query summary_media_summary($where: summary_media_summary_bool_exp!) {
    summary_media_summary(where: $where) {
      video_count
      total_frames
      media_date
      image_count
      customer_id
      customer {
        first_name
        last_name
      }
      camera_id
      camera {
        name
      }
    }
  }
`;

export const notification_analytics = /* GraphQL */ `
  query notification_analytics($args: notification_analytics_arguments!) {
    notification_analytics(args: $args) {
      predator
      mounting
      in_heat
      general
      date
      chin_resting
      calving
    }
  }
`;

export const notification_analytics_customer = /* GraphQL */ `
  query notification_analytics_customer(
    $args: notification_analytics_customer_arguments!
  ) {
    notification_analytics_customer(args: $args) {
      date
      customer5_name
      customer5_count
      customer4_name
      customer4_count
      customer3_name
      customer3_count
      customer2_name
      customer2_count
      customer1_name
      customer1_count
    }
  }
`;

export const notification_analytics_sent = /* GraphQL */ `
  query notification_analytics_sent(
    $args: notification_analytics_sent_arguments!
  ) {
    notification_analytics_sent(args: $args) {
      twilio_count
      sendgrid_count
      date
      both_count
    }
  }
`;

export const calving_analytics_chart = /* GraphQL */ `
  query calving_analytics_chart($args: calving_analytics_chart_arguments!) {
    calving_analytics_chart(args: $args) {
      tail_up
      tail_down
      date
    }
  }
`;

export const calving_chart_customer = /* GraphQL */ `
  query calving_chart_customer($args: calving_chart_customer_arguments!) {
    calving_chart_customer(args: $args) {
      date
      customer5_name
      customer5_count
      customer4_name
      customer4_count
      customer3_name
      customer3_count
      customer2_name
      customer2_count
      customer1_name
      customer1_count
    }
  }
`;

export const calving_chart_sent = /* GraphQL */ `
  query calving_chart_sent($args: calving_chart_sent_arguments!) {
    calving_chart_sent(args: $args) {
      history_check
      gpt_check
      date
    }
  }
`;

export const estrus_analytics_chart = /* GraphQL */ `
  query estrus_analytics_chart($args: estrus_analytics_chart_arguments!) {
    estrus_analytics_chart(args: $args) {
      resting_chin
      in_heat
      date
    }
  }
`;

export const estrus_chart_customer = /* GraphQL */ `
  query estrus_chart_customer($args: estrus_chart_customer_arguments!) {
    estrus_chart_customer(args: $args) {
      date
      customer5_name
      customer5_count
      customer4_name
      customer4_count
      customer3_name
      customer3_count
      customer2_name
      customer2_count
      customer1_name
      customer1_count
    }
  }
`;

export const estrus_chart_sent = /* GraphQL */ `
  query estrus_chart_sent($args: estrus_chart_sent_arguments!) {
    estrus_chart_sent(args: $args) {
      history_check
      gpt_check
      date
    }
  }
`;

export const predator_analytics_chart = /* GraphQL */ `
  query predator_analytics_chart($args: predator_analytics_chart_arguments!) {
    predator_analytics_chart(args: $args) {
      date
      canine
      date
    }
  }
`;

export const predator_chart_customer = /* GraphQL */ `
  query predator_chart_customer($args: predator_chart_customer_arguments!) {
    predator_chart_customer(args: $args) {
      date
      customer5_name
      customer5_count
      customer4_name
      customer4_count
      customer3_name
      customer3_count
      customer2_name
      customer2_count
      customer1_name
      customer1_count
    }
  }
`;

export const predator_chart_sent = /* GraphQL */ `
  query predator_chart_sent($args: predator_chart_sent_arguments!) {
    predator_chart_sent(args: $args) {
      history_check
      gpt_check
      date
    }
  }
`;

export const tag_id_count = /* GraphQL */ `
  query tag_id_count($args: tag_id_count_arguments!) {
    tag_id_count(args: $args) {
      date
      image
      video
    }
  }
`;

export const tag_id_count_by_customer = /* GraphQL */ `
  query tag_id_count_by_customer($args: tag_id_count_by_customer_arguments!) {
    tag_id_count_by_customer(args: $args) {
      date
      customer5_name
      customer5_count
      customer4_name
      customer4_count
      customer3_name
      customer3_count
      customer2_name
      customer2_count
      customer1_name
      customer1_count
    }
  }
`;

export const tag_id_count_unique = /* GraphQL */ `
  query tag_id_count_unique($args: tag_id_count_unique_arguments!) {
    tag_id_count_unique(args: $args) {
      date
      count
    }
  }
`;
export const tag_id_count_unique_label = /* GraphQL */ `
  query tag_id_count_unique_label($args: tag_id_count_unique_label_arguments!) {
    tag_id_count_unique_label(args: $args) {
      date
      count
    }
  }
`;
export const tag_id_count_unique_color = /* GraphQL */ `
  query tag_id_count_unique_color($args: tag_id_count_unique_color_arguments!) {
    tag_id_count_unique_color(args: $args) {
      date
      count
    }
  }
`;

//number of (unique) drop tags seen per day
//number of (unique) colors seen per day


export const animal_identifiation_count_userdb = /* GraphQL */ `
  query animal_identifiation_count_userdb(
    $args: animal_identifiation_count_userdb_arguments!
  ) {
    animal_identifiation_count_userdb(args: $args) {
      date
      count
    }
  }
`;