import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "aws-amplify";
import axios from "axios";
//import moment from 'moment';
import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";
import moment from "moment";
// import * as subscriptions from './graphql/subscriptions';
import { API } from "aws-amplify";
// import { eventBus } from './main';
import * as hasura_queries from "./graphql_hasura/queries";
// AWS SDK V3
import {
  ListGroupsCommand,
  ListUsersCommand,
  ListUsersInGroupCommand,
} from "@aws-sdk/client-cognito-identity-provider";

import { initializeCognitoIdentityProviderClient } from "./utilities/sessionUtils";

import { SSMClient, GetParametersByPathCommand } from "@aws-sdk/client-ssm";

var cognitoidentityserviceprovider;
// = new CognitoIdentityProviderClient({region: process.env.VUE_APP_AWS_REGION});

Vue.use(Vuex);

var sem_status = {};

const getByPagination = async function (type, query_name = undefined) {
  var objects = [];
  var next_token = null;
  // console.log(type);
  try {
    do {
      var o = await API.graphql({
        query: queries[type],
        variables: { limit: 500, nextToken: next_token },
      });
      var output_field = query_name || type;
      objects = [...objects, ...o.data[output_field].items];
      // console.log(o);
      next_token = o.data[output_field].nextToken;
    } while (next_token);
  } catch (err) {
    console.log(err);
  }
  return objects;
};

const getAllCamerasByModel = async function (model, next_token = undefined) {
  var query_variables = {
    filter: {
      camera_model: { eq: model },
    },
  };
  if (next_token) {
    query_variables.nextToken = next_token;
  }
  var res = await API.graphql({
    query: queries.searchCameraByModel,
    variables: query_variables,
  });
  if (!next_token) {
    return res.data.searchCameras.items.map((e) => e.id);
  } else {
    var next_cams = await getAllCamerasByModel(model, next_token);
    return [...res.data.searchCameras.items.map((e) => e.id), ...next_cams];
  }
};

const updateImageList = (existingImages, newImages, offset) => {
  const combinedImages =
    existingImages.length > 0 ? existingImages[0].concat(newImages) : newImages;
  return [combinedImages, offset, combinedImages.length];
};

const updateVideoList = (existingVideos, newVideos, offset) => {
  const combinedVideos =
    existingVideos.length > 0 ? existingVideos[0].concat(newVideos) : newVideos;
  return [combinedVideos, offset, combinedVideos.length];
};

const buildEstrusQuery = (filters, videos = false) => {
  let whereClauses = [`gpt_check: {_eq: true}`];
  if (filters) {
    let orConditions = [];

    Object.entries(filters.estrus).forEach(([key, value]) => {
      if (
        ["mounting", "resting_chin", "in_heat"].includes(key) &&
        value === true
      ) {
        orConditions.push(`{class_label: {_eq: "${key}"}}`);
      }
    });

    if (orConditions.length > 0) {
      whereClauses.push(`_or: [${orConditions.join(", ")}]`);
    }

    if (filters.class_conf) {
      const operator = filters.class_conf.includes(">= ") ? "_gte" : "_lte";
      const value = parseFloat(filters.class_conf.slice(3));
      whereClauses.push(`class_conf: {${operator}: ${value}}`);
    }
  }

  const mediaType = videos ? "VIDEO" : "IMAGE";
  whereClauses.push(`media_type: {_eq: "${mediaType}"}`);

  let whereClause = `{${whereClauses.join(", ")}}`;

  let query = `
  query ($limit: Int!, $offset: Int!) {
    analytics_estrus(limit: $limit, offset: $offset, order_by: {media_timestamp: desc}, where: ${whereClause}) {
      camera_id
      bbox_x
      bbox_y
      bbox_w
      bbox_h
      class_conf
      class_label
      conf_check
      gpt_check
      gpt_description
      oo_bbox_h
      oo_bbox_w
      oo_bbox_x
      oo_bbox_y
      oo_tracking_id
      recent_sent_check
      s3_uri
      media_timestamp
      tracking_id
      species_label
      species_conf
      class_label_2
      class_conf_2
      customer {
        id
        first_name
        last_name
      }
    }
  }`;

  return query;
};

const buildQuery = (filters, videos = false) => {
  // console.log(filters)
  let classificationArray = ["species_label", "species_conf"];
  // Base query structure

  let query = `
  query ($limit: Int!, $offset: Int!) {
    inference_media(limit: $limit, offset: $offset, order_by: {media_timestamp: desc}`;

  const constructWhereClause = (classification, values) => {
    if (
      classification === "conf" ||
      classification === "class_label" ||
      classification === "body_parts" ||
      classification === "estrus" ||
      classification === "body_parts_conf" ||
      classification === "Time" ||
      classification === "Tag1" ||
      classification === "Tag2"
    ) {
      return "";
    }
    const conditions = Object.entries(values)
      .filter(([, value]) => value) // Simplified filter to check only the value
      .map(([key]) => {
        if (!classificationArray.includes(classification)) {
          classificationArray.push(classification);
        }

        return key === "null"
          ? `{${classification}: {_is_null: true}}`
          : `{${classification}: {_eq: "${key}"}}`;
      });

    return conditions.length > 0 ? `{ _or: [${conditions.join(", ")}] }` : "";
  };

  // Constructing the where clause dynamically based on filters

  const whereClauses = Object.entries(filters)
    .map(([classification, values]) =>
      constructWhereClause(classification, values)
    )
    .filter((clause) => clause.length > 0);

  if (filters.conf) {
    Object.entries(filters.conf).forEach(([key, value]) => {
      if (value != null) {
        if (
          key === "class_conf" ||
          key === "bbox_ratio" ||
          key === "tail_ratio"
        ) {
          return;
        }
        if (!classificationArray.includes(key)) {
          classificationArray.push(key);
        }
        let condition;
        if (value.startsWith(">= ")) {
          condition = `{${key}: {_gte: "${value.slice(3)}"}}`;
        } else if (value.startsWith("<= ")) {
          condition = `{${key}: {_lte: "${value.slice(3)}"}}`;
        }

        if (condition) {
          whereClauses.push(condition);
        }
      }
    });
  }
  let classLabelConditions = [];
  if (filters.class_label) {
    Object.entries(filters.class_label).forEach(([key, value]) => {
      if (value === true) {
        classLabelConditions.push(`class_label: {_eq: "${key}"}`);
      }
    });

    if (filters.conf && filters.conf.class_conf) {
      let condition;
      if (filters.conf.class_conf.startsWith(">= ")) {
        condition = `class_conf: {_gte: "${filters.conf.class_conf.slice(3)}"}`;
      } else if (filters.conf.class_conf.startsWith("<= ")) {
        condition = `class_conf: {_lte: "${filters.conf.class_conf.slice(3)}"}`;
      }
      classLabelConditions.push(condition);
      // combinedWhereClause = combinedWhereClause.replace("{", `{ ${condition}`);
    }

    if (filters.conf && filters.conf.bbox_ratio) {
      let condition;
      if (filters.conf.bbox_ratio.startsWith(">= ")) {
        condition = `bbox_ratio: {_gte: "${filters.conf.bbox_ratio.slice(3)}"}`;
      } else if (filters.conf.bbox_ratio.startsWith("<= ")) {
        condition = `bbox_ratio: {_lte: "${filters.conf.bbox_ratio.slice(3)}"}`;
      }
      classLabelConditions.push(condition);
      // combinedWhereClause = combinedWhereClause.replace("{", `{ ${condition}`);
    }

    let bodyPartsConditions = [];

    // Handle filters.conf
    if (filters.conf && filters.conf.tail_ratio) {
      let condition;
      if (filters.conf.tail_ratio.startsWith(">= ")) {
        condition = `tail_ratio: {_gte: "${filters.conf.tail_ratio.slice(3)}"}`;
      } else if (filters.conf.tail_ratio.startsWith("<= ")) {
        condition = `tail_ratio: {_lte: "${filters.conf.tail_ratio.slice(3)}"}`;
      }
      if (condition) {
        bodyPartsConditions.push(condition);
      }
    }

    if (filters.body_parts) {
      for (const key in filters.body_parts) {
        if (filters.body_parts[key] === true) {
          bodyPartsConditions.push(`${key}: {_is_null: false}`);
        }
      }
    }

    if (filters.body_parts_conf) {
      for (const key in filters.body_parts_conf) {
        const value = filters.body_parts_conf[key];
        let condition;

        if (value.startsWith(">=")) {
          condition = `${key} : {_gte: "${value.slice(3)}" }`;
        } else if (value.startsWith("<=")) {
          condition = `${key} : {_lte: "${value.slice(3)}" }`;
        }

        bodyPartsConditions.push(condition);
      }
    }

    if (filters.Tag1) {
      if (filters.Tag1.tag_1_id_label) {
        bodyPartsConditions.push(
          `tag_1_id_label : {_eq: "${filters.Tag1.tag_1_id_label}"}`
        );
      }

      if (filters.Tag1.tag_1_id_conf) {
        let condition;
        if (filters.Tag1.tag_1_id_conf.startsWith(">= ")) {
          condition = `tag_1_id_conf: {_gte: "${filters.Tag1.tag_1_id_conf.slice(
            3
          )}"}`;
        } else if (filters.Tag1.tag_1_id_conf.startsWith("<= ")) {
          condition = `tag_1_id_conf: {_lte: "${filters.Tag1.tag_1_id_conf.slice(
            3
          )}"}`;
        }
        if (condition) {
          bodyPartsConditions.push(condition);
        }
      }
    }

    if (filters.Tag2) {
      if (filters.Tag2.tag_2_id_label) {
        bodyPartsConditions.push(
          `tag_2_id_label : {_eq: "${filters.Tag2.tag_2_id_label}"}`
        );
      }

      if (filters.Tag2.tag_2_id_conf) {
        let condition;
        if (filters.Tag2.tag_2_id_conf.startsWith(">= ")) {
          condition = `tag_2_id_conf: {_gte: "${filters.Tag2.tag_2_id_conf.slice(
            3
          )}"}`;
        } else if (filters.Tag2.tag_2_id_conf.startsWith("<= ")) {
          condition = `tag_2_id_conf: {_lte: "${filters.Tag2.tag_2_id_conf.slice(
            3
          )}"}`;
        }
        if (condition) {
          bodyPartsConditions.push(condition);
        }
      }
    }

    // Combine conditions for GraphQL query
    let combinedWhereClause = "";
    if (bodyPartsConditions.length > 0) {
      combinedWhereClause = `body_parts: {${bodyPartsConditions.join(", ")}}`;
      // Add to classLabel if it's part of a larger query
      classLabelConditions.push(combinedWhereClause);
    }
  }
  let pgiesWhereClause =
    whereClauses.length > 0
      ? `classifications: {_and: [${whereClauses.join(", ")}]}`
      : "";
  let classLabelWhereClause =
    classLabelConditions.length > 0
      ? `, ${classLabelConditions.join(", ")}`
      : "";
  let combinedWhereClause =
    pgiesWhereClause || classLabelWhereClause
      ? `{${pgiesWhereClause}${classLabelWhereClause}}`
      : "";

  let mediaType;
  if (videos) {
    mediaType = "VIDEO";
  } else {
    mediaType = "IMAGE";
  }

  query += combinedWhereClause
    ? `, where: {media_type: {_eq: ${mediaType}}, pgies: ${combinedWhereClause}}`
    : `, where: {media_type: {_eq: ${mediaType}}}`;

  if (videos) {
    query += `) {
        media_timestamp
        camera_id
        customer_id
        width
        uploaded_at
        ratio
        num_frames_inferred
        num_frames
        media_type
        media_timestamp_to_inference
        keypoint_type
        json_type
        inference_to_upload
        inference_start
        height
        inference_end
        fps
        `;
  } else {
    query += combinedWhereClause
      ? `) {
      media_timestamp
      camera_id
      customer_id
      width
      height
      pgies(where :${combinedWhereClause})`
      : `) {
        media_timestamp
        camera_id
        customer_id
        width
        height
        pgies`;
  }

  // query += `) {
  //   media_timestamp
  //   camera_id
  //   customer_id
  //   pgies : ${combinedWhereClause}`

  // if (whereClauses.length > 0) {
  //   query += `(where: {classifications: { _and: [${whereClauses.join(
  //     ", "
  //   )}] }})`;
  // }

  if (videos) {
    query += `
    }
  }`;
  } else {
    // let classificationFields = classificationArray
    //   .map((name) => `${name}`)
    //   .join("\n        ");
    query += `{
        bbox
        tracking_id
        body_parts {
          foot_1_bbox
          foot_1_conf
          foot_1_label
          foot_2_bbox
          foot_2_conf
          foot_2_label
          foot_3_bbox
          foot_3_conf
          foot_3_label
          foot_4_bbox
          foot_4_conf
          foot_4_label
          head_bbox
          head_conf
          head_label
          joint_1_bbox
          joint_1_conf
          joint_1_label
          joint_2_bbox
          joint_2_conf
          joint_2_label
          joint_3_bbox
          joint_3_conf
          joint_3_label
          joint_4_bbox
          joint_4_conf
          joint_4_label
          tag_1_bbox
          tag_1_colour_conf
          tag_1_colour_id
          tag_1_colour_label
          tag_1_conf
          tag_1_id_conf
          tag_1_id_label
          tag_1_label
          tag_2_bbox
          tag_2_colour_conf
          tag_2_colour_id
          tag_2_colour_label
          tag_2_conf
          tag_2_id_conf
          tag_2_id_label
          tag_2_label
          tail_bbox
          tail_conf
          tail_label
        }
        class_label
        class_conf
        classifications {
          species_label
          species_conf
          contraction_conf_2
          contraction_conf
          contraction_label
          contraction_label_2
          mounting_label_2
          mounting_conf_2
          chinrest_label_2
          chinrest_conf_2
          age_label
          age_conf
          activity_label
          activity_conf
          breed_label
          breed_conf
        }
      }
      analytics_predator {
        tracking_id
        species_label
        species_conf
        s3_uri
        media_type
        media_timestamp
        gpt_description
        gpt_check
        frame_num
        conf_check
        camera_id
        breed_label
        breed_conf
        bbox_y
        bbox_x
        bbox_w
        bbox
        bbox_h
      }
      analytics_estrus {
        camera_id
        bbox_x
        bbox_y
        bbox_w
        bbox_h
        class_conf
        class_label
        conf_check
        gpt_check
        gpt_description
        oo_bbox_h
        oo_bbox_w
        oo_bbox_x
        oo_bbox_y
        oo_tracking_id
        recent_sent_check
        s3_uri
        media_timestamp
        tracking_id
        species_label
        species_conf
        class_label_2
        class_conf_2
        customer {
          id
          first_name
          last_name
        }
      }
      analytics_calving {
        tracking_id
        tail_ratio
        tail_conf
        tail_bbox
        tail_area
        tag_2_label
        tag_2_id_label
        tag_2_id_conf
        tag_2_conf
        tag_2_colour_conf
        tag_2_colour_label
        tag_2_bbox
        tag_1_label
        tag_1_id_label
        tag_1_id_conf
        tag_1_conf
        tag_1_colour_label
        tag_1_colour_conf
        tag_1_bbox
        species_label
        species_conf
        s3_uri
        gpt_check
        contraction_label_2
        contraction_label
        contraction_conf_2
        contraction_conf
        calving_label
        calving_conf
        bbox
      }
    }
  }`;
  }
  return query;
};

const store = new Vuex.Store({
  modules: {},
  state: {
    hasuraAdminSecret: null,
    authorized: false,
    user: null,
    user_ddb: "",
    currentTemp: "",
    loginError: "",
    signupError: "",
    latitude: "",
    longitude: "",
    coordinates: Object,
    button: Boolean,
    // loadedCattle: [],
    ddbGetData: [],
    camera_objects: [],
    camera_count: 0,
    doneFetching: false,
    staging_data: [],
    production_data: [],
    staging_cameras: [],
    production_cameras: [],
    staging_ranches: [],
    production_ranches: [],
    site_objects: [],
    cognito_users: [],
    customer_objects: [],
    jetson_objects: [],
    wifi_objects: [],
    owner_name_object: {},
    name_owner_object: {},
    customer_names: [],
    site_name_object: {},
    name_site_object: {},
    site_names: [],
    videos: [],
    events: [],
    selected_customer: {},
    edited_customer: {},
    animals: [],
    herds: [],
    footage: [],
    contacts: [],
    projects: [],
    camera_ids: [],
    selected_project_contacts: [],
    project_id_names: [],
    correspondences: [],
    all_correspondences: [],
    selected_projects: [],
    selected_contacts: [],
    contact_names: [],
    last_footage_upload_cameras: [],
    selected_camera: [],
    selected_notes: [],
    notes: [],
    feedbacks: [],
    treeview_customer: null,

    notifications: null,
    notification_count: 0,

    camera_models: [
      "E1 Outdoor",
      "RLC-511WA",
      "RLC-511W",
      "E1 Zoom",
      "RLC-811A",
      "Argus PT",
      "Reolink Go PT",
      "iPhone",
      "Duo Wifi",
      "RLC-523WA",
      "RLC-542WA",
      "Trackmix WIFI",
      "Trackmix POE",
      "823A",
    ],
    camera_sources: {},
    camera_resolutions: [],
    camera_shipping_status: [
      "DELIVERED",
      "INVENTORY",
      "DEVELOPMENT",
      "SHIPPED",
      "UNDEFINED",
    ],
    camera_online_status: ["ONLINE", "OFFLINE", "WARNING"],
    customer_status: ["ACTIVE", "INACTIVE", "DISABLED"],
    shipping_status: [
      "DELIVERED",
      "INVENTORY",
      "DEVELOPMENT",
      "SHIPPED",
      "UNDEFINED",
    ],
    online_status: ["ONLINE", "OFFLINE", "WARNING", "UNDEFINED"],
    wifi_models: [
      "AC_LITE",
      "AC_LITEBEAM",
      "AC_LITE_V6",
      "AC_LR",
      "AC_LR_V6",
      "AC_PRO",
      "GOOGLE_POINT",
      "H2D",
      "H2E",
      "H3E",
      "UNDEFINED",
    ],
    wifi_shipping_status: ["DELIVERED", "INVENTORY", "SHIPPED", "UNDEFINED"],
    wifi_online_status: ["ONLINE", "OFFLINE", "WARNING", "UNDEFINED"],
    wifi_manufacturers: ["EERO", "GOOGLE", "UBIQUITI"],
    jetson_online_status: [],
    jetson_shipping_status: [],

    customer_card_dialog: false,
    user_card_dialog: false,
    site_card_dialog: false,
    camera_card_dialog: false,
    nuke_card_dialog: false,
    wifi_card_dialog: false,
    jetson_card_dialog: false,
    collaboration_card_dialog: false,
    note_card_dialog: false,
    image_card_dialog: false,
    image_card_minimal_dialog: false,
    general_filter_dialog: false,

    provinces: [
      "AB",
      "BC",
      "MB",
      "NB",
      "NL",
      "NT",
      "NS",
      "NU",
      "ON",
      "PE",
      "QC",
      "SK",
      "YT",
    ],
    states: [
      "AL",
      "AK",
      "AS",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FM",
      "FL",
      "GA",
      "GU",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MH",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "MP",
      "OH",
      "OK",
      "OR",
      "PW",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VI",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ],
    jwt_token: null,

    customer_types: [
      "ADMIN",
      "BETA",
      "CUSTOMER",
      "EARLY_ADOPTER",
      "DEMO",
      "DEVELOPER",
      "INVENTORY",
      "PARTNER",
      "RESEARCHER",
      "USER",
    ],
    notification_levels: [],
    notification_frequency: [
      "NEVER",
      "FIVE_MINUTES",
      "FIFTEEN_MINUTES",
      "THIRTY_MINUTES",
      "ONE_HOUR",

      "TWO_HOURS",
      "FOUR_HOURS",
      "EIGHT_HOURS",
      "TWELVE_HOURS",
      "ONE_DAY",
    ],
    wait_interval: 2500,

    images: [],
    imagesImagePlayer: [],
    videosBoard: [],
    videosTable: [],
    videos_timestream: [],
    notification_fields: [
      {
        text: "Alert Call",
        value: "notification_preferences.alert_call_frequency",
      },
      {
        text: "Alert Email",
        value: "notification_preferences.alert_email_frequency",
      },
      {
        text: "Alert SMS",
        value: "notification_preferences.alert_sms_frequency",
      },
      {
        text: "Warning Call",
        value: "notification_preferences.warning_call_frequency",
      },
      {
        text: "Warning Email",
        value: "notification_preferences.warning_email_frequency",
      },
      {
        text: "Warning SMS",
        value: "notification_preferences.warning_sms_frequency",
      },
      {
        text: "Info Call",
        value: "notification_preferences.info_call_frequency",
      },
      {
        text: "Info Email",
        value: "notification_preferences.info_email_frequency",
      },
      {
        text: "Info SMS",
        value: "notification_preferences.info_sms_frequency",
      },
    ],
    bbox_color_code: {
      head: {
        fillStyle: "rgba(0, 154, 255, 0.4)",
        strokeStyle: "rgba(0, 154, 255)",
      },
      tag: {
        fillStyle: "rgba(55, 255, 255, 0.4)",
        strokeStyle: "rgba(55, 255, 255)",
      },
      tail: {
        fillStyle: "rgba(255, 66, 161, 0.4)",
        strokeStyle: "rgba(255, 66, 161)",
      },
      car: {
        fillStyle: "rgba(186, 86, 36, 0.4)",
        strokeStyle: "rgba(186, 86, 36)",
      },
      vehicle: {
        fillStyle: "rgba(186, 86, 36, 0.4)",
        strokeStyle: "rgba(186, 86, 36)",
      },
      cat: {
        fillStyle: "rgba(92, 103, 132, 0.4)",
        strokeStyle: "rgba(92, 103, 132)",
      },
      cow: {
        fillStyle: "rgba(242, 114, 0, 0.4)",
        strokeStyle: "rgba(242, 114, 0)",
      },
      dog: {
        fillStyle: "rgba(32, 164, 243, 0.4)",
        strokeStyle: "rgba(32, 164, 243)",
      },
      horse: {
        fillStyle: "rgba(148, 123, 211, 0.4)",
        strokeStyle: "rgba(148, 123, 211)",
      },
      person: {
        fillStyle: "rgba(240, 167, 160, 0.4)",
        strokeStyle: "rgba(240, 167, 160)",
      },
      pig: {
        fillStyle: "rgba(96, 73, 90, 0.4)",
        strokeStyle: "rgba(96, 73, 90)",
      },
      sheep: {
        fillStyle: "rgba(255, 87, 187, 0.4)",
        strokeStyle: "rgba(255, 87, 187)",
      },
      truck: {
        fillStyle: "rgba(140, 39, 30, 0.4)",
        strokeStyle: "rgba(140, 39, 30)",
      },
      coyote: {
        fillStyle: "rgba(247, 238, 127, 0.4)",
        strokeStyle: "rgba(247, 238, 127)",
      },
      canine: {
        fillStyle: "rgba(247, 238, 127, 0.4)",
        strokeStyle: "rgba(247, 238, 127)",
      },
      bear: {
        fillStyle: "rgba(164, 180, 148, 0.4)",
        strokeStyle: "rgba(164, 180, 148)",
      },
      bison: {
        fillStyle: "rgba(196, 167, 125, 0.4)",
        strokeStyle: "rgba(196, 167, 125)",
      },
    },
    PGIE_type_list: ["animal", "person", "vehicle"],
    Species_type_list: [
      "bison",
      "canine",
      "cat",
      "cow",
      "donkey",
      "elk",
      "horse",
      "llama",
      "null",
      "pig",
      "sheep",
      "goat",
    ],
    SGIE_type_list: ["head", "tail", "tag"],
    Vehicle_type_list: [
      "car",
      "motorcycle",
      "pick_up_truck",
      "quad",
      "semi_Truck",
      "tractor",
      "van",
    ],
    Vehicle_make_list: [
      "chevrolet",
      "honda",
      "hyundai",
      "jeep",
      "kia",
      "mazda",
      "nissan",
      "toyota",
      "ford",
      "ram",
      "null",
    ],
    Human_pose_list: ["riding", "squat", "standing"],
    model_version_list: [
      "nobj_timestamp",
      "pgie_yolox",
      "pgie_yolov8",
      "pgie_yolox_ensemble",
      "sgie_dog_activity",
      "sgie_cow_suckling",
      "sgie_keypoint_0_ensemble",
      "sgie_keypoint_0_ensemble_dog",
      "sgie_cow_body_part_ensemble",
      "sgie_cow_age",
      "sgie_contraction",
      "sgie_cow_contraction",
      "sgie_dog_body_part_ensemble",
      "sgie_cow_ocr",
      "sgie_cow_activity",
      "sgie_bbox_colour_tag",
      "sgie_body_parts",
      "sgie_cow_keypoints",
      "sgie_cow_mounting",
      "sgie_dog_breed",
      "sgie_license_plate_ocr",
      "sgie_dog_timeseries_scratching_ensemble",
      "sgie_keypoint_human_ensemble",
      "sgie_license_plates",
      "sgie_tag_colour",
      "sgie_vehicle_brand",
      "sgie_vehicle_type",
      "sgie_cow_delivery",
      "sgie_cow_chinrest",
      "sgie_cow_mounting_cross_tree",
      "sgie_species",
      "sgie_heat_detection",
    ],
    time_ranges: [
      { text: "Last 5 Minutes", value: "5m" },
      { text: "Last 10 Minutes", value: "10m" },
      { text: "Last 15 Minutes", value: "15m" },
      { text: "Last 30 Minutes", value: "30m" },
      { text: "Last 1 Hours", value: "1h" },
      { text: "Last 3 Hours", value: "3h" },
      { text: "Last 6 Hours", value: "6h" },
      { text: "Last 12 Hours", value: "12h" },
      { text: "Last 24 Hours", value: "24h" },
      { text: "Last 3 Days", value: "3d" },
      { text: "Last 7 Days", value: "7d" },
      { text: "Last 14 Days", value: "14d" },
      { text: "Last 28 Days", value: "28d" },
    ],
    time_settings: {
      "5m": {
        bucket: "1minute",
        from: "now-5m",
        to: "now",
        moment_args: [5, "minutes"],
      },
      "10m": {
        bucket: "1minute",
        from: "now-10m",
        to: "now",
        moment_args: [10, "minutes"],
      },
      "15m": {
        bucket: "1minute",
        from: "now-15m",
        to: "now",
        moment_args: [15, "minutes"],
      },
      "30m": {
        bucket: "5minute",
        from: "now-30m",
        to: "now",
        moment_args: [30, "minutes"],
      },
      "1h": {
        bucket: "5minute",
        from: "now-1h",
        to: "now",
        moment_args: [1, "hours"],
      },
      "3h": {
        bucket: "15minute",
        from: "now-3h",
        to: "now",
        moment_args: [3, "hours"],
      },
      "6h": {
        bucket: "1hour",
        from: "now-6h",
        to: "now",
        moment_args: [6, "hours"],
      },
      "12h": {
        bucket: "1hour",
        from: "now-12h",
        to: "now",
        moment_args: [12, "hours"],
      },
      "24h": {
        bucket: "1hour",
        from: "now-24h",
        to: "now",
        moment_args: [24, "hours"],
      },
      "3d": {
        bucket: "1day",
        from: "now-3d",
        to: "now",
        moment_args: [3, "days"],
      },
      "7d": {
        bucket: "1day",
        from: "now-7d",
        to: "now",
        moment_args: [7, "days"],
      },
      "14d": {
        bucket: "1day",
        from: "now-14d",
        to: "now",
        moment_args: [14, "days"],
      },
      "28d": {
        bucket: "1day",
        from: "now-28d",
        to: "now",
        moment_args: [28, "days"],
      },
    },
    current_customer: {},
    animal_tags: [],

    video_years: [2021, 2022, 2023],
    animal_images: [],
    animal_images_count: 0,
    triton_model_list: [
      "nobj_timestamp",
      "pgie_yolox",
      "sgie_body_parts",
      "sgie_cow_activity",
      "sgie_cow_age",
      "sgie_cow_chinrest",
      "sgie_cow_contraction",
      "sgie_cow_delivery",
      "sgie_cow_keypoints",
      "sgie_cow_keypoints_ensemble",
      "sgie_cow_keypoints_postprocess",
      "sgie_cow_mounting",
      "sgie_cow_ocr",
      "sgie_cow_suckling",
      "sgie_dog_activity",
      "sgie_dog_breed",
      "sgie_license_plate_ocr",
      "sgie_license_plates",
      "sgie_species",
      "sgie_tag_colour",
      "sgie_vehicle_type",
    ],
    triton_metric_list: [
      "nv_cache_hit_lookup_duration_per_model_total",
      "nv_cache_miss_insertion_duration_per_model_total",
      "nv_cache_miss_lookup_duration_per_model_total",
      "nv_cache_num_hits_per_model_total",
      "nv_cache_num_misses_per_model_total",
      "nv_inference_compute_infer_duration_us_total",
      "nv_inference_compute_input_duration_us_total",
      "nv_inference_compute_output_duration_us_total",
      "nv_inference_count_total",
      "nv_inference_exec_count_total",
      "nv_inference_queue_duration_us_total",
      "nv_inference_request_duration_us_total",
      "nv_inference_request_failure_total",
      "nv_inference_request_success_total",
    ],

    hasura_params: {
      url: "https://onecup-writer.hasura.app/v1/graphql",
      headers: {
        Authorizaation: "",
        "Content-Type": "application/json",
        "x-hasura-admin-secret": "",
      },
    },

    hasura_params_write: {
      url: "https://onecup-writer.hasura.app/v1/graphql",
      headers: {
        Authorizaation: "",
        "Content-Type": "application/json",
        "x-hasura-admin-secret": "",
      },
    },

    admin_key: null,

    selection_cameras: [],

    summary_media: [],
    summary_pgie: [],
    summary_activity: [],
    summary_calving: [],
    summary_estrus: [],
    summary_species: [],
    summary_vpc: [],
    summary_vehicle: [],
    summary_tag: [],

    home_page_stats: {
      customer_count: "...",
      site_count: "...",
      animal_count: "...",
      animal_tag_count: "...",
      animal_image_count: "...",
      camera_count: "...",
      online_camera_count: "...",
      wifi_count: "...",
    },

    analytics_calving: [],
    analytics_calving_count: 0,

    analytics_estrus: [],
    analytics_estrus_count: 0,

    analytics_predator: [],
    analytics_predator_count: 0,

    media_by_camera_summary: [],

    notification_analytics: { type: [], customer: [], sent: [] },

    calving_charts: { type: [], customer: [], sent: [] },
    estrus_charts: { type: [], customer: [], sent: [] },
    predator_charts: { type: [], customer: [], sent: [] },

    animal_id_charts: { type: [], customer: [] },
    animal_id_unique_charts: { combo: [], label: [], color: [] },

    animal_id_userdb: { total: [], pending: [], approved: [], denied: [] },
  },

  mutations: {
    SET_ANIMAL_ID_USERDB(state, payload) {
      state.animal_id_userdb = payload;
    },
    SET_ANIMAL_ID_UNIQUE_CHARTS(state, payload) {
      state.animal_id_unique_charts = payload;
    },
    SET_ANIMAL_ID_CHARTS(state, payload) {
      state.animal_id_charts = payload;
    },
    SET_CALVING_CHARTS(state, payload) {
      state.calving_charts = payload;
    },
    SET_ESTRUS_CHARTS(state, payload) {
      state.estrus_charts = payload;
    },
    SET_PREDATOR_CHARTS(state, payload) {
      state.predator_charts = payload;
    },
    SET_NOTIFICATION_ANALYTICS(state, payload) {
      state.notification_analytics = payload;
    },
    SET_MEDIA_BY_CAMERA_SUMMARY(state, payload) {
      state.media_by_camera_summary = payload;
    },
    SET_MODEL_VERSION_LIST(state, payload) {
      state.model_version_list = payload;
    },
    SET_ANIMAL_TAGS(state, payload) {
      state.animal_tags = payload;
    },
    SET_WIFI_MODELS(state, payload) {
      state.wifi_models = payload;
    },
    SET_CURRENT_CUSTOMER(state, payload) {
      state.current_customer = payload;
    },
    SET_IMAGES(state, payload) {
      state.images = payload;
    },
    SET_IMAGES_IMAGEPLAYER(state, payload) {
      state.imagesImagePlayer = payload;
    },
    SET_VIDEOS_TIMESTREAM(state, payload) {
      state.videos_timestream = payload;
    },
    SET_VIDEOS_VIDEOBOARD(state, payload) {
      state.videosBoard = payload;
    },
    SET_VIDEOS_VIDEOTABLE(state, payload) {
      state.videosTable = payload;
    },
    SET_CUSTOMER_TYPES(state, payload) {
      state.customer_types = payload;
    },
    SET_NOTIFICATION_LEVELS(state, payload) {
      state.notification_levels = payload;
    },
    SET_NOTIFICATION_FREQUENCY(state, payload) {
      state.notification_frequency = payload;
    },
    SET_JWT_TOKEN(state, payload) {
      state.jwt_token = payload;
    },
    SET_NOTE_CARD_DIALOG(state, payload) {
      state.note_card_dialog = payload;
    },
    SET_IMAGE_CARD_DIALOG(state, payload) {
      state.image_card_dialog = payload;
    },
    SET_IMAGE_CARD_MINIMAL_DIALOG(state, payload) {
      state.image_card_minimal_dialog = payload;
    },
    SET_GENERAL_FILTER_DIALOG(state, payload) {
      state.general_filter_dialog = payload;
    },
    SET_CUSTOMER_CARD_DIALOG(state, payload) {
      state.customer_card_dialog = payload;
    },
    SET_USER_CARD_DIALOG(state, payload) {
      state.user_card_dialog = payload;
    },
    SET_SITE_CARD_DIALOG(state, payload) {
      state.site_card_dialog = payload;
    },
    SET_CAMERA_CARD_DIALOG(state, payload) {
      state.camera_card_dialog = payload;
    },
    SET_NUKE_CARD_DIALOG(state, payload) {
      state.nuke_card_dialog = payload;
    },
    SET_WIFI_CARD_DIALOG(state, payload) {
      state.wifi_card_dialog = payload;
    },
    SET_JETSON_CARD_DIALOG(state, payload) {
      state.jetson_card_dialog = payload;
    },
    SET_COLLABORATION_CARD_DIALOG(state, payload) {
      state.collaboration_card_dialog = payload;
    },
    SET_TREEVIEW_CUSTOMER(state, payload) {
      state.treeview_customer = payload;
    },
    SET_FEEDBACKS(state, payload) {
      state.feedbacks = payload;
    },
    SET_NOTES(state, payload) {
      state.notes = payload;
    },
    SET_SELECTED_NOTES(state, payload) {
      state.selected_notes = payload;
    },
    SET_SELECTED_CAMERA(state, payload) {
      state.selected_camera = payload;
    },
    SET_LAST_FOOTAGE_UPLOAD_CAMERAS(state, payload) {
      state.last_footage_upload_cameras = payload;
    },
    SET_CONTACT_NAMES(state, payload) {
      state.contact_names = payload;
    },
    SET_SELECTED_CONTACTS(state, payload) {
      state.selected_contacts = payload;
    },
    SET_SELECTED_PROJECTS(state, payload) {
      state.selected_projects = payload;
    },
    SET_ALL_CORRESPONDENCES(state, payload) {
      state.all_correspondences = payload;
    },
    SET_CORRESPONDENCES(state, payload) {
      state.correspondences = payload;
    },
    SET_CAMERA_IDS(state, payload) {
      state.camera_ids = payload;
    },
    SET_CONTACTS(state, payload) {
      state.contacts = payload;
    },
    SET_HERDS(state, payload) {
      state.herds = payload;
    },
    SET_ANIMALS(state, payload) {
      state.animals = payload;
    },
    SET_SELECTED_CUSTOMER(state, payload) {
      state.selected_customer = payload;
    },
    SET_EDITED_CUSTOMER(state, payload) {
      state.edited_customer = payload;
    },
    SET_SITE_NAME_OBJECT(state, payload) {
      state.site_name_object = payload;
    },
    SET_NAME_SITE_OBJECT(state, payload) {
      state.name_site_object = payload;
    },
    SET_SITE_NAMES(state, payload) {
      state.site_names = payload;
    },
    SET_VIDEOS(state, payload) {
      state.videos = payload;
    },
    SET_EVENTS(state, payload) {
      state.events = payload;
    },
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload.notifications;
      state.notification_count = payload.notification_count;
    },
    SET_OWNER_NAME_OBJECT(state, payload) {
      state.owner_name_object = payload;
    },
    SET_NAME_OWNER_OBJECT(state, payload) {
      state.name_owner_object = payload;
    },
    SET_CUSTOMER_NAMES(state, payload) {
      state.customer_names = payload;
    },
    SET_COORDINATES(state, payload) {
      state.coordinates = payload;
    },
    SET_PRODUCTION_DATA(state, payload) {
      state.production_data = payload;
    },
    SET_CAMERA_OBJECTS(state, payload) {
      state.camera_objects = payload.cameras;
      state.camera_count = payload.count;
    },
    SET_CAMERA_MODELS(state, payload) {
      state.camera_models = payload;
    },
    SET_CAMERA_RESOLUTIONS(state, payload) {
      state.camera_resolutions = payload;
    },
    SET_CAMERA_SOURCES(state, payload) {
      state.camera_sources = payload;
    },
    SET_CAMERA_SHIPPING_STATUS(state, payload) {
      state.camera_shipping_status = payload;
    },
    SET_CAMERA_ONLINE_STATUS(state, payload) {
      state.camera_online_status = payload;
    },
    SET_WIFI_SHIPPING_STATUS(state, payload) {
      state.wifi_shipping_status = payload;
    },
    SET_WIFI_ONLINE_STATUS(state, payload) {
      state.wifi_online_status = payload;
    },
    SET_WIFI_MANUFACTURERS(state, payload) {
      state.wifi_manufacturers = payload;
    },
    SET_JETSON_ONLINE_STATUS(state, payload) {
      state.jetson_online_status = payload;
    },
    SET_JETSON_SHIPPING_STATUS(state, payload) {
      state.jetson_shipping_status = payload;
    },
    SET_CUSTOMER_STATUS(state, payload) {
      state.customer_status = payload;
    },
    SET_PRODUCTION_CAMERAS(state, payload) {
      state.production_cameras = payload;
    },
    SET_STAGING_RANCHES(state, payload) {
      state.staging_ranches = payload;
    },
    SET_SITE_OBJECTS(state, payload) {
      state.site_objects = payload;
    },
    SET_PRODUCTION_RANCHES(state, payload) {
      state.production_ranches = payload;
    },
    SET_THUMBNAILS(state, payload) {
      state.thumbnails = payload;
    },
    SET_COGNITO_USERS(state, payload) {
      state.cognito_users = payload;
    },
    SET_CUSTOMER_OBJECTS(state, payload) {
      state.customer_objects = payload;
    },
    SET_JETSON_OBJECTS(state, payload) {
      state.jetson_objects = payload;
    },
    SET_WIFI_OBJECTS(state, payload) {
      state.wifi_objects = payload;
    },
    SET_FOOTAGE(state, payload) {
      state.footage = payload;
    },
    SET_PROJECTS(state, payload) {
      state.projects = payload;
    },
    SET_SELECTED_PROJECT_CONTACTS(state, payload) {
      state.selected_project_contacts = payload;
    },
    SET_PROJECT_ID_NAMES(state, payload) {
      state.project_id_names = payload;
    },
    SET_HASURA_ADMIN_KEY(state, payload) {
      state.admin_key = payload;
    },
    SET_SELECTION_CAMERAS(state, payload) {
      state.selection_cameras = payload;
    },
    SET_ANIMAL_IMAGES(state, payload) {
      state.animal_images = payload.animal_images;
      state.animal_images_count = payload.animal_images_count;
    },
    SET_SUMMARY_MEDIA(state, payload) {
      state.summary_media = payload;
    },
    SET_SUMMARY_PGIE(state, payload) {
      state.summary_pgie = payload;
    },
    SET_SUMMARY_ACTIVITY(state, payload) {
      state.summary_activity = payload;
    },
    SET_SUMMARY_CALVING(state, payload) {
      state.summary_calving = payload;
    },
    SET_SUMMARY_ESTRUS(state, payload) {
      state.summary_estrus = payload;
    },
    SET_SUMMARY_SPECIES(state, payload) {
      state.summary_species = payload;
    },
    SET_SUMMARY_VPC(state, payload) {
      state.summary_vpc = payload;
    },
    SET_SUMMARY_VEHICLE(state, payload) {
      state.summary_vehicle = payload;
    },
    SET_SUMMARY_TAG(state, payload) {
      state.summary_tag = payload;
    },
    SET_HOME_PAGE_STATS(state, payload) {
      state.home_page_stats = payload;
    },
    SET_ANALYTICS_CALVING(state, payload) {
      state.analytics_calving = payload.analytics_calving;
      state.analytics_calving_count = payload.analytics_calving_count;
    },
    SET_ANALYTICS_ESTRUS(state, payload) {
      state.analytics_estrus = payload.analytics_estrus;
      state.analytics_estrus_count = payload.analytics_estrus_count;
    },
    SET_ANALYTICS_PREDATOR(state, payload) {
      state.analytics_predator = payload.analytics_predator;
      state.analytics_predator_count = payload.analytics_predator_count;
    },
    user(state, user) {
      // state.authorized = !!user && user.attributes && user.attributes.email_verified
      state.authorized = !!user && user.attributes;
      state.user = user;
    },
    NULL() {
      return null;
    },
  },

  actions: {
    /**
     *
     * @param {Object} params
     * @param {Array} params.date_range Date range
     * @param {Number} params.limit Limit
     * @param {Boolean} params.first_time Is first time?
     * @param {String} params.user_id user id
     * @param {String} params.camera_id camera id
     *
     * @param {Object} params.model_versions model versions
     * @param {Object} params.model_versions.sgie_contraction sgie_contraction
     *
     * @param {Object} params.filters
     * @param {Object} params.filters.PGIE PGIE classifications
     * @param {Object} params.filters.Species Species classifications
     * @param {Object} params.filters.SGIE SGIE classifications
     * @param {Object} params.filters.OCR OCR IDs
     * @param {Object} params.filters.Contraction Contraction classifications
     * @param {Object} params.filters.Contraction_Classification New contraction classifications
     * @param {Object} params.filters.Suckling Suckling classifications
     * @param {Object} params.filters.Activity Activity classifications
     * @param {Object} params.filters.Age Age classifications
     * @returns Promise
     */

    DDB_GET_IMAGES({ commit, state }, params) {
      let estrusFilterApplied = Object.keys(params.filters.estrus).length > 0;
      let query = estrusFilterApplied
        ? buildEstrusQuery(params.filters)
        : buildQuery(params.filters);

      let isFilterApplied = false;

      if (params.user_id) {
        const customerIdFilter = `customer_id: {_eq: "${params.user_id}"}, `;
        const whereIndex = query.indexOf("where: {") + "where: {".length;
        const newQuery =
          query.slice(0, whereIndex) +
          customerIdFilter +
          query.slice(whereIndex);
        query = newQuery;
      }

      if (params.camera_id) {
        const cameraIdFilter = `camera_id: {_eq: "${params.camera_id}"}, `;
        const whereIndex = query.indexOf("where: {") + "where: {".length;
        const newQuery =
          query.slice(0, whereIndex) + cameraIdFilter + query.slice(whereIndex);
        query = newQuery;
      }

      if (params.date_range && params.date_range.length > 0) {
        let timestampFilter;
        const whereIndex = query.indexOf("where: {") + "where: {".length;

        const hourStart = params.filters.Time.hour_start;
        const hourEnd = params.filters.Time.hour_end;

        if (params.date_range.length === 1) {
          let dateStart = hourStart
            ? moment(params.date_range[0]).format(
                `YYYY-MM-DDT${hourStart}:00:00`
              )
            : moment(params.date_range[0]).format("YYYY-MM-DDT00:00:00");
          let dateEnd = hourEnd
            ? moment(params.date_range[0]).format(`YYYY-MM-DDT${hourEnd}:00:00`)
            : moment(params.date_range[0]).format("YYYY-MM-DDT23:59:59");

          timestampFilter = `media_timestamp: {_gte: "${dateStart}", _lte: "${dateEnd}"}, `;
        } else if (params.date_range.length === 2) {
          let dateStart = moment(params.date_range[0]).format("YYYY-MM-DD");
          let dateEnd = moment(params.date_range[1]).format("YYYY-MM-DD");

          dateStart += hourStart ? `T${hourStart}:00:00` : "T00:00:00";
          dateEnd += hourEnd ? `T${hourEnd}:00:00` : "T23:59:59";

          timestampFilter = `media_timestamp: {_gte: "${dateStart}", _lte: "${dateEnd}"}, `;
        }

        query =
          query.slice(0, whereIndex) +
          timestampFilter +
          query.slice(whereIndex);

        //const pgiesWithParenIndex = query.lastIndexOf("pgies(");
        const pgiesCurlyIndex = query.lastIndexOf("pgies{");

        if (pgiesCurlyIndex !== -1) {
          let index = pgiesCurlyIndex + 5;
          query =
            query.substring(0, index) +
            `(where : { ${timestampFilter}}){` +
            query.substring(index + 1);
        } else {
          let lastWhereIndex = query.lastIndexOf("where :{");
          if (lastWhereIndex !== -1) {
            let insertIndex = lastWhereIndex + 8;
            query =
              query.substring(0, insertIndex) +
              timestampFilter.substring(0, timestampFilter.length - 1) +
              query.substring(insertIndex);
          }
        }
      }
      // console.log(query);
      return new Promise((resolve, reject) => {
        async function getImages() {
          if (
            sem_status.limit &&
            params.limit &&
            sem_status.limit !== params.limit
          ) {
            // Calculate new offset based on the total number of images fetched and new limit
            var totalFetchedImages =
              state.images.length > 0 ? state.images[0].length : 0;
            var pagesFetched = Math.floor(totalFetchedImages / params.limit);
            sem_status.offset = pagesFetched * params.limit;
            sem_status.limit = params.limit;
          } else if (!sem_status.limit && params.limit) {
            // Initialize limit and offset if not already set
            sem_status.limit = params.limit;
            sem_status.offset = 0;
          }

          if (sem_status.query !== query) {
            isFilterApplied = true;
          }
          sem_status.query = query;

          if (isFilterApplied) {
            sem_status.offset = 0;
          }
          var variables = {
            limit: sem_status.limit + 1,
            offset: sem_status.offset,
          };

          // console.log(query);
          const response = await axios.post(
            store.state.hasura_params.url,
            { query: query, variables: variables },
            { headers: store.state.hasura_params.headers }
          );
          // console.log(response.data)
          var images = estrusFilterApplied
            ? response.data.data.analytics_estrus
            : response.data.data.inference_media;

          var hasNextPage = images.length > sem_status.limit;

          if (hasNextPage) {
            images.pop(); // remove the extra item
            sem_status.offset += sem_status.limit; // update offset for next page
          }
          var image_list;
          console.log(images)
          if (estrusFilterApplied) {
            image_list = images.map((image) => {
              let user_id = image.customer ? image.customer.id : "";
              let camera_id = image.camera_id;
              let image_time = moment(image.media_timestamp);
              let bbox_list = [];
              if (
                image.bbox_x &&
                image.bbox_y &&
                image.bbox_w &&
                image.bbox_h
              ) {
                bbox_list.push({
                  x1: image.bbox_x,
                  y1: image.bbox_y,
                  w: image.bbox_w,
                  h: image.bbox_h,
                  tracking_id: image.tracking_id,
                  species: image.species_label,
                  speciesConf: image.species_conf,
                });
              }

              if (
                image.oo_bbox_x &&
                image.oo_bbox_y &&
                image.oo_bbox_w &&
                image.oo_bbox_h
              ) {
                bbox_list.push({
                  x1: image.oo_bbox_x,
                  y1: image.oo_bbox_y,
                  w: image.oo_bbox_w,
                  h: image.oo_bbox_h,
                  tracking_id: image.oo_tracking_id,
                });
              }

              if (bbox_list.length >= 2) {
                const minX = Math.min(...bbox_list.map((bbox) => bbox.x1));
                const minY = Math.min(...bbox_list.map((bbox) => bbox.y1));
                const maxX = Math.max(
                  ...bbox_list.map((bbox) => bbox.x1 + bbox.w)
                );
                const maxY = Math.max(
                  ...bbox_list.map((bbox) => bbox.y1 + bbox.h)
                );

                const encompassingWidth = maxX - minX;
                const encompassingHeight = maxY - minY;

                bbox_list.push({
                  x1: minX,
                  y1: minY,
                  w: encompassingWidth,
                  h: encompassingHeight,
                });
              }
              let classificationInfo =
                image.class_label !== undefined &&
                image.class_conf !== undefined &&
                image.class_label_2 !== undefined &&
                image.class_conf_2 !== undefined
                  ? [
                      {
                        label1: image.class_label,
                        conf1: image.class_conf,
                        label2: image.class_label_2,
                        conf2: image.class_conf_2,
                      },
                    ]
                  : undefined;
              return {
                ...image,
                estrusFilterApplied: true,
                user_id: user_id,
                camera_id: camera_id,
                image_time: image_time.format("YYYY-MM-DD HH:mm:ss"),
                image_path: image.s3_uri,
                hasura_header: store.state.hasura_params.headers,
                hasura_url: store.state.hasura_params.url,
                bbox_list: bbox_list,
                classificationInfo: classificationInfo,
              };
            });
          } else {
            image_list = images.map((image) => {
              let user_id = image.customer_id;
              let camera_id = image.camera_id;
              let image_time = moment(image.media_timestamp);
              let bbox_list = [];
              let body_parts_list = [];
              let year = String(image_time.year()).padStart(4, "0");
              let month = String(image_time.month() + 1).padStart(2, "0");
              let day = String(image_time.date()).padStart(2, "0");
              let full_date = image_time.format("YYYY-MM-DD_HH-mm-ss");
              let tags = [];

              let analytics_calving = image.analytics_calving;
              let analytics_predator = image.analytics_predator;
              let analytics_estrus = image.analytics_estrus;

              image.pgies.map((inference) => {
                bbox_list.push({
                  x1: inference.bbox.x,
                  y1: inference.bbox.y,
                  w:
                    inference.bbox.width !== undefined
                      ? inference.bbox.width
                      : inference.bbox.w,
                  h:
                    inference.bbox.height !== undefined
                      ? inference.bbox.height
                      : inference.bbox.h,
                  type:
                    inference.classifications &&
                    inference.classifications.species_label
                      ? inference.classifications.species_label
                      : inference.class_label,
                });

                if (inference.body_parts) {
                  const partsToCheck = [
                    "head",
                    "tail",
                    "foot_1",
                    "foot_2",
                    "foot_3",
                    "foot_4",
                    "joint_1",
                    "joint_2",
                    "joint_3",
                    "joint_4",
                    "tag_1",
                    "tag_2",
                  ];

                  partsToCheck.forEach((p) => {
                    const bboxKey = `${p}_bbox`;
                    const confKey = `${p}_conf`;
                    const labelKey = `${p}_label`;

                    if (inference.body_parts[bboxKey] !== null) {
                      const conf = inference.body_parts[confKey];
                      const label = inference.body_parts[labelKey];

                      if (label == "tag") {
                        let tag_id = inference.body_parts[`${p}_id_label`];
                        let tag_id_conf = inference.body_parts[`${p}_id_conf`];
                        let tag_color =
                          inference.body_parts[`${p}_colour_label`];

                        if (tag_id_conf && tag_id_conf >= 0.7) {
                          body_parts_list.push({
                            bbox: inference.body_parts[bboxKey],
                            conf,
                            label,
                            tag_id: tag_id,
                            tag_id_conf: tag_id_conf,
                            tag_color,
                            tracking_id: inference.tracking_id,
                          });
                          tags.push({
                            tag_label: tag_id,
                            tracking_id: inference.tracking_id,
                            tag_color: tag_color,
                            tag_id_conf: tag_id_conf,
                          });
                        }
                      } else {
                        body_parts_list.push({
                          bbox: inference.body_parts[bboxKey],
                          conf,
                          label,
                          tracking_id: inference.tracking_id,
                        });
                      }
                    }
                  });
                }
              });
              return {
                ...image,
                estrusFilterApplied: false,
                inference: true,
                analytics_calving: analytics_calving,
                analytics_predator: analytics_predator,
                analytics_estrus: analytics_estrus,
                user_id: user_id,
                camera_id: camera_id,
                image_time: image_time.format("YYYY-MM-DD HH:mm:ss"),
                image_path: `${user_id}/${camera_id}/${year}/${month}/${day}/${camera_id}_${full_date}`,
                body_parts_list: body_parts_list,
                tags: tags,
                hasura_header: store.state.hasura_params.headers,
                hasura_url: store.state.hasura_params.url,
                bbox_list: bbox_list,
              };
            });
          }

          if (response) {
            if (params.first_time) {
              image_list = [image_list, sem_status.offset, image_list.length];
              commit("SET_IMAGES", image_list);
              commit("SET_IMAGES_IMAGEPLAYER", image_list);
            } else {
              if (params.imagePlayer) {
                image_list = updateImageList(
                  state.imagesImagePlayer,
                  image_list,
                  sem_status.offset
                );
                commit("SET_IMAGES_IMAGEPLAYER", image_list);
              } else {
                image_list = updateImageList(
                  state.images,
                  image_list,
                  sem_status.offset
                );
                commit("SET_IMAGES", image_list);
                if (
                  state.imagesImagePlayer[0].length < state.images[0].length
                ) {
                  commit("SET_IMAGES_IMAGEPLAYER", image_list);
                }
              }
            }
            resolve(hasNextPage);
          } else {
            reject();
          }
        }

        getImages();
      });
    },
    DDB_GET_VIDEOS_VIDEOTABLE({ commit }, params) {
      return new Promise((resolve, reject) => {
        async function getVideos() {
          const response = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.inference_media_video,
              variables: {
                where: params.where,
                order_by: params.order_by,
                limit: params.limit,
                offset: params.offset,
              },
            },
            { headers: store.state.hasura_params.headers }
          );

          const response2 = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.inference_media_aggregate,
              variables: {
                where: params.where,
                order_by: params.order_by,
              },
            },
            { headers: store.state.hasura_params.headers }
          );

          //DEBUG
          //   const whereString = JSON.stringify(params.where).replace(/"([^"]+)":/g, '$1:');

          // console.log(whereString)

          if (response) {
            var videos = [];
            var videos_list = response.data.data.inference_media.map(
              (video) => {
                var user_id = video.customer_id;
                var camera_id = video.camera_id;
                var video_time = moment(video.media_timestamp);

                var year = String(video_time.year()).padStart(4, "0");
                var month = String(video_time.month() + 1).padStart(2, "0");
                var day = String(video_time.date()).padStart(2, "0");
                var full_date = video_time.format("YYYY-MM-DD_HH-mm-ss");

                return {
                  ...video,
                  video_time: video_time.format("YYYY-MM-DD HH:mm:ss"),
                  video_path: `${user_id}/${camera_id}/${year}/${month}/${day}/${camera_id}_${full_date}`,
                };
              }
            );

            videos.push(videos_list);
            videos.push(
              response2.data.data.inference_media_aggregate.aggregate.count
            );
            commit("SET_VIDEOS_VIDEOTABLE", videos);

            resolve();
          } else {
            reject();
          }
        }
        getVideos();
      });
    },
    DDB_GET_VIDEOS_VIDEOBOARD({ commit, state }, params) {
      let query = buildQuery(params.filters, "VIDEO");
      let isFilterApplied = false;

      if (params.user_id) {
        const customerIdFilter = `customer_id: {_eq: "${params.user_id}"}, `;
        const whereIndex = query.indexOf("where: {") + "where: {".length;
        const newQuery =
          query.slice(0, whereIndex) +
          customerIdFilter +
          query.slice(whereIndex);
        query = newQuery;
      }

      if (params.camera_id) {
        const cameraIdFilter = `camera_id: {_eq: "${params.camera_id}"}, `;
        const whereIndex = query.indexOf("where: {") + "where: {".length;
        const newQuery =
          query.slice(0, whereIndex) + cameraIdFilter + query.slice(whereIndex);
        query = newQuery;
      }

      if (params.date_range && params.date_range.length > 0) {
        let timestampFilter;
        const whereIndex = query.indexOf("where: {") + "where: {".length;

        const hourStart = params.filters.Time.hour_start;
        const hourEnd = params.filters.Time.hour_end;

        if (params.date_range.length === 1) {
          let dateStart = hourStart
            ? moment(params.date_range[0]).format(
                `YYYY-MM-DDT${hourStart}:00:00`
              )
            : moment(params.date_range[0]).format("YYYY-MM-DDT00:00:00");
          let dateEnd = hourEnd
            ? moment(params.date_range[0]).format(`YYYY-MM-DDT${hourEnd}:00:00`)
            : moment(params.date_range[0]).format("YYYY-MM-DDT23:59:59");

          timestampFilter = `media_timestamp: {_gte: "${dateStart}", _lte: "${dateEnd}"}, `;
        } else if (params.date_range.length === 2) {
          let dateStart = moment(params.date_range[0]).format("YYYY-MM-DD");
          let dateEnd = moment(params.date_range[1]).format("YYYY-MM-DD");

          dateStart += hourStart ? `T${hourStart}:00:00` : "T00:00:00";
          dateEnd += hourEnd ? `T${hourEnd}:00:00` : "T23:59:59";

          timestampFilter = `media_timestamp: {_gte: "${dateStart}", _lte: "${dateEnd}"}, `;
        }

        query =
          query.slice(0, whereIndex) +
          timestampFilter +
          query.slice(whereIndex);

        //const pgiesWithParenIndex = query.lastIndexOf("pgies(");
        const pgiesCurlyIndex = query.lastIndexOf("pgies{");

        if (pgiesCurlyIndex !== -1) {
          let index = pgiesCurlyIndex + 5;
          query =
            query.substring(0, index) +
            `(where : { ${timestampFilter}}){` +
            query.substring(index + 1);
        } else {
          let lastWhereIndex = query.lastIndexOf("where :{");
          if (lastWhereIndex !== -1) {
            let insertIndex = lastWhereIndex + 8;
            query =
              query.substring(0, insertIndex) +
              timestampFilter.substring(0, timestampFilter.length - 1) +
              query.substring(insertIndex);
          }
        }
      }

      return new Promise((resolve, reject) => {
        async function getVideos() {
          if (
            sem_status.videos_limit &&
            params.limit &&
            sem_status.videos_limit !== params.limit
          ) {
            // Calculate new offset based on the total number of images fetched and new limit
            var totalFetchedVideos =
              state.videosBoard.length > 0 ? state.videosBoard[0].length : 0;
            var pagesFetched = Math.floor(totalFetchedVideos / params.limit);
            sem_status.videos_offset = pagesFetched * params.limit;
            sem_status.videos_limit = params.limit;
          } else if (!sem_status.videos_limit && params.limit) {
            // Initialize limit and offset if not already set
            sem_status.videos_limit = params.limit;
            sem_status.videos_offset = 0;
          }

          if (sem_status.videos_query !== query) {
            isFilterApplied = true;
          }
          sem_status.videos_query = query;

          if (isFilterApplied) {
            sem_status.videos_offset = 0;
          }
          var variables = {
            limit: sem_status.videos_limit + 1,
            offset: sem_status.videos_offset,
          };

          //console.log(query);
          const response = await axios.post(
            store.state.hasura_params.url,
            { query: query, variables: variables },
            { headers: store.state.hasura_params.headers }
          );

          var videos = response.data.data.inference_media;

          var hasNextPage = videos.length > sem_status.videos_limit;

          if (hasNextPage) {
            videos.pop(); // remove the extra item
            sem_status.videos_offset += sem_status.videos_limit; // update offset for next page
          }

          var videos_list = videos.map((video) => {
            var user_id = video.customer_id;
            var camera_id = video.camera_id;
            var video_time = moment(video.media_timestamp);

            var year = String(video_time.year()).padStart(4, "0");
            var month = String(video_time.month() + 1).padStart(2, "0");
            var day = String(video_time.date()).padStart(2, "0");
            var full_date = video_time.format("YYYY-MM-DD_HH-mm-ss");

            return {
              ...video,
              video_time: video_time.format("YYYY-MM-DD HH:mm:ss"),
              video_path: `${user_id}/${camera_id}/${year}/${month}/${day}/${camera_id}_${full_date}`,
            };
          });
          if (response) {
            if (params.first_time) {
              videos_list = [
                videos_list,
                sem_status.videos_offset,
                videos_list.length,
              ];
              commit("SET_VIDEOS_VIDEOBOARD", videos_list);
              // commit("SET_IMAGES_IMAGEPLAYER", image_list);
            } else {
              videos_list = updateVideoList(
                state.videosBoard,
                videos_list,
                sem_status.videos_offset
              );
              commit("SET_VIDEOS_VIDEOBOARD", videos_list);
            }

            resolve(hasNextPage);
          } else {
            reject();
          }
        }
        getVideos();
      });
    },
    /**
     *
     * @param {Object} params
     * @param {Array} params.date_range Date range
     * @param {Number} params.limit Limit
     * @param {Boolean} params.first_time Is first time?
     * @param {String} params.user_id user id
     * @param {String} params.camera_id camera id
     *
     * @param {Object} params.model_versions model versions
     * @param {Object} params.model_versions.sgie_contraction sgie_contraction
     *
     * @param {Object} params.filters
     * @param {Object} params.filters.PGIE PGIE classifications
     * @param {Object} params.filters.Species Species classifications
     * @param {Object} params.filters.SGIE SGIE classifications
     * @param {Object} params.filters.OCR OCR IDs
     * @param {Object} params.filters.Contraction Contraction classifications
     * @param {Object} params.filters.Contraction_Classification New contraction classifications
     * @param {Object} params.filters.Suckling Suckling classifications
     * @param {Object} params.filters.Activity Activity classifications
     * @param {Object} params.filters.Age Age classifications
     * @returns Promise
     */
    // DDB_GET_VIDEOS_TIMESTREAM({ commit, state }, params) {
    //   var request_number = 1;

    //   if (sem_status.video_number) {
    //     request_number = sem_status.video_number + 1;
    //   }
    //   sem_status.video_number = request_number;

    //   return new Promise((resolve, reject) => {
    //     // process filters
    //     // date range
    //     var date_start = "ago(24h)";
    //     var date_end = "now()";
    //     if (params.date_range) {
    //       if (params.date_range.length == 1) {
    //         var date = moment(params.date_range[0]);
    //         date_start = `timestamp '${date.format("YYYY-MM-DD HH:mm:ss")}'`;
    //         date_end = `timestamp '${date
    //           .endOf("day")
    //           .format("YYYY-MM-DD HH:mm:ss")}'`;
    //       } else {
    //         var date1 = moment(params.date_range[0]);
    //         var date2 = moment(params.date_range[1]);
    //         date_start = `timestamp '${date1.format("YYYY-MM-DD HH:mm:ss")}'`;
    //         date_end = `timestamp '${date2
    //           .endOf("day")
    //           .format("YYYY-MM-DD HH:mm:ss")}'`;
    //       }
    //     }

    //     var video_condition = prepareCondition(params);

    //     // sort fields
    //     var sort_fields = [];
    //     if (params.sort_fields) {
    //       for (let i in params.sort_fields) {
    //         sort_fields.push(
    //           `${params.sort_fields[i].field} ${params.sort_fields[i].direction}`
    //         );
    //       }
    //     }

    //     if (sort_fields.length > 0) {
    //       sort_fields = "ORDER BY " + sort_fields.join(", ");
    //     } else {
    //       sort_fields = "";
    //     }

    //     // user id
    //     var table_name = "inference";
    //     var database_name = "production_inference_analytics";
    //     if (params.user_id) {
    //       table_name = params.user_id;
    //       database_name = "production_inference_customer";
    //     }

    //     var camera_condition = "";
    //     if (params.camera_id) {
    //       camera_condition = `AND camera_id = '${params.camera_id}'`;
    //     }

    //     var query = `
    //     WITH cte0 AS (
    //       SELECT *,
    //       rank() OVER (PARTITION BY user_id, camera_id, time ORDER BY uploaded_at DESC) AS row_num
    //       FROM "${database_name}"."${table_name}"
    //       WHERE
    //         source = 'video'
    //         AND time BETWEEN ${date_start} AND ${date_end} ${camera_condition}
    //     ), all_frames AS(
    //       SELECT
    //         user_id,
    //         camera_id,
    //         time,
    //         bbox_body,
    //         species,
    //         bbox_head,
    //         bbox_tag,
    //         bbox_tail,
    //         PGIE,
    //         CAST(frame AS int) AS frame,
    //         fps,
    //         from_milliseconds(to_milliseconds(time)+(CAST(frame AS int)/CAST(fps AS int))*1000) AS frame_time
    //       FROM cte0
    //       WHERE row_num = 1
    //       ${sort_fields}
    //     ), filtered_frames AS (
    //       SELECT
    //         *,
    //         row_number() OVER(ORDER BY camera_id, time, frame_time) AS RN,
    //         LAG(frame_time) OVER (ORDER BY camera_id, time, frame_time) AS previous_time,
    //         LAG(camera_id) OVER (ORDER BY camera_id, time, frame_time) AS previous_camera
    //       FROM all_frames
    //       WHERE ${video_condition}
    //     ), groups AS (
    //       SELECT
    //         *,
    //       SUM(
    //         CASE
    //           WHEN (date_diff('minute', previous_time, frame_time) > 5 OR camera_id != previous_camera) THEN 1
    //           ELSE 0
    //           END
    //       ) OVER (ORDER BY RN) AS IslandId
    //       FROM filtered_frames
    //     ), ordered_groups AS (
    //       SELECT
    //         *,
    //         rank() OVER (PARTITION BY IslandId ORDER BY frame ASC) AS group_rank
    //       FROM groups
    //     )
    //     SELECT
    //       MAX(user_id),
    //       MAX(camera_id),
    //       MAX(time),
    //       array_agg(bbox_body) AS "PGIE_bbox_list",
    //       array_agg(species) AS "species_list",
    //       array_agg(bbox_head) AS "SGIE_head_list",
    //       array_agg(bbox_tag) AS "SGIE_tag_list",
    //       array_agg(bbox_tail) AS "SGIE_tail_list",
    //       array_agg(PGIE) AS "PGIE_list",
    //       MAX(frame),
    //       MAX(fps),
    //       IslandId
    //     FROM ordered_groups
    //     WHERE group_rank = 1
    //     GROUP BY IslandId
    //     `;

    //     var query_params = {
    //       QueryString: query,
    //       MaxRows: params.limit,
    //     };

    //     var query_count = `
    //     WITH cte0 AS (
    //       SELECT *,
    //       rank() OVER (PARTITION BY user_id, camera_id, time ORDER BY uploaded_at DESC) AS row_num
    //       FROM "${database_name}"."${table_name}"
    //       WHERE
    //         source = 'video'
    //         AND time BETWEEN ${date_start} AND ${date_end} ${camera_condition}
    //     ), all_frames AS(
    //       SELECT
    //         user_id,
    //         camera_id,
    //         time,
    //         bbox_body,
    //         species,
    //         bbox_head,
    //         bbox_tag,
    //         bbox_tail,
    //         PGIE,
    //         CAST(frame AS int) AS frame,
    //         fps,
    //         from_milliseconds(to_milliseconds(time)+(CAST(frame AS int)/CAST(fps AS int))*1000) AS frame_time
    //       FROM cte0
    //       WHERE row_num = 1
    //       ${sort_fields}
    //     ), filtered_frames AS (
    //       SELECT
    //         *,
    //         row_number() OVER(ORDER BY camera_id, time, frame_time) AS RN,
    //         LAG(frame_time) OVER (ORDER BY camera_id, time, frame_time) AS previous_time,
    //         LAG(camera_id) OVER (ORDER BY camera_id, time, frame_time) AS previous_camera
    //       FROM all_frames
    //       WHERE ${video_condition}
    //     ), groups AS (
    //       SELECT
    //         *,
    //       SUM(
    //         CASE
    //           WHEN (date_diff('minute', previous_time, frame_time) > 5 OR camera_id != previous_camera) THEN 1
    //           ELSE 0
    //           END
    //       ) OVER (ORDER BY RN) AS IslandId
    //       FROM filtered_frames
    //     ), ordered_groups AS (
    //       SELECT
    //         *,
    //         rank() OVER (PARTITION BY IslandId ORDER BY frame ASC) AS group_rank
    //       FROM groups
    //     ), result AS (
    //       SELECT
    //         user_id,
    //         camera_id,
    //         time,
    //         array_agg(bbox_body) AS "PGIE_bbox_list",
    //         array_agg(species) AS "species_list",
    //         array_agg(bbox_head) AS "SGIE_head_list",
    //         array_agg(bbox_tag) AS "SGIE_tag_list",
    //         array_agg(bbox_tail) AS "SGIE_tail_list",
    //         array_agg(PGIE) AS "PGIE_list",
    //         frame,
    //         fps
    //       FROM ordered_groups
    //       WHERE group_rank = 1
    //       GROUP BY user_id, camera_id, time, frame, fps
    //     )
    //     SELECT count(*)
    //     FROM result
    //     `;

    //     var query_count_params = {
    //       QueryString: query_count,
    //     };

    //     if (state.videos_timestream[1] && !params.first_time) {
    //       query_params.NextToken = state.videos_timestream[1];
    //     }

    //     initializeTimestreamQueryClient()
    //       .then((client) => {
    //         timestreamQueryClient = client;
    //         (async () => {
    //           try {
    //             var command = new QueryCommand(query_params);
    //             var video_data = await timestreamQueryClient.send(command);
    //             // var zero_count = 0;
    //             while (video_data.NextToken && video_data.Rows.length <= 0) {
    //               // zero_count ++;
    //               // if(zero_count >= 2){
    //               //   break;
    //               // }
    //               query_params.NextToken = video_data.NextToken;
    //               command = new QueryCommand(query_params);
    //               video_data = await timestreamQueryClient.send(command);
    //             }

    //             // processing data
    //             var video_list = video_data.Rows.map((e) => {
    //               // console.log("=====Timestream Data=====");
    //               // console.log(e.Data);
    //               var user_id = e.Data[0].ScalarValue;
    //               var camera_id = e.Data[1].ScalarValue;
    //               var video_time = moment(e.Data[2].ScalarValue);
    //               var frame = e.Data[9].ScalarValue;
    //               var fps = e.Data[10].ScalarValue;
    //               var bbox_list = [];

    //               // pgie
    //               var pgie_bbox_list = [];
    //               if (!e.Data[3].NullValue) {
    //                 pgie_bbox_list = e.Data[3].ArrayValue.map((a) => {
    //                   if (a.ScalarValue) {
    //                     return {
    //                       ...JSON.parse(a.ScalarValue.replaceAll("'", '"')),
    //                       type: "",
    //                     };
    //                   } else {
    //                     return null;
    //                   }
    //                 });
    //               }

    //               if (!e.Data[8].NullValue) {
    //                 e.Data[8].ArrayValue.map((a) => {
    //                   return a.ScalarValue;
    //                 }).forEach((b, index) => {
    //                   if (pgie_bbox_list[index] && b) {
    //                     pgie_bbox_list[index].type = b;
    //                   }
    //                 });
    //               }

    //               if (!e.Data[4].NullValue) {
    //                 e.Data[4].ArrayValue.map((a) => {
    //                   return a.ScalarValue;
    //                 }).forEach((b, index) => {
    //                   if (pgie_bbox_list[index] && b) {
    //                     pgie_bbox_list[index].type = b;
    //                   }
    //                 });
    //               }

    //               bbox_list = [
    //                 ...bbox_list,
    //                 ...pgie_bbox_list.filter((a) => !!a),
    //               ];

    //               // head
    //               if (
    //                 !e.Data[5].NullValue &&
    //                 (params.filters.SGIE.head ||
    //                   params.filters.SGIE_Conf.head ||
    //                   params.filters.SGIE_Ratio.head)
    //               ) {
    //                 bbox_list = [
    //                   ...bbox_list,
    //                   ...e.Data[5].ArrayValue.map((a) => {
    //                     if (a.ScalarValue) {
    //                       return {
    //                         ...JSON.parse(a.ScalarValue.replaceAll("'", '"')),
    //                         type: "head",
    //                         label: "head",
    //                       };
    //                     } else {
    //                       return null;
    //                     }
    //                   }).filter((b) => !!b),
    //                 ];
    //               }

    //               // tag
    //               if (
    //                 !e.Data[6].NullValue &&
    //                 (params.filters.SGIE.tag ||
    //                   params.filters.SGIE_Conf.tag ||
    //                   params.filters.SGIE_Ratio.tag ||
    //                   params.filters.OCR.exist ||
    //                   params.filters.OCR.non_exist)
    //               ) {
    //                 bbox_list = [
    //                   ...bbox_list,
    //                   ...e.Data[6].ArrayValue.map((a) => {
    //                     if (a.ScalarValue) {
    //                       return {
    //                         ...JSON.parse(a.ScalarValue.replaceAll("'", '"')),
    //                         type: "tag",
    //                         label: "tag",
    //                       };
    //                     } else {
    //                       return null;
    //                     }
    //                   }).filter((b) => !!b),
    //                 ];
    //               }

    //               // tail
    //               if (
    //                 !e.Data[7].NullValue &&
    //                 (params.filters.SGIE.tail ||
    //                   Object.keys(
    //                     params.filters.Contraction_Classification
    //                   ).some(
    //                     (e) =>
    //                       params.filters.Contraction_Classification[e] ||
    //                       params.filters.SGIE_Conf.tail ||
    //                       params.filters.SGIE_Ratio.tail
    //                   ))
    //               ) {
    //                 bbox_list = [
    //                   ...bbox_list,
    //                   ...e.Data[7].ArrayValue.map((a) => {
    //                     if (a.ScalarValue) {
    //                       return {
    //                         ...JSON.parse(a.ScalarValue.replaceAll("'", '"')),
    //                         type: "tail",
    //                         label: "tail",
    //                       };
    //                     } else {
    //                       return null;
    //                     }
    //                   }).filter((b) => !!b),
    //                 ];
    //               }

    //               // // foot
    //               // for(let i = 8; i<=11; i++){
    //               //   if(!e.Data[i].NullValue){
    //               //     bbox_list = [
    //               //       ...bbox_list,
    //               //       e.Data[i].ArrayValue.map(a=>{
    //               //         return {
    //               //           ...JSON.parse(a.ScalarValue.replaceAll("'", "\"")),
    //               //           type: "foot"
    //               //         };
    //               //       })
    //               //     ];
    //               //   }
    //               // }

    //               // // joint
    //               // for(let i = 12; i<=15; i++){
    //               //   if(!e.Data[i].NullValue){
    //               //     bbox_list = [
    //               //       ...bbox_list,
    //               //       e.Data[i].ArrayValue.map(a=>{
    //               //         return {
    //               //           ...JSON.parse(a.ScalarValue.replaceAll("'", "\"")),
    //               //           type: "joint"
    //               //         };
    //               //       })
    //               //     ];
    //               //   }
    //               // }

    //               var year = String(video_time.year()).padStart(4, "0");
    //               var month = String(video_time.month() + 1).padStart(2, "0");
    //               var day = String(video_time.date()).padStart(2, "0");
    //               var full_date = video_time.format("YYYY-MM-DD_HH-mm-ss");

    //               return {
    //                 user_id: user_id,
    //                 camera_id: camera_id,
    //                 video_time: video_time.format("YYYY-MM-DD HH:mm:ss"),
    //                 video_path: `${user_id}/${camera_id}/${year}/${month}/${day}/${camera_id}_${full_date}`,
    //                 video_frame: frame,
    //                 video_fps: fps,
    //                 bbox_list: bbox_list,
    //               };
    //             });
    //             if (state.videos_timestream[0] && !params.first_time) {
    //               video_list = [state.videos_timestream[0].concat(video_list)];
    //             } else {
    //               video_list = [video_list];
    //             }
    //             if (video_data.NextToken) {
    //               video_list.push(video_data.NextToken);
    //             } else {
    //               video_list.push(null);
    //             }
    //             if (state.videos_timestream[2] && !params.first_time) {
    //               video_list.push(state.videos_timestream[2]);
    //             } else {
    //               var count_command = new QueryCommand(query_count_params);
    //               var video_count_data = await timestreamQueryClient.send(
    //                 count_command
    //               );
    //               while (
    //                 video_count_data.NextToken &&
    //                 video_count_data.Rows.length <= 0
    //               ) {
    //                 query_count_params.NextToken = video_count_data.NextToken;
    //                 command = new QueryCommand(query_count_params);
    //                 video_count_data = await timestreamQueryClient.send(
    //                   command
    //                 );
    //               }
    //               var video_count = Number(
    //                 video_count_data.Rows[0]?.Data[0]?.ScalarValue
    //               );
    //               video_list.push(video_count);
    //             }

    //             if (request_number >= sem_status.video_number) {
    //               commit("SET_VIDEOS_TIMESTREAM", video_list);
    //               resolve(video_data.NextToken);
    //             } else {
    //               reject();
    //             }
    //           } catch (err) {
    //             console.log(err);
    //             if (request_number >= sem_status.video_number) {
    //               commit("SET_VIDEOS_TIMESTREAM", []);
    //               resolve(null);
    //             } else {
    //               reject();
    //             }
    //           }
    //         })();
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   });
    // },
    /**
     *
     * @param {Object} params
     * @param {String} params.search_str
     * @param {String} params.camera
     * @param {String} params.type
     * @param {String} params.customer
     * @param {String} params.level
     * @param {String} params.date
     * @returns
     */
    DDB_GET_NOTIFICATIONS({ commit }, params) {
      return new Promise((resolve) => {
        var _this = this;
        async function get_events(params) {
          _this.state.event_params = params;

          let order_by = {};
          if (params.sort) {
            order_by[params.sort] = params.sort_direction;
          }
          let where = {};

          if (params.search && params.search.length) {
            where.description = { _iregex: params.search };
          }
          if (params.filter.camera && params.filter.camera != "All") {
            where.camera_id = { _eq: params.filter.camera };
          }
          if (params.filter.customer_id != "All Customers") {
            where.customer_id = { _eq: params.filter.customer_id };
          }
          if (params.filter.level) {
            where.level = { _eq: params.filter.level };
          }
          if (params.filter.email_filter != "All") {
            where.sent_datetime_sendgrid = {
              _is_null: !params.filter.email_filter,
            };
          }
          if (params.filter.txt_filter != "All") {
            where.sent_datetime_twilio = {
              _is_null: !params.filter.txt_filter,
            };
          }
          if (params.filter.feedback_received_filter != "All") {
            where.feedback_received = {
              _eq: params.filter.feedback_received_filter,
            };
          }
          if (params.filter.feedback_positive_filter != "All") {
            where.feedback_positive = {
              _eq: params.filter.feedback_positive_filter,
            };
          }
          if (params.date) {
            where.datetime = {
              _gte: moment(params.date, "YYYY-MM-DDTHH").format(
                "YYYY-MM-DDTHH:ss:mm"
              ),
              _lt: moment(params.date, "YYYY-MM-DDTHH")
                .add(1, "d")
                .format("YYYY-MM-DDTHH:ss:mm"),
            };
          }
          let notifications = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.notification,
                variables: {
                  where: where,
                  order_by: order_by,
                  limit: params.limit,
                  offset: params.offset,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          let notification_count = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.notification_aggregate,
                variables: {
                  where: where,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.notification_aggregate.aggregate.count;

          commit("SET_NOTIFICATIONS", {
            notifications: notifications.data.notification,
            notification_count: notification_count,
          });
          resolve();
        }
        get_events(params);
      });
    },

    DDB_GET_ANALYTICS_CALVING({ commit }, params) {
      return new Promise((resolve) => {
        var _this = this;
        async function get_events(params) {
          _this.state.event_params = params;

          let order_by = {};
          if (params.sort) {
            order_by[params.sort] = params.sort_direction;
          }
          let where = {};

          if (params.filter.camera && params.filter.camera != "All") {
            where.camera_id = { _eq: params.filter.camera };
          }
          if (params.filter.customer_id != "All Customers") {
            where.customer_id = { _eq: params.filter.customer_id };
          }
          if (params.filter.contraction_1_filter != "All") {
            where.contraction_label = {
              _eq: params.filter.contraction_1_filter,
            };
          }
          if (params.filter.contraction_2_filter != "All") {
            where.contraction_label_2 = {
              _eq: params.filter.contraction_2_filter,
            };
          }
          if (params.filter.valid_event_filter != "All") {
            where.valid_calving_event = {
              _eq: params.filter.valid_event_filter,
            };
          }

          if (params.date) {
            where.notification_timestamp = {
              _gte: moment(params.date, "YYYY-MM-DDTHH").format(
                "YYYY-MM-DDTHH:ss:mm"
              ),
              _lt: moment(params.date, "YYYY-MM-DDTHH")
                .add(1, "d")
                .format("YYYY-MM-DDTHH:ss:mm"),
            };
          }

          let analytics_calving = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.analytics_calving,
                variables: {
                  where: where,
                  order_by: order_by,
                  limit: params.limit,
                  offset: params.offset,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          let analytics_calving_count = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.analytics_calving_aggregate,
                variables: {
                  where: where,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.analytics_calving_aggregate.aggregate.count;

          commit("SET_ANALYTICS_CALVING", {
            analytics_calving: analytics_calving.data.analytics_calving,
            analytics_calving_count: analytics_calving_count,
          });
          resolve();
        }
        get_events(params);
      });
    },

    DDB_GET_ANALYTICS_ESTRUS({ commit }, params) {
      return new Promise((resolve) => {
        var _this = this;
        async function get_events(params) {
          _this.state.event_params = params;

          let order_by = {};
          if (params.sort) {
            order_by[params.sort] = params.sort_direction;
          }
          let where = {};

          if (params.filter.camera && params.filter.camera != "All") {
            where.camera_id = { _eq: params.filter.camera };
          }
          if (params.filter.customer_id != "All Customers") {
            where.customer_id = { _eq: params.filter.customer_id };
          }
          if (params.filter.gpt_check) {
            where.gpt_check = { _eq: true };
          }
          if (params.filter.label != "All") {
            where.class_label = { _eq: params.filter.label };
          }

          if (params.date) {
            where.notification_timestamp = {
              _gte: moment(params.date, "YYYY-MM-DDTHH").format(
                "YYYY-MM-DDTHH:ss:mm"
              ),
              _lt: moment(params.date, "YYYY-MM-DDTHH")
                .add(1, "d")
                .format("YYYY-MM-DDTHH:ss:mm"),
            };
          }

          let analytics_estrus = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.analytics_estrus,
                variables: {
                  where: where,
                  order_by: order_by,
                  limit: params.limit,
                  offset: params.offset,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          let analytics_estrus_count = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.analytics_estrus_aggregate,
                variables: {
                  where: where,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.analytics_estrus_aggregate.aggregate.count;

          commit("SET_ANALYTICS_ESTRUS", {
            analytics_estrus: analytics_estrus.data.analytics_estrus,
            analytics_estrus_count: analytics_estrus_count,
          });
          resolve();
        }
        get_events(params);
      });
    },
    DDB_GET_ANALYTICS_PREDATOR({ commit }, params) {
      return new Promise((resolve) => {
        var _this = this;
        async function get_events(params) {
          _this.state.event_params = params;

          let order_by = {};
          if (params.sort) {
            order_by[params.sort] = params.sort_direction;
          }
          let where = {};

          if (params.filter.camera && params.filter.camera != "All") {
            where.camera_id = { _eq: params.filter.camera };
          }
          if (params.filter.customer_id != "All Customers") {
            where.customer_id = { _eq: params.filter.customer_id };
          }

          if (params.date) {
            where.notification_timestamp = {
              _gte: moment(params.date, "YYYY-MM-DDTHH").format(
                "YYYY-MM-DDTHH:ss:mm"
              ),
              _lt: moment(params.date, "YYYY-MM-DDTHH")
                .add(1, "d")
                .format("YYYY-MM-DDTHH:ss:mm"),
            };
          }

          let analytics_predator = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.analytics_predator,
                variables: {
                  where: where,
                  order_by: order_by,
                  limit: params.limit,
                  offset: params.offset,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          let analytics_predator_count = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.analytics_predator_aggregate,
                variables: {
                  where: where,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.analytics_predator_aggregate.aggregate.count;

          commit("SET_ANALYTICS_PREDATOR", {
            analytics_predator: analytics_predator.data.analytics_predator,
            analytics_predator_count: analytics_predator_count,
          });
          resolve();
        }
        get_events(params);
      });
    },

    DDB_GET_SELECTION_CAMERAS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_selection_cameras(params) {
          let where = {};
          if (params.customer_id != "All Customers") {
            where.customer_id = { _eq: params.customer_id };
          }
          if (params.search && params.search != "All Cameras") {
            //where.name = { _iregex: params.search };
            where._or = [
              { name: { _iregex: params.search } },
              { id: { _iregex: params.search } },
            ];
          }
          //  console.log(where);
          let cameras = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.camera,
                variables: {
                  where: where,
                  order_by: { name: "asc" },
                  limit: 12,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          commit("SET_SELECTION_CAMERAS", cameras.data.camera);
          resolve();
        }
        get_selection_cameras(params);
      });
    },
    DDB_GET_NOTIFICATION_LEVELS({ commit }) {
      return new Promise((resolve) => {
        API.graphql({
          query: queries.getNotificationLevels,
        }).then((res) => {
          var list = res.data.__type.enumValues.map((e) => e.name);
          commit("SET_NOTIFICATION_LEVELS", list);
          resolve();
        });
      });
    },

    DDB_GET_SELECTED_NOTES({ commit }, params) {
      return new Promise((resolve) => {
        async function get_notes() {
          let where = {};

          where[params.type + "_id"] = { _eq: params.id };

          let notes = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.admin_note,
              variables: {
                where: where,
              },
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SELECTED_NOTES", notes.data.data.admin_note);
          resolve();
        }
        get_notes();
      });
    },
    DDB_GET_FEEDBACKS({ commit }) {
      return new Promise((resolve) => {
        async function get_camera() {
          var feedbacks;
          feedbacks = await API.graphql({
            query: queries.listFeedbacks,
            variables: {},
          });
          commit("SET_FEEDBACKS", feedbacks.data.listFeedbacks.items);
          resolve();
        }
        get_camera();
      });
    },
    DDB_GET_NOTES({ commit }) {
      return new Promise((resolve) => {
        async function get_camera() {
          var notes;
          notes = await API.graphql({
            query: queries.listNotes,
            variables: {},
          });
          commit("SET_NOTES", notes.data.listNotes.items);
          resolve();
        }
        get_camera();
      });
    },
    DDB_GET_CAMERA({ commit }, id) {
      return new Promise((resolve) => {
        async function get_camera() {
          var camera;
          camera = await API.graphql({
            query: queries.getCamera,
            variables: { id: id },
          });
          commit("SET_SELECTED_CAMERA", camera.data.getCamera);
          resolve();
        }
        get_camera();
      });
    },
    DDB_GET_DELETE_PROJECTS_CONTACTS({ commit }, id) {
      commit("NULL");
      return new Promise((resolve) => {
        async function delete_projects_contacts() {
          var contacts_correspondence;
          contacts_correspondence = await API.graphql({
            query: queries.listProjectContactsByProjectID,
            variables: { project_id: id },
          });
          for (const i in contacts_correspondence.data.listContactsByProjectID
            .items) {
            await API.graphql({
              query: mutations.deleteProjectContact,
              variables: {
                input: {
                  id: contacts_correspondence.data.listContactsByProjectID
                    .items[i].id,
                },
              },
            });
          }
          resolve();
        }
        delete_projects_contacts();
      });
    },
    DDB_GET_DELETE_PROJECTS_CORR({ commit }, id) {
      commit("NULL");
      return new Promise((resolve) => {
        async function delete_projects_correspondence() {
          var correspondence_projects;
          correspondence_projects = await API.graphql({
            query: queries.listProjectsCorrByProjectID,
            variables: { project_id: id },
          });
          for (const i in correspondence_projects.data
            .listCorrespondenceByProjectID.items) {
            await API.graphql({
              query: mutations.deleteProjectContact,
              variables: {
                input: {
                  id: correspondence_projects.data.listCorrespondenceByProjectID
                    .items[i].id,
                },
              },
            });
          }
          resolve();
        }
        delete_projects_correspondence();
      });
    },

    DDB_GET_DELETE_CONTACTS_CORR({ commit }, id) {
      commit("NULL");
      return new Promise((resolve) => {
        async function delete_contacts_correspondence() {
          var contacts_correspondence;
          contacts_correspondence = await API.graphql({
            query: queries.listContactsCorrByContactID,
            variables: { contact_id: id },
          });
          for (const i in contacts_correspondence.data
            .listCorrespondenceByContactID.items) {
            await API.graphql({
              query: mutations.deleteCorrespondenceCommunication,
              variables: {
                input: {
                  id: contacts_correspondence.data.listCorrespondenceByContactID
                    .items[i].id,
                },
              },
            });
          }
          resolve();
        }
        delete_contacts_correspondence();
      });
    },
    DDB_GET_DELETE_CONTACTS_PROJECTS({ commit }, id) {
      commit("NULL");
      return new Promise((resolve) => {
        async function delete_correspondence_projects() {
          var correspondence_projects;
          correspondence_projects = await API.graphql({
            query: queries.listProjectsContactByContactID,
            variables: { contact_id: id },
          });
          for (const i in correspondence_projects.data.listProjectsByContactID
            .items) {
            await API.graphql({
              query: mutations.deleteProjectContact,
              variables: {
                input: {
                  id: correspondence_projects.data.listProjectsByContactID
                    .items[i].id,
                },
              },
            });
          }
          resolve();
        }
        delete_correspondence_projects();
      });
    },

    DDB_DELETE_CORR_CONTACTS({ commit }, id) {
      commit("NULL");
      return new Promise((resolve) => {
        async function delete_correspondence_contacts() {
          var correspondence_contacts;
          correspondence_contacts = await API.graphql({
            query: queries.listContactsCorrByCorrespondenceID,
            variables: { correspondence_id: id },
          });

          for (const i in correspondence_contacts.data
            .listContactByCorrespondenceID.items) {
            await API.graphql({
              query: mutations.deleteCorrespondenceCommunication,
              variables: {
                input: {
                  id: correspondence_contacts.data.listContactByCorrespondenceID
                    .items[i].id,
                },
              },
            });
          }
          resolve();
        }
        delete_correspondence_contacts();
      });
    },
    DDB_DELETE_CORR_PROJECTS({ commit }, id) {
      commit("NULL");
      return new Promise((resolve) => {
        async function delete_correspondence_projects() {
          var correspondence_projects;
          correspondence_projects = await API.graphql({
            query: queries.listProjectsCorrByCorrespondenceID,
            variables: { correspondence_id: id },
          });
          for (const i in correspondence_projects.data
            .listProjectsByCorrespondenceID.items) {
            await API.graphql({
              query: mutations.deleteProjectCorrespondence,
              variables: {
                input: {
                  id: correspondence_projects.data
                    .listProjectsByCorrespondenceID.items[i].id,
                },
              },
            });
          }
          resolve();
        }
        delete_correspondence_projects();
      });
    },

    DDB_GET_LAST_FOOTAGE_BY_CAMERA({ commit }, params) {
      return new Promise((resolve) => {
        params.limit = 99999;
        if (!params.filter) {
          params.filter = {};
        }
        //params.filter.camera_footage_thumbnail_url = { contains: "latest" }

        async function get_last_footage() {
          var last_footage = await API.graphql({
            query: queries.listLastFootageDateByCamera,
            variables: params,
          });

          commit(
            "SET_LAST_FOOTAGE_UPLOAD_CAMERAS",
            last_footage.data.listCameras.items
          );
          resolve();
        }
        get_last_footage();
      });
    },
    /**
     *
     * @param {Object} params
     * @param {Number} params.limit The footage per page
     * @param {Object} params.filter The filter parameter
     * @param {String} params.nextToken Next token
     * @param {Boolean} params.first_time Is first time?
     * @returns
     */
    DDB_GET_LAST_FOOTAGE({ commit, state }, params) {
      if (sem_status.last_footage) {
        return sem_status.last_footage;
      }
      sem_status.last_footage = new Promise((resolve) => {
        if (!params.filter) {
          params.filter = {};
        }
        var first_time = params.first_time;
        delete params.first_time;
        var footage_objects = [];

        async function get_last_footage() {
          var last_footage = await API.graphql({
            query: queries.searchLastFootageByCamera,
            variables: params,
          });
          footage_objects = [
            last_footage.data.searchCameras.items,
            last_footage.data.searchCameras.nextToken,
          ];
          if (state.last_footage_upload_cameras[0] && !first_time) {
            footage_objects[0] = state.last_footage_upload_cameras[0].concat(
              footage_objects[0]
            );
          }
          commit("SET_LAST_FOOTAGE_UPLOAD_CAMERAS", footage_objects);
          delete sem_status.last_footage;
          resolve();
        }
        get_last_footage();
      });

      return sem_status.last_footage;
    },
    DDB_GET_CONTACT_ID_NAMES({ commit }) {
      return new Promise((resolve) => {
        async function get_contact_names() {
          var contact_names;
          contact_names = await API.graphql({
            query: queries.listContactNames,
            variables: { limit: 500 },
          });

          commit("SET_CONTACT_NAMES", contact_names.data.listContacts.items);
          resolve();
        }
        get_contact_names();
      });
    },
    DDB_GET_CONTACTS_BY_CORRESPONDENCE({ commit }, id) {
      return new Promise((resolve) => {
        async function get_correspondence_contacts() {
          var correspondence_contacts;
          correspondence_contacts = await API.graphql({
            query: queries.listContactByCorrespondenceID,
            variables: { correspondence_id: id[0] },
          });
          var contacts = [];
          for (const i in correspondence_contacts.data
            .listContactByCorrespondenceID.items) {
            contacts.push(
              correspondence_contacts.data.listContactByCorrespondenceID.items[
                i
              ].contact
            );
          }
          if (!id[1]) {
            var filtered = contacts.filter(function (el) {
              return el != null;
            });
            commit("SET_CONTACTS", filtered);
          } else {
            commit("SET_SELECTED_CONTACTS", contacts);
          }
          resolve();
        }
        get_correspondence_contacts();
      });
    },
    DDB_GET_PROJECT_BY_CORRESPONDENCE({ commit }, id) {
      return new Promise((resolve) => {
        async function get_contacts_correspondence() {
          var project_correspondence;

          project_correspondence = await API.graphql({
            query: queries.listProjectsByCorrespondenceID,
            variables: { correspondence_id: id },
          });
          var projects = [[], []];
          for (const i in project_correspondence.data
            .listProjectsByCorrespondenceID.items) {
            projects[0].push(
              project_correspondence.data.listProjectsByCorrespondenceID.items[
                i
              ].project
            );
            projects[1].push(
              project_correspondence.data.listProjectsByCorrespondenceID.items[
                i
              ].id
            );
          }
          commit("SET_SELECTED_PROJECTS", projects);
          resolve();
        }
        get_contacts_correspondence();
      });
    },
    DDB_GET_PROJECT_BY_CORRESPONDENCE2({ commit }, id) {
      return new Promise((resolve) => {
        async function get_contacts_correspondence() {
          var project_correspondence;

          project_correspondence = await API.graphql({
            query: queries.listProjectsByCorrespondenceID2,
            variables: { correspondence_id: id },
          });
          var projects = [];
          for (const i in project_correspondence.data
            .listProjectsByCorrespondenceID.items) {
            projects.push(
              project_correspondence.data.listProjectsByCorrespondenceID.items[
                i
              ].project
            );
          }
          commit("SET_PROJECTS", projects);
          resolve();
        }
        get_contacts_correspondence();
      });
    },
    DDB_GET_PROJECTS_BY_CONTACT({ commit }, id) {
      return new Promise((resolve) => {
        async function get_projects_by_contact() {
          var project_customer;
          project_customer = await API.graphql({
            query: queries.listProjectsByContactID,
            variables: { contact_id: id },
          });
          var projects = [[], []];
          for (const i in project_customer.data.listProjectsByContactID.items) {
            projects[0].push(
              project_customer.data.listProjectsByContactID.items[i].project
            );
            projects[1].push(
              project_customer.data.listProjectsByContactID.items[i].id
            );
          }

          commit("SET_SELECTED_PROJECTS", projects);

          resolve();
        }
        get_projects_by_contact();
      });
    },
    DDB_GET_PROJECTS_BY_CONTACT2({ commit }, id) {
      return new Promise((resolve) => {
        async function get_projects_by_contact() {
          var project_customer;
          project_customer = await API.graphql({
            query: queries.listProjectsByContactID2,
            variables: { contact_id: id },
          });
          var projects = [];
          for (const i in project_customer.data.listProjectsByContactID.items) {
            projects.push(
              project_customer.data.listProjectsByContactID.items[i].project
            );
          }

          commit("SET_PROJECTS", projects);

          resolve();
        }
        get_projects_by_contact();
      });
    },
    DDB_GET_ALL_CORRESPONDENCES({ commit }, params) {
      return new Promise((resolve) => {
        params.limit = 2000;
        async function get_correspondence() {
          var correspondences;

          correspondences = await API.graphql({
            query: queries.listCorrespondences,
            variables: params,
          });
          commit(
            "SET_ALL_CORRESPONDENCES",
            correspondences.data.listCorrespondences.items
          );
          resolve();
        }
        get_correspondence();
      });
    },
    DDB_GET_SENDER_CORRESPONDENCES({ commit }, params) {
      return new Promise((resolve) => {
        params.limit = 2000;
        //params.contact_sender_receiver = {eq: "SENDER"}
        async function get_correspondence() {
          var correspondences;

          correspondences = await API.graphql({
            query: queries.listContactByCorrespondenceSender,
            variables: params,
          });
          var selected_correspondences = [];
          for (const i in correspondences.data.listContactByCorrespondenceSender
            .items) {
            selected_correspondences.push(
              correspondences.data.listContactByCorrespondenceSender.items[i]
                .correspondence
            );
          }
          commit("SET_ALL_CORRESPONDENCES", selected_correspondences);
          resolve();
        }
        get_correspondence();
      });
    },
    DDB_GET_CORRESPONDENCES_BY_PROJECT({ commit }, id_corr) {
      return new Promise((resolve) => {
        async function get_project_correspondence() {
          var project_correspondence;

          project_correspondence = await API.graphql({
            query: queries.listCorrespondenceByProjectID,
            variables: { project_id: id_corr[0] },
          });
          var correspondences = [[], []];
          for (const i in project_correspondence.data
            .listCorrespondenceByProjectID.items) {
            correspondences[0].push(
              project_correspondence.data.listCorrespondenceByProjectID.items[i]
                .correspondence
            );
            correspondences[1].push(
              project_correspondence.data.listCorrespondenceByProjectID.items[i]
                .id
            );
          }
          if (!id_corr[1]) {
            commit("SET_CORRESPONDENCES", correspondences);
          } else {
            commit("SET_ALL_CORRESPONDENCES", correspondences[0]);
          }
          resolve();
        }
        get_project_correspondence();
      });
    },
    DDB_GET_CORRESPONDENCES_BY_CONTACT({ commit }, id) {
      return new Promise((resolve) => {
        async function get_contacts_correspondence() {
          var correspondences_contact;

          correspondences_contact = await API.graphql({
            query: queries.listCorrespondenceByContactID,
            variables: { contact_id: id[0] },
          });
          var correspondences = [];
          for (const i in correspondences_contact.data
            .listCorrespondenceByContactID.items) {
            correspondences.push(
              correspondences_contact.data.listCorrespondenceByContactID.items[
                i
              ].correspondence
            );
          }
          if (!id[1]) {
            commit("SET_CORRESPONDENCES", correspondences);
          } else {
            commit("SET_ALL_CORRESPONDENCES", correspondences);
          }
          resolve();
        }
        get_contacts_correspondence();
      });
    },
    DDB_GET_CONTACTS_BY_PROJECT({ commit }, id) {
      return new Promise((resolve) => {
        async function get_project_contacts() {
          var project_contacts;
          project_contacts = await API.graphql({
            query: queries.listContactsByProjectID,
            variables: { project_id: id },
          });
          var contacts = [];
          for (const i in project_contacts.data.listContactsByProjectID.items) {
            contacts.push(
              project_contacts.data.listContactsByProjectID.items[i].contact
            );
          }
          commit("SET_CONTACTS", contacts);
          resolve();
        }
        get_project_contacts();
      });
    },
    DDB_GET_PROJECT_ID_NAMES({ commit }) {
      return new Promise((resolve) => {
        async function get_project_contacts() {
          var project_names;
          project_names = await API.graphql({
            query: queries.listProjectNames,
          });

          commit("SET_PROJECT_ID_NAMES", project_names.data.listProjects.items);
          resolve();
        }
        get_project_contacts();
      });
    },
    DDB_GET_PROJECT_CONTACTS({ commit }, id) {
      return new Promise((resolve) => {
        async function get_project_contacts() {
          var project_contacts;
          project_contacts = await API.graphql({
            query: queries.getProject,
            variables: { id: id },
          });
          commit(
            "SET_SELECTED_PROJECT_CONTACTS",
            project_contacts.data.getProject
          );
          resolve();
        }
        get_project_contacts();
      });
    },
    DDB_GET_CAMERA_IDS({ commit }) {
      return new Promise((resolve) => {
        async function get_camera_ids() {
          var camera_ids;
          camera_ids = await API.graphql({
            query: queries.listCameraIDs,
            variables: { limit: 2000 },
          });
          commit("SET_CAMERA_IDS", camera_ids.data.listCameras.items);
          resolve();
        }
        get_camera_ids();
      });
    },
    DDB_GET_PROJECT({ commit }, params) {
      return new Promise((resolve) => {
        params.limit = 2000;
        async function get_projects() {
          var projects;
          projects = await API.graphql({
            query: queries.listProjects,
            variables: params,
          });
          commit("SET_PROJECTS", projects.data.listProjects.items);
          resolve();
        }
        get_projects();
      });
    },
    DDB_GET_CONTACTS({ commit }, params) {
      return new Promise((resolve) => {
        params.limit = 2000;
        async function get_contacts() {
          var contacts;
          contacts = await API.graphql({
            query: queries.listContacts,
            variables: params,
          });
          commit("SET_CONTACTS", contacts.data.listContacts.items);
          resolve();
        }
        get_contacts();
      });
    },
    DDB_GET_FOOTAGE({ commit }, params) {
      return new Promise((resolve) => {
        params.limit = 2000;
        params.sortDirection = "DESC";
        async function get_footage() {
          var footage;
          try {
            footage = await API.graphql({
              query: queries.listFootages,
              variables: params,
            });
          } catch (err) {
            console.log(err);
          }

          commit("SET_FOOTAGE", footage.data.listFootages.items);
          resolve();
        }
        get_footage();
      });
    },
    DDB_GET_FOOTAGE_CAMERAID({ commit }, params) {
      return new Promise((resolve) => {
        params.limit = 2000;
        params.sortDirection = "DESC";
        async function get_footage() {
          var footage;
          footage = await API.graphql({
            query: queries.listFootageByCamera,
            variables: params,
          });
          commit("SET_FOOTAGE", footage.data.listFootageByCamera.items);
          resolve();
        }
        get_footage();
      });
    },
    DDB_GET_HERDS({ commit }, params) {
      return new Promise((resolve) => {
        params.limit = 99999;
        async function get_herds() {
          var herds;
          herds = await API.graphql({
            query: queries.listHerds,
            variables: params,
          });
          commit("SET_HERDS", herds.data.listHerds.items);
          resolve();
        }
        get_herds();
      });
    },

    DDB_GET_ANIMAL_IMAGES({ commit }, params) {
      return new Promise((resolve) => {
        async function get_herds() {
          let order_by = {};
          if (params.sort) {
            order_by[params.sort] = params.sort_direction;
          }
          var where = {};

          if (params.date) {
            where.media_timestamp = {
              _lt: moment(params.date, "YYYY-MM-DD")
                .add(1, "d")
                .format("YYYY-MM-DD"),
              _gte: params.date,
            };
          }

          if (params.search && params.search.length) {
            where.detected_tag_label = { label: { _iregex: params.search } };
          }

          if (params.customer_id && params.customer_id != "All Customers") {
            where.customer_id = { _eq: params.customer_id };
          }
          let images = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.animal_image,
                variables: {
                  where: where,
                  order_by: order_by,
                  limit: params.limit,
                  offset: params.offset,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          let id_count = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.animal_image_aggregate,
                variables: {
                  where: where,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.animal_image_aggregate.aggregate.count;
          // console.log(images);
          commit("SET_ANIMAL_IMAGES", {
            animal_images: images.data.animal_image,
            animal_images_count: id_count,
          });
          resolve();
        }
        get_herds();
      });
    },
    DDB_GET_COGNITO_USERS({ commit }) {
      if (sem_status.users) {
        return sem_status.users;
      }
      var owner_name_object = [];
      var name_owner_object = {};
      var user_name_object = [];
      var users = [];
      async function sortByKey(array, key) {
        return array.sort(function (a, b) {
          var x = a[key];
          var y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
      }
      function getUsersInGroup(poolID, groupname, key) {
        var params = {
          GroupName: groupname,
          UserPoolId: poolID,
        };
        if (key) {
          params.NextToken = key;
        }
        var command = new ListUsersInGroupCommand(params);
        return new Promise((resolve) => {
          cognitoidentityserviceprovider
            .send(command)
            .then((data) => {
              (async () => {
                data.Users.forEach((u) => {
                  if (
                    !users.some((u1) => {
                      var u1_email = u1.Attributes.find(
                        (e) => e.Name == "email"
                      )?.Value;
                      var u_email = u.Attributes.find(
                        (e) => e.Name == "email"
                      )?.Value;
                      return u1.Username == u.Username || u1_email == u_email;
                    })
                  ) {
                    u.Groups = [{ GroupName: groupname }];
                    users.push(u);
                  } else {
                    users
                      .find((u1) => {
                        var u1_email = u1.Attributes.find(
                          (e) => e.Name == "email"
                        )?.Value;
                        var u_email = u.Attributes.find(
                          (e) => e.Name == "email"
                        )?.Value;
                        return u1.Username == u.Username || u1_email == u_email;
                      })
                      .Groups.push({ GroupName: groupname });
                  }
                });
                if (data.NextToken) {
                  await getUsersInGroup(poolID, groupname, data.NextToken);
                }
                resolve();
              })();
            })
            .catch((err) => {
              console.log(err, err.stack);
            });
        });
        // return new Promise((resolve)=>{
        //   cognitoidentityserviceprovider.listUsersInGroup(user_params, (err, data)=>{
        //     if (err) { console.log(err, err.stack); }// an error occurred
        //     else {
        //       (async()=>{
        //         data.Users.forEach(u=>{
        //           if(!users.some(u1=>{
        //             var u1_email = u1.Attributes.find(e=>e.Name=="email")?.Value;
        //             var u_email = u.Attributes.find(e=>e.Name=="email")?.Value;
        //             return u1.Username == u.Username || u1_email == u_email;
        //           })){
        //             u.Groups = [{GroupName: groupname}]
        //             users.push(u);
        //           }else{
        //             users.find(u1=>{
        //               var u1_email = u1.Attributes.find(e=>e.Name=="email")?.Value;
        //               var u_email = u.Attributes.find(e=>e.Name=="email")?.Value;
        //               return u1.Username == u.Username || u1_email == u_email;
        //             }).Groups.push({GroupName: groupname});
        //           }
        //         })
        //         if(data.NextToken){
        //           await getUsersInGroup(groupname, data.NextToken)
        //         }
        //         resolve()
        //       })()
        //     }
        //   })
        // })
      }
      function getUsersNotInGroup(poolID, key) {
        var params = {
          UserPoolId: poolID,
        };
        if (key) {
          params.PaginationToken = key;
        }
        var command = new ListUsersCommand(params);
        return new Promise((resolve) => {
          cognitoidentityserviceprovider
            .send(command)
            .then((data) => {
              (async () => {
                data.Users.forEach((u) => {
                  if (
                    !users.some((u1) => {
                      var u1_email = u1.Attributes.find(
                        (e) => e.Name == "email"
                      )?.Value;
                      var u_email = u.Attributes.find(
                        (e) => e.Name == "email"
                      )?.Value;
                      return u1.Username == u.Username || u1_email == u_email;
                    })
                  ) {
                    u.Groups = [];
                    users.push(u);
                  }
                });
                if (data.PaginationToken) {
                  await getUsersNotInGroup(poolID, data.PaginationToken);
                }
                resolve();
              })();
            })
            .catch((err) => {
              console.log(err, err.stack);
            });
        });

        // return new Promise((resolve)=>{
        //   cognitoidentityserviceprovider.listUsers(user_params, (err, data)=>{
        //     if (err) { console.log(err, err.stack); }// an error occurred
        //     else {
        //       (async()=>{
        //         data.Users.forEach(u=>{
        //           if(!users.some(u1=>{
        //             var u1_email = u1.Attributes.find(e=>e.Name=="email")?.Value;
        //             var u_email = u.Attributes.find(e=>e.Name=="email")?.Value;
        //             return u1.Username == u.Username || u1_email == u_email;
        //           })){
        //             u.Groups = [];
        //             users.push(u);
        //           }
        //         })
        //         if(data.PaginationToken){
        //           await getUsersNotInGroup(data.PaginationToken)
        //         }
        //         resolve()
        //       })()
        //     }
        //   })
        // })
      }
      function getUsersInPool(poolID) {
        var params = {
          UserPoolId: poolID,
          // UserPoolId: process.env.VUE_APP_USER_POOL_ID, /* required */
        };

        var command = new ListGroupsCommand(params);
        return cognitoidentityserviceprovider
          .send(command)
          .then((data) => {
            var list_user_promises = [];
            data.Groups.forEach((e) => {
              list_user_promises.push(
                getUsersInGroup(poolID, e.GroupName, null)
              );
            });
            list_user_promises.push(getUsersNotInGroup(poolID, null));
            return Promise.all(list_user_promises);
          })
          .then(() => {
            for (const i in users) {
              owner_name_object.push({
                value: users[i].Username,
                text: users[i].Attributes[users[i].Attributes.length - 1].Value,
              });
              name_owner_object[users[i].Username] =
                users[i].Attributes[users[i].Attributes.length - 1].Value;
              var first_name =
                users[i].Attributes.find((e) => e.Name == "given_name")
                  ?.Value || "";
              var last_name =
                users[i].Attributes.find((e) => e.Name == "family_name")
                  ?.Value || "";
              if (first_name == last_name) {
                last_name = "";
              }
              if (first_name || last_name) {
                user_name_object.push({
                  value: users[i].Username,
                  text:
                    first_name +
                    (first_name && last_name ? " " : "") +
                    last_name,
                });
              }
            }
          })
          .catch((err) => {
            console.log(err, err.stack);
          });
        // return new Promise(resolve0=>{
        //   var params = {
        //     UserPoolId: poolID
        //     // UserPoolId: process.env.VUE_APP_USER_POOL_ID, /* required */
        //   }

        //   cognitoidentityserviceprovider.listGroups(params, (err, data)=>{
        //     if (err) { console.log(err, err.stack); }// an error occurred
        //     else {
        //       var list_user_promises = [];
        //       data.Groups.forEach(e=>{
        //         list_user_promises.push(new Promise((resolve1)=>{
        //           async function getUsersInGroup(key) {
        //             var user_params = {
        //               GroupName: e.GroupName,
        //               UserPoolId: poolID
        //             };
        //             if(key){
        //               user_params.NextToken = key
        //             }
        //             return new Promise((resolve2)=>{
        //               cognitoidentityserviceprovider.listUsersInGroup(user_params, (err, data)=>{
        //                 if (err) { console.log(err, err.stack); }// an error occurred
        //                 else {
        //                   (async()=>{
        //                     data.Users.forEach(u=>{
        //                       if(!users.some(u1=>{
        //                         var u1_email = u1.Attributes.find(e=>e.Name=="email")?.Value;
        //                         var u_email = u.Attributes.find(e=>e.Name=="email")?.Value;
        //                         return u1.Username == u.Username || u1_email == u_email;
        //                       })){
        //                         u.Groups = [{GroupName: e.GroupName}]
        //                         users.push(u);
        //                       }else{
        //                         users.find(u1=>{
        //                           var u1_email = u1.Attributes.find(e=>e.Name=="email")?.Value;
        //                           var u_email = u.Attributes.find(e=>e.Name=="email")?.Value;
        //                           return u1.Username == u.Username || u1_email == u_email;
        //                         }).Groups.push({GroupName: e.GroupName});
        //                       }
        //                     })
        //                     if(data.NextToken){
        //                       await getUsersInGroup(data.NextToken)
        //                     }
        //                     resolve2()
        //                   })()
        //                 }
        //               })
        //             })
        //           }
        //           (async()=>{
        //             await getUsersInGroup(null);
        //             resolve1();
        //           })()
        //         }))
        //       });
        //       list_user_promises.push(new Promise((resolve1)=>{
        //         async function getUsersNotInGroup(key) {
        //           var user_params = {
        //             UserPoolId: poolID
        //           };
        //           if(key){
        //             user_params.PaginationToken = key;
        //           }
        //           return new Promise((resolve2)=>{
        //             cognitoidentityserviceprovider.listUsers(user_params, (err, data)=>{
        //               if (err) { console.log(err, err.stack); }// an error occurred
        //               else {
        //                 (async()=>{
        //                   data.Users.forEach(u=>{
        //                     if(!users.some(u1=>{
        //                       var u1_email = u1.Attributes.find(e=>e.Name=="email")?.Value;
        //                       var u_email = u.Attributes.find(e=>e.Name=="email")?.Value;
        //                       return u1.Username == u.Username || u1_email == u_email;
        //                     })){
        //                       u.Groups = [];
        //                       users.push(u);
        //                     }
        //                   })
        //                   if(data.PaginationToken){
        //                     await getUsersNotInGroup(data.PaginationToken)
        //                   }
        //                   resolve2()
        //                 })()
        //               }
        //             })
        //           })
        //         }
        //         (async()=>{
        //           await getUsersNotInGroup(null);
        //           resolve1();
        //         })()
        //       }))
        //       Promise.all(list_user_promises).then(()=>{
        //         for (const i in users) {
        //           owner_name_object.push({ value: users[i].Username, text: users[i].Attributes[users[i].Attributes.length - 1].Value })
        //           name_owner_object[users[i].Username] = users[i].Attributes[users[i].Attributes.length - 1].Value;
        //           var first_name = users[i].Attributes.find(e=>e.Name=="given_name")?.Value || "";
        //           var last_name = users[i].Attributes.find(e=>e.Name=="family_name")?.Value || "";
        //           if(first_name==last_name){
        //             last_name = "";
        //           }
        //           if(first_name || last_name){
        //             user_name_object.push({ value: users[i].Username, text: first_name + ((first_name&&last_name)? " " :"") +last_name});
        //           }
        //         }
        //         resolve0();
        //       })
        //     }
        //   })
        // })
      }
      return (sem_status.users = initializeCognitoIdentityProviderClient()
        .then((client) => {
          cognitoidentityserviceprovider = client;
          var all_user_promises = [];
          // all_user_promises.push(getUsersInPool(process.env.VUE_APP_USER_POOL_ID));
          all_user_promises.push(
            getUsersInPool(process.env.VUE_APP_CLIENT_USER_POOL_ID)
          );
          return Promise.all(all_user_promises);
        })
        .then(() => {
          sortByKey(owner_name_object, "text");
          commit("SET_OWNER_NAME_OBJECT", [
            owner_name_object,
            name_owner_object,
            user_name_object,
          ]);
          commit("SET_COGNITO_USERS", users);
          delete sem_status.users;
        })
        .catch((err) => {
          console.log(err);
        }));
    },
    DDB_GET_ANIMALS({ commit }) {
      return new Promise((resolve) => {
        commit("SET_ANIMALS", []);

        async function get_animals() {
          var animals;
          try {
            animals = await API.graphql({
              query: queries.listAnimals,
              variables: { limit: 999999 },
            });
          } catch (e) {
            console.log(e);
          }
          commit("SET_ANIMALS", animals.data.listAnimals.items);

          resolve();
        }
        get_animals();
      });
    },
    DDB_GET_ANIMALS_FOR_TAG({ commit }) {
      if (sem_status.animal_tags) {
        return sem_status.animal_tags;
      }
      sem_status.animal_tags = new Promise((resolve) => {
        async function get_animal_tags() {
          var animal_tag_objects = await getByPagination(
            "listAnimalsForTags",
            "listAnimals"
          );
          commit("SET_ANIMAL_TAGS", animal_tag_objects);
          delete sem_status.animal_tags;
          resolve();
        }

        get_animal_tags();
      });
      return sem_status.animal_tags;
    },
    DDB_GET_TREEVIEW_CUSTOMER({ commit }, id) {
      return new Promise((resolve) => {
        async function get_customer() {
          var selected_customer;
          selected_customer = await API.graphql({
            query: queries.getCustomerTree,
            variables: { id: id },
          });
          commit("SET_TREEVIEW_CUSTOMER", selected_customer.data.getCustomer);

          resolve();
        }
        get_customer();
      });
    },
    // DDB_GET_SELECTED_CUSTOMER({ commit }, id) {
    //   return new Promise((resolve) => {
    //     async function get_customer() {
    //       var selected_customer;
    //       selected_customer = await API.graphql({
    //         query: queries.getCustomer,
    //         variables: { id: id },
    //       });
    //       commit("SET_SELECTED_CUSTOMER", selected_customer.data.getCustomer);

    //       resolve();
    //     }
    //     get_customer();
    //   });
    // },
    DDB_GET_SELECTED_CUSTOMER({ commit }, id) {
      return new Promise((resolve) => {
        async function get_customer() {
          // selected_customer = await API.graphql({
          //   query: queries.getCustomer,
          //   variables: { id: id },
          // });

          var selected_customer = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.customer_by_pk,
                variables: { id: id },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.customer_by_pk;
          // selected_customer = await API.graphql({
          //   query: hasura_queries.customer_by_pk,
          //   variables: { id: id },
          // });
          console.log(selected_customer);
          commit("SET_SELECTED_CUSTOMER", selected_customer);

          resolve();
        }

        get_customer();
      });
    },
    DDB_GET_EDITED_CUSTOMER({ commit }, id) {
      if (sem_status.edited_customer) {
        return sem_status.edited_customer;
      }

      sem_status.edited_customer = new Promise((resolve) => {
        async function get_customer() {
          var edited_customer = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.customer_by_pk,
                variables: { id: id },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;
          console.log(edited_customer);

          commit("SET_EDITED_CUSTOMER", edited_customer.data.customer_by_pk);
          delete sem_status.edited_customer;
          resolve();
        }
        get_customer();
      });

      return sem_status.edited_customer;
    },
    DDB_GET_EVENTS({ commit }, params) {
      return new Promise((resolve) => {
        params.limit = 99999;
        async function get_events() {
          var events;
          events = await API.graphql({
            query: queries.listEvents,
            variables: params,
          });
          commit("SET_EVENTS", events.data.listEvents.items);
          resolve();
        }
        get_events();
      });
    },
    DDB_GET_VIDEOS2({ commit }, params) {
      return new Promise((resolve) => {
        commit("SET_VIDEOS", []);
        params.limit = 480;
        async function get_videos() {
          var videos;
          videos = await API.graphql({
            query: queries.listVideos,
            variables: params,
          });
          commit("SET_VIDEOS", videos.data.listVideos.items);
          resolve();
        }
        get_videos();
      });
    },
    DDB_GET_VIDEOS3({ commit }, params) {
      return new Promise((resolve) => {
        commit("SET_VIDEOS", []);
        params.limit = 480;

        async function get_videos() {
          var videos;
          var query_variables = {};
          query_variables.camera_idVideo_datetime = {
            beginsWith: { camera_id: params.camera_id },
          };
          query_variables.customer_id = params.owner;
          try {
            videos = await API.graphql({
              query: queries.listVideosByCustomerCameraDate,
              variables: query_variables,
            });
          } catch (err) {
            console.log(err);
          }

          commit(
            "SET_VIDEOS",
            videos.data.listVideosByCustomerCameraDate.items
          );
          resolve();
        }
        get_videos();
      });
    },
    DDB_GET_VIDEOS({ commit }, params) {
      return new Promise((resolve) => {
        var _this = this;
        var thumbnail_objects = [];
        var query_variables = {};
        async function get_videos() {
          if (params.owner == "All Customers") {
            params.owner = null;
          }
          // if (params.owner) {
          //   query_variables = {
          //     customer_id: params.owner
          //   }
          //   query_variables.sortDirection = params.sort_order
          // }

          query_variables.limit = params.per_page;
          // initializze filter params
          var filter_params = {};
          if (params.owner) {
            filter_params.owner = { eq: params.owner };
          }
          if (params.source) {
            filter_params.video_source = { eq: params.source };
          }
          if (params.selected_bookmark) {
            filter_params.video_bookmarked = { eq: "YES" };
          }
          if (params.dates) {
            if (params.dates.length == 2) {
              filter_params.video_datetime = {
                gte: moment
                  .utc(params.dates[0])
                  .format("YYYY-MM-DDThh:mm:ss.sssZ"),
                lte: moment
                  .utc(params.dates[1])
                  .format("YYYY-MM-DDThh:mm:ss.sssZ"),
              };
            } else if (params.dates.length == 1) {
              filter_params.video_datetime = {
                match: params.dates[0].slice(0, 10),
              };
            }
          }
          if (params.cameras) {
            filter_params.camera_id = { eq: params.cameras };
          }
          if (params.model) {
            var cameras_with_model = await getAllCamerasByModel(params.model);
            filter_params.or = [];
            cameras_with_model.forEach((e) => {
              filter_params.or.push({
                camera_id: { eq: e },
              });
            });
          }
          if (Object.keys(filter_params).length != 0) {
            query_variables.filter = filter_params;
          }
          if (params.sort_order) {
            query_variables.sort = {
              direction: params.sort_order.toLowerCase(),
              field: "video_datetime",
            };
          }
          //query_variables.sortDirection = params.sort_order
          if (params.next_token) {
            query_variables.nextToken = params.next_token;
          }
          var temp_thumbnail_objects = [];
          try {
            temp_thumbnail_objects = await API.graphql({
              // query: queries.listVideos,
              query: queries.searchVideos,
              variables: query_variables,
            });
            // thumbnail_objects = [temp_thumbnail_objects.data.listVideos.items, temp_thumbnail_objects.data.listVideos.nextToken]
            thumbnail_objects = [
              temp_thumbnail_objects.data.searchVideos.items,
              temp_thumbnail_objects.data.searchVideos.nextToken,
            ];
          } catch (error) {
            console.log(error);
          }
          if (_this.state.videos[0] && !params.first_time) {
            thumbnail_objects[0] = _this.state.videos[0].concat(
              thumbnail_objects[0]
            );
          }
          console.log("=====thumbnail_objects=====");
          console.log(thumbnail_objects);

          var tz_promises = [];
          thumbnail_objects[0].forEach((e) => {
            tz_promises.push(
              new Promise((resolve_tz) => {
                API.graphql({
                  query: queries.getCamera,
                  variables: { id: e.camera_id },
                }).then((res) => {
                  e.video_timezone = res.data.getCamera?.site?.site_timezone;
                  resolve_tz();
                });
              })
            );
          });
          Promise.all(tz_promises).then(() => {
            commit("SET_VIDEOS", thumbnail_objects);
            resolve();
          });
        }
        get_videos();
      });
    },
    DDB_GET_VIDEOS_HASURA({ commit }, params) {
      return new Promise((resolve) => {
        var _this = this;
        var thumbnail_objects = [];

        async function get_videos() {
          try {
            var page = params.per_page * (params.page - 1);
            var query;
            var variables = {
              pageSize: params.per_page,
              page: page,
            };

            if (
              params.cameras &&
              params.owner &&
              params.owner != "All Customers"
            ) {
              query = hasura_queries.getHeatVideosWithBoth;
              variables.camera_id = params.cameras;
              variables.customer_id = params.owner;
            } else if (params.cameras) {
              query = hasura_queries.getHeatVideosWithCameraId;
              variables.camera_id = params.cameras;
            } else if (params.owner) {
              query = hasura_queries.getHeatVideosWithCustomerId;
              variables.customer_id = params.owner;
            } else {
              query = hasura_queries.getHeatVideos;
            }

            const response = await axios.post(
              store.state.hasura_params.url,
              { query: query, variables: variables },
              { headers: store.state.hasura_params.headers }
            );

            const modifiedData =
              response.data.data.custom_video_classifications.map((item) => {
                // Extract relevant values from the item
                const {
                  camera_id,
                  customer_id,
                  media_timestamp,
                  inference_start,
                  inference_end,
                } = item;

                const timestamp = new Date(media_timestamp);
                const year = timestamp.getFullYear();
                const month = (timestamp.getMonth() + 1)
                  .toString()
                  .padStart(2, "0");
                const day = timestamp.getDate().toString().padStart(2, "0");
                const hours = timestamp.getHours().toString().padStart(2, "0");
                const minutes = timestamp
                  .getMinutes()
                  .toString()
                  .padStart(2, "0");
                const seconds = timestamp
                  .getSeconds()
                  .toString()
                  .padStart(2, "0");

                const overall_time = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

                const video_path = `${customer_id}/${camera_id}/${year}/${month}/${day}/${camera_id}_${overall_time}`;
                const video_datetime = timestamp.toISOString();

                const startTimestamp = new Date(inference_start);
                const endTimestamp = new Date(inference_end);
                const durationInSeconds =
                  (endTimestamp - startTimestamp) / 1000;

                const minutesDuration = Math.floor(durationInSeconds / 60);
                const secondsDuration = Math.floor(durationInSeconds % 60);
                const formattedDuration = `${minutesDuration
                  .toString()
                  .padStart(2, "0")}:${secondsDuration
                  .toString()
                  .padStart(2, "0")}`;

                return {
                  ...item,
                  video_path: video_path,
                  video_datetime: video_datetime,
                  video_duration: formattedDuration,
                };
              });

            thumbnail_objects[0] = modifiedData;
            thumbnail_objects[1] = null;
            if (_this.state.videos[0] && !params.first_time) {
              thumbnail_objects[0] = _this.state.videos[0].concat(
                thumbnail_objects[0]
              );
            }

            var tz_promises = [];
            thumbnail_objects[0].forEach((e) => {
              tz_promises.push(
                new Promise((resolve_tz) => {
                  API.graphql({
                    query: queries.getCamera,
                    variables: { id: e.camera_id },
                  }).then((res) => {
                    e.video_timezone = res.data.getCamera?.site?.site_timezone;
                    resolve_tz();
                  });
                })
              );
            });
            Promise.all(tz_promises).then(() => {
              commit("SET_VIDEOS", thumbnail_objects);
              resolve();
            });
          } catch (error) {
            console.error(error);
          }
        }
        get_videos();
      });
    },
    DDB_GET_CAMERAS({ commit }, params) {
      if (sem_status.cameras) {
        return sem_status.cameras;
      }
      sem_status.cameras = new Promise((resolve) => {
        async function get_cameras() {
          let order_by = {};
          if (params.sort) {
            order_by[params.sort] = params.sort_direction;
          }
          let where = {};

          if (params.search && params.search.length) {
            where._or = [
              { name: { _iregex: params.search } },
              { id: { _iregex: params.search } },
            ];
          }
          if (
            params.filter.customer &&
            params.filter.customer != "All Customers"
          ) {
            where.customer_id = { _eq: params.filter.customer };
          }
          if (params.filter.status && params.filter.status != "All") {
            where.status = { _eq: params.filter.status };
          }
          if (params.filter.model && params.filter.model != "All") {
            where.model = { _eq: params.filter.model };
          }
          if (
            params.filter.blurriness_label &&
            params.filter.blurriness_label != "All"
          ) {
            where.blurriness_label = { _eq: params.filter.blurriness_label };
          }
          if (params.filter.shipped && params.filter.shipped != "All") {
            where.shipped = { _eq: params.filter.shipped };
          }

          var camera_objects = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.camera,
                variables: {
                  where: where,
                  order_by: order_by,
                  limit: params.limit,
                  offset: params.offset,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data;

          var count = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.camera_aggregate,
                variables: {
                  where: where,
                },
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data;
          console.log(camera_objects);
          console.log(where);
          commit("SET_CAMERA_OBJECTS", {
            cameras: camera_objects.data.camera,
            count: count.camera_aggregate.aggregate.count,
          });
          delete sem_status.cameras;
          resolve();
        }

        get_cameras();
      });
      return sem_status.cameras;
    },

    DDB_GET_CAMERA_RESOLUTIONS({ commit }) {
      return new Promise((resolve) => {
        async function get_camera_resolution() {
          var camera_resolution_data = await API.graphql({
            query: queries.searchCameraResolutions,
          });

          var camera_resolutions =
            camera_resolution_data.data.searchCameras.aggregateItems[0]?.result?.buckets?.map(
              (e) => e.key
            ) || [];
          commit("SET_CAMERA_RESOLUTIONS", camera_resolutions);
          resolve();
        }

        get_camera_resolution();
      });
    },
    DDB_GET_CAMERA_SOURCES({ commit }) {
      return new Promise((resolve) => {
        async function get_camera_source() {
          var camera_source_data = await API.graphql({
            query: queries.searchCameraSources,
          });
          var camera_sources = {};
          console.log("=====there=====");
          console.log(camera_source_data);
          camera_source_data.data.searchCameras.aggregateItems[0]?.result?.buckets?.forEach(
            (e) => {
              camera_sources[e.key] = e.doc_count;
            }
          );
          commit("SET_CAMERA_SOURCES", camera_sources);
          resolve();
        }

        get_camera_source();
      });
    },

    DDB_GET_WIFI({ commit }) {
      if (sem_status.wifi) {
        return sem_status.wifi;
      }
      sem_status.wifi = new Promise((resolve) => {
        async function get_wifi() {
          var wifi_objects = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.wifi,
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.wifi;
          commit("SET_WIFI_OBJECTS", wifi_objects);
          delete sem_status.wifi;
          resolve();
        }
        get_wifi();
      });
      return sem_status.wifi;
    },

    DDB_GET_CUSTOMERS({ commit, state }) {
      if (sem_status.customers) {
        return sem_status.customers;
      }
      async function sortByKey(array, key) {
        return array.sort(function (a, b) {
          var x = a[key];
          var y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
      }
      sem_status.customers = new Promise((resolve) => {
        async function get_customers() {
          var customer_objects = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.customer,
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.customer;
          var customer_names = [[], {}];
          var current_id = state.user.username;
          // console.log(state.user);
          var current_customer;
          for (const i in customer_objects) {
            customer_names[0].push({
              text:
                (customer_objects[i].first_name
                  ? customer_objects[i].first_name + " "
                  : "") + customer_objects[i].last_name,
              value: customer_objects[i].id,
            });
            customer_names[1][customer_objects[i].id] = [];
            for (const n in customer_objects[i].sites) {
              customer_names[1][customer_objects[i].id].push({
                text: customer_objects[i].sites[n].name,
                value: customer_objects[i].sites[n].id,
              });
            }

            if (customer_objects[i].id == current_id) {
              current_customer = customer_objects[i];
            }
          }

          // console.log(current_customer);
          sortByKey(customer_names, "text");
          // commit('SET_OWNER_NAME_OBJECT', [owner_name_object, name_owner_object])
          commit("SET_CUSTOMER_NAMES", customer_names);
          commit("SET_CUSTOMER_OBJECTS", customer_objects);
          if (current_customer) {
            commit("SET_CURRENT_CUSTOMER", current_customer);
          }
          delete sem_status.customers;
          resolve();
        }
        get_customers();
      });
      return sem_status.customers;
    },

    DDB_GET_SITES({ commit }) {
      if (sem_status.sites) {
        return sem_status.sites;
      }
      sem_status.sites = new Promise((resolve) => {
        async function get_sites() {
          var site_objects = (
            await axios.post(
              store.state.hasura_params.url,
              {
                query: hasura_queries.site,
              },
              {
                headers: store.state.hasura_params.headers,
              }
            )
          ).data.data.site;

          var site_name_object = {};
          var name_site_object = {};
          var site_names = [];
          for (const i in site_objects) {
            site_names.push(site_objects[i].name);
            site_name_object[site_objects[i].id] = site_objects[i].name;
            name_site_object[site_objects[i].name] = site_objects[i].id;
          }
          site_names.sort();
          commit("SET_SITE_NAMES", site_names);
          commit("SET_SITE_NAME_OBJECT", site_name_object);
          commit("SET_NAME_SITE_OBJECT", name_site_object);
          commit("SET_SITE_OBJECTS", site_objects);
          delete sem_status.sites;
          resolve();
        }
        get_sites();
      });
      return sem_status.sites;
    },
    DDB_HOME_PAGE_STATS({ commit }, customer_id) {
      return new Promise((resolve) => {
        async function get_home_page_stats() {
          var stats = {
            customer_count: 0,
            site_count: 0,
            animal_count: 0,
            animal_tag_count: 0,
            animal_image_count: 0,
            camera_count: 0,
            online_camera_count: 0,
            wifi_count: 0,
          };
          let where = {};

          if (customer_id) {
            where.customer_id = { _eq: customer_id };
          }

          let customer_count = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.customer_aggregate,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let site_count = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.site_aggregate,
              variables: {
                where: where,
              },
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let animal_count = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.animal_aggregate,
              variables: {
                where: where,
              },
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let animal_tag_count = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.animal_tag_aggregate,
              variables: {
                where: where,
              },
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let animal_image_count = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.animal_image_aggregate,
              variables: {
                where: where,
              },
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let camera_count = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.camera_aggregate,
              variables: {
                where: where,
              },
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let online_camera_count = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.camera_aggregate,
              variables: {
                where: customer_id
                  ? {
                      status: { _eq: "ONLINE" },
                      customer_id: { _eq: customer_id },
                    }
                  : { status: { _eq: "ONLINE" } },
              },
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let wifi_count = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.wifi_aggregate,
              variables: {
                where: where,
              },
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          console.log(animal_image_count);
          stats = {
            customer_count:
              customer_count.data.data.customer_aggregate.aggregate.count,
            site_count: site_count.data.data.site_aggregate.aggregate.count,
            animal_count:
              animal_count.data.data.animal_aggregate.aggregate.count,
            animal_tag_count:
              animal_tag_count.data.data.animal_tag_aggregate.aggregate.count,
            animal_image_count:
              animal_image_count.data.data.animal_image_aggregate.aggregate
                .count,
            camera_count:
              camera_count.data.data.camera_aggregate.aggregate.count,
            online_camera_count:
              online_camera_count.data.data.camera_aggregate.aggregate.count,
            wifi_count: wifi_count.data.data.wifi_aggregate.aggregate.count,
          };
          commit("SET_HOME_PAGE_STATS", stats);
          resolve();
        }
        get_home_page_stats();
      });
    },
    login({ state }, { email, password }) {
      return new Promise((resolve) => {
        state.loginError = "";
        if (!/^.*@onecup\.ai$/.test(email)) {
          state.loginError = "Not Authorized";
          resolve();
        }
        (async () => {
          try {
            const user = await Auth.signIn(email, password);
            state.pending_user = user;
            resolve(state.pending_user.challengeName);
            // if(user.challengeName === 'MFA_SETUP'){
            //   state.pending_user = user;
            // }else if (user.challengeName === 'SOFTWARE_TOKEN_MFA'){
            //   getTOTPToken(async (code)=>{
            //     await Auth.confirmSignIn(
            //       user, // Return object from Auth.signIn()
            //       code, // Confirmation code
            //       "SOFTWARE_TOKEN_MFA"
            //     );
            //     await dispatch('fetchUser')
            //     eventBus.$emit("finish_loading");
            //   })
            // }
          } catch (err) {
            console.log(`Login Error [${err}]`);
            if (err) state.button = true;
            state.loginError = err.message || err;
            resolve();
          }
        })();
      });
      // await dispatch('fetchUser')
    },
    // const setupTOTP = function(user, callback){
    //   Auth.setupTOTP(user).then((code) => {
    //     eventBus.$emit("start_TOTP_setup", code);
    //     eventBus.$emit("finish_loading");
    //   });
    //   eventBus.$on("finish_TOTP_setup", (challengeAnswer)=>{
    //     eventBus.$emit("start_loading");
    //     Auth.verifyTotpToken(user, challengeAnswer)
    //     .then(() => {
    //       Auth.setPreferredMFA(user, 'TOTP');
    //       if(callback){
    //         callback();
    //       }
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       if(callback){
    //         callback();
    //       }
    //     });
    //   });
    // }

    // const getTOTPToken = function(callback){
    //   eventBus.$emit("start_TOTP_verification");
    //   eventBus.$on("finish_TOTP_verification", (code)=>{
    //     callback(code)
    //   })
    // }
    start_TOTP_setup({ state }) {
      return new Promise((resolve, reject) => {
        Auth.setupTOTP(state.pending_user)
          .then((code) => {
            const url = `otpauth://totp/AWSCognito:${state.pending_user.username}?secret=${code}&issuer=OneCup%20AI`;
            var data = {
              url: url,
              code: code,
            };
            resolve(data);
          })
          .catch((err) => {
            console.log(err);
            reject(err.message || err);
          });
      });
    },

    complete_TOTP_setup({ state }, challengeAnswer) {
      return new Promise((resolve, reject) => {
        Auth.verifyTotpToken(state.pending_user, challengeAnswer)
          .then(() => {
            Auth.setPreferredMFA(state.pending_user, "TOTP");
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err.message || err);
          });
      });
    },

    complete_TOTP_verification({ dispatch, state }, code) {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            await Auth.confirmSignIn(
              state.pending_user, // Return object from Auth.signIn()
              code, // Confirmation code
              "SOFTWARE_TOKEN_MFA"
            );
            await dispatch("fetchUser");
            resolve();
          } catch (err) {
            if (err.code == "ExpiredCodeException") {
              var message = err.message || err;
              message = `${message} Please wait until the next code.`;
              reject(message);
            } else {
              reject(err.message || err);
            }
          }
        })();
      });
    },

    async changePass({ token, lastpass, newpass }) {
      var params = {
        AccessToken: token /* required */,
        PreviousPassword: lastpass /* required */,
        ProposedPassword: newpass /* required */,
      };
      await cognitoidentityserviceprovider.changePassword(
        params,
        function (err, data) {
          if (err) console.log(err, err.stack); // an error occurred
          else console.log(data); // successful response
        }
      );
    },

    async signup({ state }, { email, password }) {
      state.signupError = "";
      try {
        await Auth.signUp({
          username: email,
          email: email,
          password: password,
          attributes: {
            email: email,
          },
          validationData: [],
        });
        //switch email confirmation form
        state.signupError =
          "successfully signed up. Please verify your email address";
      } catch (err) {
        console.log(`Signup Error [${err}]`);
        if (err) state.signupError = err.message || err;
      }
    },

    async authState({ commit, dispatch }, state) {
      if (state === "signedIn") await dispatch("fetchUser");
      else commit("user", null);
    },

    async fetchUser({ commit, dispatch }) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const expires =
          user.getSignInUserSession().getIdToken().payload.exp -
          Math.floor(new Date().getTime() / 1000);
        const group = user.getSignInUserSession().getIdToken().decodePayload()[
          "cognito:groups"
        ];

        setTimeout(async () => {
          await dispatch("fetchUser");
        }, expires * 1000);
        if (group.includes("Admin")) {
          // set cookies
          const token = user.getSignInUserSession().getIdToken().jwtToken;
          commit("SET_JWT_TOKEN", token);
          const credentials = await Auth.currentCredentials();

          const ssmClient = new SSMClient({
            region: process.env.VUE_APP_AWS_REGION,
            credentials: {
              accessKeyId: credentials.accessKeyId,
              secretAccessKey: credentials.secretAccessKey,
              sessionToken: credentials.sessionToken,
            },
          });

          const getParametersByPath = async () => {
            const path = "/hasura_admin_secret/";
            let parameters = [];
            let nextToken;
            do {
              const params = {
                Path: path,
                WithDecryption: true,
                MaxResults: 10,
                NextToken: nextToken,
              };

              try {
                // Create a new command instance
                const command = new GetParametersByPathCommand(params);
                // Send the command to the SSM client
                const response = await ssmClient.send(command);
                parameters = parameters.concat(response.Parameters);
                nextToken = response.NextToken;
              } catch (error) {
                console.error("Error fetching parameters:", error);
                break;
              }
            } while (nextToken);

            return parameters;
          };

          // Execute the function
          await getParametersByPath()
            .then((parameters) => {
              commit("SET_HASURA_ADMIN_KEY", parameters);
              this.state.hasura_params.headers = {
                Authorizaation: "",
                "Content-Type": "application/json",
                "x-hasura-admin-secret": parameters[1].Value,
              };

              this.state.hasura_params_write.headers = {
                Authorizaation: "",
                "Content-Type": "application/json",
                "x-hasura-admin-secret": parameters[1].Value,
              };
              commit("user", user);
            })
            .catch((error) => {
              console.error("Error:", error);
            });

          var config = {
            method: "GET",
            withCredentials: true,
            url: process.env.VUE_APP_COOKIE_URL,
            headers: {
              Auth: `Bearer ${token}`,
            },
          };
          axios(config)
            .then(function (response) {
              console.log(response);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } catch (err) {
        commit("user", null);
      }
    },

    async logout({ commit }) {
      await Auth.signOut();
      commit("user", null);
    },

    DDB_GET_SUMMARY_MEDIA({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let query = `summary_media_${params.timeframe}_${
            params.customer == "All Customers" || !params.customer
              ? "admin"
              : "user"
          }`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit * 2 }
              : {
                  limit: params.limit * 2,
                  where: { customer_id: { _eq: params.customer } },
                };

          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_MEDIA", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_SUMMARY_PGIE({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let query = `summary_pgie_${params.timeframe}_${
            params.customer == "All Customers" || !params.customer
              ? "admin"
              : "user"
          }`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit }
              : {
                  limit: params.limit,
                  where: { customer_id: { _eq: params.customer } },
                };
          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_PGIE", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_SUMMARY_ACTIVITY({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let query = `summary_activity_${params.timeframe}_${
            params.customer == "All Customers" || !params.customer
              ? "admin"
              : "user"
          }`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit }
              : {
                  limit: params.limit,
                  where: { customer_id: { _eq: params.customer } },
                };
          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_ACTIVITY", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },
    DDB_GET_SUMMARY_CALVING({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let query = `summary_calving_${params.timeframe}_${
            params.customer == "All Customers" || !params.customer
              ? "admin"
              : "user"
          }`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit }
              : {
                  limit: params.limit,
                  where: { customer_id: { _eq: params.customer } },
                };
          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_CALVING", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },
    DDB_GET_SUMMARY_ESTRUS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let query = `summary_estrus_${params.timeframe}_${
            params.customer == "All Customers" || !params.customer
              ? "admin"
              : "user"
          }`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit }
              : {
                  limit: params.limit,
                  where: { customer_id: { _eq: params.customer } },
                };
          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_ESTRUS", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },
    DDB_GET_SUMMARY_SPECIES({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let query = `summary_species_${params.timeframe}_${
            params.customer == "All Customers" || !params.customer
              ? "admin"
              : "user"
          }`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit }
              : {
                  limit: params.limit,
                  where: { customer_id: { _eq: params.customer } },
                };
          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_SPECIES", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_MEDIA_BY_CAMERA_SUMMARY({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let variables = { where: { media_date: { _eq: params.date } } };
          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries["summary_media_summary"],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit(
            "SET_MEDIA_BY_CAMERA_SUMMARY",
            data.data.data["summary_media_summary"]
          );
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_SUMMARY_VPC({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let query = `summary_vpc_1day_admin`;

          let variables = { limit: params.limit * 20 };

          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_VPC", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_SUMMARY_VEHICLE({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {

          let query = `summary_vehicle_details_${params.timeframe}_${
            params.customer == "All Customers" || !params.customer
              ? "admin"
              : "user"
          }`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit }
              : {
                  limit: params.limit,
                  where: { customer_id: { _eq: params.customer } },
                };

          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_SUMMARY_VEHICLE", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_SUMMARY_TAG({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {

          let query = `summary_tag_${params.timeframe}_${
            params.customer == "All Customers" || !params.customer
              ? "admin"
              : "user"
          }`;

          let variables =
            params.customer == "All Customers" || !params.customer
              ? { limit: params.limit }
              : {
                  limit: params.limit,
                  where: { customer_id: { _eq: params.customer } },
                };

          let data = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries[query],
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          console.log(data)
          commit("SET_SUMMARY_TAG", data.data.data[query]);
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_NOTIFICATION_ANALYTICS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let variables = {
            args: {
              date_start: params.date_start,
              date_end: params.date_end,
              customer: params.customer,
            },
          };
          let variables_customer = {
            args: { date_start: params.date_start, date_end: params.date_end },
          };

          let type = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.notification_analytics,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let customer = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.notification_analytics_customer,
              variables: variables_customer,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let sent = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.notification_analytics_sent,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_NOTIFICATION_ANALYTICS", {
            type: type.data.data.notification_analytics,
            customer: customer.data.data.notification_analytics_customer,
            sent: sent.data.data.notification_analytics_sent,
          });
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_CALVING_CHARTS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let variables = {
            args: {
              date_start: params.date_start,
              date_end: params.date_end,
              customer: params.customer,
            },
          };
          let variables_customer = {
            args: { date_start: params.date_start, date_end: params.date_end },
          };

          let type = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.calving_analytics_chart,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let customer = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.calving_chart_customer,
              variables: variables_customer,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let sent = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.calving_chart_sent,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_CALVING_CHARTS", {
            type: type.data.data.calving_analytics_chart,
            customer: customer.data.data.calving_chart_customer,
            sent: sent.data.data.calving_chart_sent,
          });
          resolve();
        }
        get_data();
      });
    },
    DDB_GET_ESTRUS_CHARTS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let variables = {
            args: {
              date_start: params.date_start,
              date_end: params.date_end,
              customer: params.customer,
            },
          };
          let variables_customer = {
            args: { date_start: params.date_start, date_end: params.date_end },
          };

          let type = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.estrus_analytics_chart,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let customer = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.estrus_chart_customer,
              variables: variables_customer,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let sent = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.estrus_chart_sent,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_ESTRUS_CHARTS", {
            type: type.data.data.estrus_analytics_chart,
            customer: customer.data.data.estrus_chart_customer,
            sent: sent.data.data.estrus_chart_sent,
          });
          resolve();
        }
        get_data();
      });
    },
    DDB_GET_PREDATOR_CHARTS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let variables = {
            args: {
              date_start: params.date_start,
              date_end: params.date_end,
              customer: params.customer,
            },
          };
          let variables_customer = {
            args: { date_start: params.date_start, date_end: params.date_end },
          };

          let type = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.predator_analytics_chart,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let customer = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.predator_chart_customer,
              variables: variables_customer,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let sent = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.predator_chart_sent,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_PREDATOR_CHARTS", {
            type: type.data.data.predator_analytics_chart,
            customer: customer.data.data.predator_chart_customer,
            sent: sent.data.data.predator_chart_sent,
          });
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_ANIMAL_ID_CHARTS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let variables = {
            args: {
              date_start: params.date_start,
              date_end: params.date_end,
              customer: params.customer,
            },
          };
          let variables_customer = {
            args: { date_start: params.date_start, date_end: params.date_end },
          };

          let type = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.tag_id_count,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let customer = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.tag_id_count_by_customer,
              variables: variables_customer,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let unique = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.tag_id_count_unique,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let unique_label = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.tag_id_count_unique_label,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let unique_color = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.tag_id_count_unique_color,
              variables: variables,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          commit("SET_ANIMAL_ID_CHARTS", {
            type: type.data.data.tag_id_count,
            customer: customer.data.data.tag_id_count_by_customer,
          });
          commit("SET_ANIMAL_ID_UNIQUE_CHARTS", {
            combo: unique.data.data.tag_id_count_unique,
            label: unique_label.data.data.tag_id_count_unique_label,
            color: unique_color.data.data.tag_id_count_unique_color,
          });
          resolve();
        }
        get_data();
      });
    },

    DDB_GET_ANIMAL_ID_USERDB({ commit }, params) {
      return new Promise((resolve) => {
        async function get_data() {
          let var_total = {
            args: {
              date_start: params.date_start,
              customer_id: params.customer,
            },
          };
          let var_pending = {
            args: {
              date_start: params.date_start,
              customer_id: params.customer,
              review_status: 'PENDING'
            },
          };
          let var_approved = {
            args: {
              date_start: params.date_start,
              customer_id: params.customer,
              review_status: 'APPROVED'
            },
          };
          let var_denied = {
            args: {
              date_start: params.date_start,
              customer_id: params.customer,
              review_status: 'DENIED'
            },
          };
    

          let total = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.animal_identifiation_count_userdb,
              variables: var_total,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let pending = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.animal_identifiation_count_userdb,
              variables: var_pending,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          let approved = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.animal_identifiation_count_userdb,
              variables: var_approved,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );

          let denied = await axios.post(
            store.state.hasura_params.url,
            {
              query: hasura_queries.animal_identifiation_count_userdb,
              variables: var_denied,
            },
            {
              headers: store.state.hasura_params.headers,
            }
          );
          console.log(total.data.data.animal_identifiation_count_userdb)
          commit("SET_ANIMAL_ID_USERDB", { 
            total: total.data.data.animal_identifiation_count_userdb, 
            pending: pending.data.data.animal_identifiation_count_userdb, 
            approved: approved.data.data.animal_identifiation_count_userdb, 
            denied: denied.data.data.animal_identifiation_count_userdb });
          resolve();
        }
        get_data();
      });
    },
  },

  getters: {
    getAnimalIDUserDB(state) {
      return state.animal_id_userdb;
    },
    getAnimalIDUniqueCharts(state) {
      return state.animal_id_unique_charts;
    },
    getAnimalIDCharts(state) {
      return state.animal_id_charts;
    },
    getCalvingCharts(state) {
      return state.calving_charts;
    },
    getEstrusCharts(state) {
      return state.estrus_charts;
    },
    getPredatorCharts(state) {
      return state.predator_charts;
    },
    getNotificationAnalytics(state) {
      return state.notification_analytics;
    },
    getMediaByCameraSummary(state) {
      return state.media_by_camera_summary;
    },
    getImageCount(state) {
      return state.animal_images_count;
    },
    getAnimalImages(state) {
      return state.animal_images;
    },
    getTritonModels(state) {
      return state.triton_model_list;
    },
    getTritonMetrics(state) {
      return state.triton_metric_list;
    },
    getVideoYears(state) {
      return state.video_years;
    },
    getAnimalTags(state) {
      return state.animal_tags;
    },
    getWifiModels(state) {
      return state.wifi_models;
    },
    getCurrentCustomer(state) {
      return state.current_customer;
    },
    getTimeRanges(state) {
      return state.time_ranges;
    },
    getTimeSettings(state) {
      return state.time_settings;
    },
    getPGIETypeList(state) {
      return state.PGIE_type_list;
    },
    getSpeciesTypeList(state) {
      return state.Species_type_list;
    },
    getSGIETypeList(state) {
      return state.SGIE_type_list;
    },
    getVehicleTypeList(state) {
      return state.Vehicle_type_list;
    },
    getVehicleMakeList(state) {
      return state.Vehicle_make_list;
    },
    getHumanPoseList(state) {
      return state.Human_pose_list;
    },
    getModelVersionList(state) {
      return state.model_version_list;
    },
    getNotificationFields(state) {
      return state.notification_fields;
    },
    getBBOXColorCode(state) {
      return state.bbox_color_code;
    },
    getImagesImagePlayer(state) {
      return state.imagesImagePlayer;
    },
    getImages(state) {
      return state.images;
    },
    getVideosTimestream(state) {
      return state.videos_timestream;
    },
    getVideosVideoBoard(state) {
      return state.videosBoard;
    },
    getVideosVideoTable(state) {
      return state.videosTable;
    },
    getWaitInterval(state) {
      return state.wait_interval;
    },
    getCustomerTypes(state) {
      return state.customer_types;
    },
    getNotificationLevels(state) {
      return state.notification_levels;
    },
    getNotificationFrequency(state) {
      return state.notification_frequency;
    },
    getJwtToken(state) {
      return state.jwt_token;
    },
    getCustomerTreeview(state) {
      return state.treeview_customer;
    },
    getFeedbacks(state) {
      return state.feedbacks;
    },
    getNotes(state) {
      return state.notes;
    },
    getSelectedNotes(state) {
      return state.selected_notes;
    },
    getSelectedCamera(state) {
      return state.selected_camera;
    },
    getLastFootageUploads(state) {
      return state.last_footage_upload_cameras;
    },
    getContactNames(state) {
      return state.contact_names;
    },
    getSelectedContacts(state) {
      return state.selected_contacts;
    },
    getSelectedProjects(state) {
      return state.selected_projects;
    },
    getAllCorrespondence(state) {
      return state.all_correspondences;
    },
    getCorrespondences(state) {
      return state.correspondences;
    },
    getProjectNamesID(state) {
      return state.project_id_names;
    },
    getProjectContacts(state) {
      return state.selected_project_contacts;
    },
    getCameraIds(state) {
      return state.camera_ids;
    },
    getProjects(state) {
      return state.projects;
    },
    getContacts(state) {
      return state.contacts;
    },
    getHerds(state) {
      return state.herds;
    },
    getAnimals(state) {
      return state.animals;
    },
    getCustomer(state) {
      return state.selected_customer;
    },
    getEditedCustomer(state) {
      return state.edited_customer;
    },
    getEvents(state) {
      return state.events;
    },
    getVideos(state) {
      return state.videos;
    },
    getSiteNameObject(state) {
      return state.site_name_object;
    },
    getNameSiteObject(state) {
      return state.name_site_object;
    },
    getSiteNames(state) {
      return state.site_names;
    },
    getOwnerNameObject(state) {
      return state.owner_name_object;
    },
    getNameOwnerObject(state) {
      return state.name_owner_object;
    },
    getCustomerNames(state) {
      return state.customer_names;
    },
    getCoordinates(state) {
      return state.coordinates;
    },
    getUser(state) {
      return state.user;
    },
    getUserDDB(state) {
      return state.user_ddb;
    },
    getStagingData(state) {
      return state.staging_data;
    },
    getProductionData(state) {
      return state.production_data;
    },
    getSiteObjects(state) {
      return state.site_objects;
    },
    getCameraObjects(state) {
      return state.camera_objects;
    },
    getCameraCount(state) {
      return state.camera_count;
    },
    getCameraModels(state) {
      return state.camera_models;
    },
    getCameraResolutions(state) {
      return state.camera_resolutions;
    },
    getCameraSources(state) {
      return state.camera_sources;
    },
    getCameraShippingStatus(state) {
      return state.camera_shipping_status;
    },
    getCameraOnlineStatus(state) {
      return state.camera_online_status;
    },
    getWifiShippingStatus(state) {
      return state.wifi_shipping_status;
    },
    getWifiOnlineStatus(state) {
      return state.wifi_online_status;
    },
    getWifiManufacturers(state) {
      return state.wifi_manufacturers;
    },
    getJetsonOnlineStatus(state) {
      return state.jetson_online_status;
    },
    getJetsonShippingStatus(state) {
      return state.jetson_shipping_status;
    },
    getCustomerStatus(state) {
      return state.customer_status;
    },
    getShippingStatus(state) {
      return state.shipping_status;
    },
    getOnlineStatus(state) {
      return state.online_status;
    },
    getJetsonObjects(state) {
      return state.jetson_objects;
    },
    getProductionCameras(state) {
      return state.production_cameras;
    },
    getCustomerObjects(state) {
      return state.customer_objects;
    },
    getCognitoUsers(state) {
      return state.cognito_users;
    },
    getStagingRanches(state) {
      return state.staging_ranches;
    },
    getProductionRanches(state) {
      return state.production_ranches;
    },
    getWifiObjects(state) {
      return state.wifi_objects;
    },
    getFootage(state) {
      return state.footage;
    },
    getCustomerCardDialog(state) {
      return state.customer_card_dialog;
    },
    getUserCardDialog(state) {
      return state.user_card_dialog;
    },
    getNoteCardDialog(state) {
      return state.note_card_dialog;
    },
    getImageCardMinimalDialog(state) {
      return state.image_card_minimal_dialog;
    },
    getGeneralFilterDialog(state) {
      return state.general_filter_dialog;
    },
    getSiteCardDialog(state) {
      return state.site_card_dialog;
    },
    getCameraCardDialog(state) {
      return state.camera_card_dialog;
    },
    getWifiCardDialog(state) {
      return state.wifi_card_dialog;
    },
    getJetsonCardDialog(state) {
      return state.jetson_card_dialog;
    },
    getCollaborationCardDialog(state) {
      return state.collaboration_card_dialog;
    },
    getDialogStatus(state) {
      return (
        state.customer_card_dialog ||
        state.user_card_dialog ||
        state.site_card_dialog ||
        state.camera_card_dialog ||
        state.nuke_card_dialog ||
        state.wifi_card_dialog ||
        state.jetson_card_dialog ||
        state.collaboration_card_dialog ||
        state.note_card_dialog ||
        state.image_card_dialog ||
        state.image_card_minimal_dialog ||
        state.general_filter_dialog
      );
    },
    getProvinces(state) {
      return state.provinces;
    },
    getStates(state) {
      return state.states;
    },
    getHasuraParams(state) {
      return state.hasura_params;
    },
    getHasuraParamsWrite(state) {
      return state.hasura_params_write;
    },
    getNotifications(state) {
      return state.notifications;
    },
    getNotificationCount(state) {
      return state.notification_count;
    },
    getCalvingAnalytics(state) {
      return state.analytics_calving;
    },
    getCalvingAnalyticsCount(state) {
      return state.analytics_calving_count;
    },
    getEstrusAnalytics(state) {
      return state.analytics_estrus;
    },
    getEstrusAnalyticsCount(state) {
      return state.analytics_estrus_count;
    },
    getPredatorAnalytics(state) {
      return state.analytics_predator;
    },
    getPredatorAnalyticsCount(state) {
      return state.analytics_predator_count;
    },
    getSelectionCameras(state) {
      return state.selection_cameras;
    },
    getSummaryMedia(state) {
      return state.summary_media;
    },
    getSummaryPGIE(state) {
      return state.summary_pgie;
    },
    getSummaryActivity(state) {
      return state.summary_activity;
    },
    getSummaryCalving(state) {
      return state.summary_calving;
    },
    getSummaryEstrus(state) {
      return state.summary_estrus;
    },
    getSummarySpecies(state) {
      return state.summary_species;
    },
    getSummaryVPC(state) {
      return state.summary_vpc;
    },
    getSummaryVehicle(state) {
      return state.summary_vehicle;
    },
    getSummaryTag(state) {
      return state.summary_tag;
    },
    getHomePageStats(state) {
      return state.home_page_stats;
    },
    // for displaying a single cow
  },
});

export default store;
